import Sizes from 'common/Sizes'
import Card from 'components/v1/Card'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import Link from 'components/v1/Link'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import SvgIcon, { SvgIconVariant } from 'components/v1/SvgIcon'
import React from 'react'

const EducationSection = () => {
  const items: { icon: SvgIconVariant, text: string, url: string }[] = [
    { icon: 'book-with-bookmark', text: 'ДНК России', url: '/dna-of-russia' },
    { icon: 'scroll-with-feather', text: 'Программы повышения квалификации', url: 'https://cdto.ranepa.ru/program-registry' },
    { icon: 'science-hat', text: 'Аспирантура', url: '/graduate-school' }
  ]
  return (
    <PageSection>
      <Stack
        gap={Sizes.gap30.desktop}
        smallScreenGap={Sizes.gap30.smallScreen}
        tabletGap={Sizes.gap30.tablet}
        phoneGap={Sizes.gap30.phone}
      >
        <Heading variant='h2'>Образование</Heading>
        <Grid
          cols={3}
          smallScreenCols={2}
          tabletCols={2}
          phoneCols={1}

          columnGap={Sizes.gap32.desktop}
          smallScreenColumnGap={Sizes.gap32.smallScreen}
          tabletColumnGap={Sizes.gap32.tablet}
          phoneColumnGap={Sizes.gap32.phone}

          rowGap={Sizes.gap30.desktop}
          smallScreenRowGap={Sizes.gap30.smallScreen}
          tabletRowGap={Sizes.gap30.tablet}
          phoneRowGap={Sizes.gap30.phone}
        >
          {items.map((item, index) => {
            return (<Link
              to={item.url}
              key={index}
            >
              <Card
                padding='28px 28px'
                bgColor='green-medium'
                style={{ height: '100%', boxSizing: 'border-box'}}
              >
                <Stack
                  gap={36}
                  alignItems='center'
                  height='100%'
                >
                  <SvgIcon
                    variant={item.icon}
                    size={125}
                    smallScreenSize={100}
                    tabletSize={90}
                    phoneSize={75}
                  />
                  <Heading
                    variant='h3'
                    color='white'
                    textAlign='center'
                  >
                    {item.text}
                  </Heading>
                </Stack>
              </Card>
            </Link>)
          })}
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default EducationSection
