import React from 'react'
import GlobalHeader from 'components/v1/GlobalHeader'
import MainMenu from 'components/v1/MainMenu/MainMenu'
import GlobalFooter from 'components/v1/GlobalFooter'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'

type BasePageProps = {
  children?: React.ReactNode;
  paddingTop?: React.CSSProperties['paddingTop'];
  paddingBottom?: React.CSSProperties['paddingBottom'];
}

const BasePage = (props: BasePageProps) => {
  const device = useDevice()
  return (
    <div>
      <GlobalHeader />
      <MainMenu />
      <div
        style={{
          minHeight: '100%',
          boxSizing: 'border-box',
          paddingTop: props.paddingTop ?? Sizes.gap30[device],
          paddingBottom: props.paddingBottom ?? Sizes.gap80[device]
        }}
      >
        {props.children}
      </div>
      <GlobalFooter />
    </div>
  )
}

export default BasePage
