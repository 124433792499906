import React from 'react'

import colors from 'common/colors'
import useDevice from 'common/useDevice'

import { SettingsContext } from 'App'

import Heading from 'components/v1/Heading'
import Link from 'components/v1/Link'
import Logo from 'components/v1/Logo'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import Sizes from 'common/Sizes'
import SvgIcon from './SvgIcon'
import CookieConsent from './CookieConsent'

const GlobalFooter = () => {
  const settings = React.useContext(SettingsContext)
  const device = useDevice()

  const getSetting = (key: string) => {
    const setting: any = settings.find((item: any) => item.key === key)
    return setting ? setting.value : ''
  }

  return (
    <div
      style={{
        paddingTop: Sizes.gap40[device],
        paddingBottom: Sizes.gap40[device],
        paddingLeft: Sizes.gap128[device],
        paddingRight: Sizes.gap128[device],
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: colors['green-medium']
      }}
    >
      <Stack
        orientation='horizontal'
        phoneOrientation='vertical'
        gap={32}
        alignItems='center'
      >
        {/* left */}
        <Stack
          width='70%'
          smallScreenWidth='70%'
          tabletWidth='70%'
          phoneWidth='100%'
          gap={40}
          style={{
            boxSizing: 'border-box'
          }}
        >
          <Stack orientation='horizontal' gap={30} alignItems='center'>
            <Link to='https://www.ranepa.ru'>
              <Logo variant='ranepa' width={60} />
            </Link>
            <div
              style={{
                height: 56,
                width: 1,
                backgroundColor: colors['white']
              }}
            />
            <Link to='/'>
              <Logo variant='firo-with-text' width={206} />
            </Link>
          </Stack>
          <Stack gap={20}>
            <Heading variant='h4' color='white'>Юридический и почтовый адрес</Heading>
            <Text color='white'>{getSetting('legal_address')}</Text>
          </Stack>
          <Stack gap={20}>
            <Heading variant='h4' color='white'>Фактический адрес</Heading>
            <Text color='white'>{getSetting('address')}</Text>
          </Stack>
        </Stack>
        {/* right */}
        <Stack
          width='30%'
          smallScreenWidth='30%'
          tabletWidth='30%'
          phoneWidth='100%'
          gap={40}
          style={{
            boxSizing: 'border-box'
          }}
        >
          <Link to='https://t.me/firo_online'><SvgIcon variant='tg-white' size={32} /></Link>
          <Stack gap={20}>
            <Heading variant='h4' color='white'>Телефон</Heading>
            <Text color='white'>{getSetting('phone')}</Text>
          </Stack>
          <Stack gap={20}>
            <Heading variant='h4' color='white'>Email</Heading>
            <Link to={`mailto:${getSetting('email')}`} color='white' underline>{getSetting('email')}</Link>
          </Stack>
        </Stack>
      </Stack>
      <CookieConsent />
    </div>
  )
}

export default GlobalFooter
