import React from 'react'
import Layout from 'components/v1/Layout/Layout'
import Heading from 'components/v1/Heading'
import Grid from 'components/v1/Grid'
import Sizes from 'common/Sizes'
import Stack from 'components/v1/Stack'
import Link from 'components/v1/Link'
import Text from 'components/v1/Text'
import Card from 'components/v1/Card'
import useDevice from 'common/useDevice'
import nbspacer from 'common/nbspacer'

type CenterContactsProps = {
  name: string;
  phone: string;
  email: string;
  address: string;
}

const CenterContacts = (props: CenterContactsProps) => {
  const device = useDevice()
  return (
    <Card
      borderColor='green-medium'
    >
      <Layout variant='vertical'>
        <Heading
          variant='h3'
          color='green-medium'
        >
          Наши контакты
        </Heading>
        <Grid
          cols={Sizes.cols4[device]}
          columnGap={Sizes.gap32[device]}
          rowGap={Sizes.gap30[device]}
        >
          <Stack gap={12}>
            <Text
              variant='small'
              color='black-medium'
            >
              Директор Центра
            </Text>
            <Text
              color='green-medium'
            >
              {props.name}
            </Text>
          </Stack>
          <Stack gap={12}>
            <Text
              variant='small'
              color='black-medium'
            >
              Почта
            </Text>
            <Link
              to={`mailto:${props.email}`}
              color='green-medium'
              underline
            >
              {props.email}
            </Link>
          </Stack>
          <Stack gap={12}>
            <Text
              variant='small'
              color='black-medium'
            >
              Телефон
            </Text>
            <Text
              color='green-medium'
            >
              {props.phone}
            </Text>
          </Stack>
          <Stack gap={12}>
            <Text
              variant='small'
              color='black-medium'
            >
              Адрес
            </Text>
            <Text
              color='green-medium'
            >
              {nbspacer(props.address)}
            </Text>

          </Stack>
        </Grid>
      </Layout>
    </Card>
  )
}

export default CenterContacts
