import React from 'react'
import Card from 'components/v1/Card'
import Layout from 'components/v1/Layout/Layout'
import Icon, { IconVariant } from 'components/v1/Icon'
import Text from 'components/v1/Text'
import nbspacer from 'common/nbspacer'
import Color from 'common/Color.d'
import colors from 'common/colors'

type AccentIconTextProps = {
  icon: IconVariant;
  text: string;

  iconColor?: Color;
  iconAccentColor?: Color;
  textColor?: Color;
  descriptionColor?: Color;
  bgColor?: Color;

  minHeight?: React.CSSProperties['height'];
  style?: React.CSSProperties;
}

const defaultColor = 'white'
const defaultBgColor = 'green-medium'

const AccentIconText = (props: AccentIconTextProps) => {
  return (
    <Card
      bgColor={props.bgColor ?? defaultBgColor}
      minHeight={props.minHeight ?? '370px'}
      style={props.style}
    >
      <Layout
        variant='vertical'
        alignItems='flex-start'
      >
        <div
          style={{
            width: 150,
            height: 150,
            borderRadius: '50%',
            backgroundColor: colors[props.iconAccentColor ?? 'green-dark'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Icon
            size={100}
            color={props.iconColor ?? defaultColor}
            variant={props.icon}
          />
        </div>
        <Text
          variant='larger'
          color={props.textColor ?? defaultColor}
        >
          {nbspacer(props.text)}
        </Text>
      </Layout>
    </Card>
  )
}

export default AccentIconText
