import React from 'react'
import Text from 'components/v1/Text'

import styles from './BgImageTextDescription.module.css'
import Color from 'common/Color.d'
import nbspacer from 'common/nbspacer'

type BgImageTextDescriptionProps = {
  style?: React.CSSProperties;

  text: string;
  description: string;
  imageSrc: string;

  textColor?: Color;
  descriptionColor?: Color;
}

const defaultColor = 'white'

const BgImageTextDescription = (props: BgImageTextDescriptionProps) => {
  const style: React.CSSProperties = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props.imageSrc}) no-repeat center center / cover`
  }

  const componentProps = {
    style: { ...style, ...props.style },
    className: styles['BgImageTextDescription']
  }

  return (
    <div {...componentProps}>
      {props.text && <Text
        variant='largest'
        color={props.textColor ?? defaultColor}
      >
        {nbspacer(props.text)}
      </Text>}
      <Text
        variant='larger'
        color={props.descriptionColor ?? defaultColor}
      >
        {nbspacer(props.description ?? '')}
      </Text>
    </div>
  )
}

export default BgImageTextDescription
