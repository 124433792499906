import React from 'react'
import Color from 'common/Color.d'
import colors from 'common/colors'
import useDevice from 'common/useDevice'

type TextVariant = 'small' | 'medium' | 'large' | 'larger' | 'largest'

const defaultTextVariant: TextVariant = 'medium'
const defaultTextColor: Color = 'black-dark'

const FontWeights = {
  'small': {
    'desktop': 400,
    'smallScreen': 400,
    'tablet': 400,
    'phone': 400
  },
  'medium': {
    'desktop': 400,
    'smallScreen': 400,
    'tablet': 400,
    'phone': 400
  },
  'large': {
    'desktop': 400,
    'smallScreen': 400,
    'tablet': 400,
    'phone': 400
  },
  'larger': {
    'desktop': 500,
    'smallScreen': 500,
    'tablet': 500,
    'phone': 500
  },
  'largest': {
    'desktop': 500,
    'smallScreen': 500,
    'tablet': 500,
    'phone': 500
  }
}

const FontSizes = {
  'small': {
    'desktop': '14px',
    'smallScreen': '13px',
    'tablet': '11px',
    'phone': '10px'
  },
  'medium': {
    'desktop': '20px',
    'smallScreen': '18px',
    'tablet': '15px',
    'phone': '15px'
  },
  'large': {
    'desktop': '24px',
    'smallScreen': '21px',
    'tablet': '18px',
    'phone': '16px'
  },
  'larger': {
    'desktop': '30px',
    'smallScreen': '27px',
    'tablet': '33px',
    'phone': '20px'
  },
  'largest': {
    'desktop': '80px',
    'smallScreen': '70px',
    'tablet': '33px',
    'phone': '28x'
  }
}

const LineHeights = {
  'small': {
    'desktop': '18px',
    'smallScreen': '17px',
    'tablet': '16px',
    'phone': '15px'
  },
  'medium': {
    'desktop': '28px',
    'smallScreen': '25px',
    'tablet': '21px',
    'phone': '18px'
  },
  'large': {
    'desktop': '32px',
    'smallScreen': '29px',
    'tablet': '24px',
    'phone': '20px'
  },
  'larger': {
    'desktop': '50px',
    'smallScreen': '45px',
    'tablet': '38px',
    'phone': '24px'
  },
  'largest': {
    'desktop': '86px',
    'smallScreen': '75px',
    'tablet': '38px',
    'phone': '32px'
  }
}

type TextProps = {
  children?: React.ReactNode;
  color?: Color;
  className?: string;
  variant?: TextVariant;
  uppercase?: boolean;
  underline?: boolean;
  textAlign?: React.CSSProperties['textAlign'];
  textOverflow?: React.CSSProperties['textOverflow'];
  overflow?: React.CSSProperties['overflow'];
  overflowY?: React.CSSProperties['overflowY'];
  cursor?: React.CSSProperties['cursor'];
  style?: React.CSSProperties;
  flexGrow?: React.CSSProperties['flexGrow'];
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const Text = (props: TextProps) => {
  const device = useDevice()

  const handleClick = (e: any) => {
    props.onClick?.(e)
  }

  const textVariant = props.variant ?? defaultTextVariant
  const color = props.color ?? defaultTextColor

  const style: React.CSSProperties = {
    fontFamily: 'ALS Hauss',
    fontSize: FontSizes[textVariant][device],
    fontWeight: FontWeights[textVariant][device],
    lineHeight: LineHeights[textVariant][device],
    fontStyle: 'normal',
    textAlign: props.textAlign,
    color: colors[color],
    cursor: props.cursor,
    textTransform: props.uppercase ? 'uppercase' : 'none',
    textDecoration: props.underline ? 'underline' : 'none',
    textOverflow: props.textOverflow,
    overflow: props.overflow,
    overflowY: props.overflowY,
    flexGrow: props.flexGrow
  }

  const componentProps = {
    style: {...style, ...props.style},
    className: props.className,
    onClick: handleClick
  }

  return (
    <span {...componentProps}>{props.children}</span>
  )
}

export default Text
export type { TextVariant }
