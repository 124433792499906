import Color from 'common/Color.d'
import Sizes from 'common/Sizes'
import nbspacer from 'common/nbspacer'
import useDevice from 'common/useDevice'
import Banner from 'components/v1/Banner'
import CenterContacts from 'components/v1/CenterContacts'
import ContentCard from 'components/v1/ContentCard/ContentCard'
import ExpandableCard from 'components/v1/ExpandableCard/ExpandableCard'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import { IconVariant } from 'components/v1/Icon'
import Layout from 'components/v1/Layout/Layout'
import Link from 'components/v1/Link'
import ListItem from 'components/v1/ListItem/ListItem'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'

const EducationQualityPage = () => {
  const device = useDevice()

  const numbersItems: {
    icon?: IconVariant;
    text: string;
    textColor: Color;
    description: string;
    descriptionColor: Color;
    bgColor?: Color;
    imageSrc?: string;
  }[] =
    [
      {
        text: '4',
        description: 'НИР',
        textColor: 'white',
        descriptionColor: 'white',
        bgColor: 'green-medium',
        icon: 'book-with-pencil'
      },
      {
        text: '20+',
        textColor: 'white',
        description: 'выступлений на международных конференциях',
        descriptionColor: 'white',
        imageSrc: '/static/img/pages/centers/education-quality/image2.jpg'
      },
      {
        text: '8',
        description: 'РИД',
        textColor: 'black-dark',
        descriptionColor: 'black-dark',
        bgColor: 'green-lighter',
        icon: 'book-simple'
      },
      {
        text: '1',
        description: 'реализованный проект',
        textColor: 'white',
        descriptionColor: 'white',
        bgColor: 'green-light',
        icon: 'half-cog'
      },
      {
        text: '10+',
        description: 'статей',
        descriptionColor: 'white',
        textColor: 'white',
        bgColor: 'green-medium',
        icon: 'letter-a'
      }
    ]

  return (
    <BasePage>
      <Layout variant='page-sections'>

        <PageSection>
          <Layout variant='vertical'>
            <Banner
              heading='Центр систем оценки и управления качеством образования'
              bgColor='green-light'
              breadcrumbs={[
                { name: 'Главная', url: '/' },
                { name: 'Центр систем оценки и управления качеством образования', url: null }
              ]}
            />
            <Layout
              variant='image-content'
              imageSrc='/static/img/pages/centers/education-quality/image1.jpg'
            >
              <Stack gap={20}
                justifyContent='center'
              >
                <Text variant='large'>{nbspacer('Центр систем оценки и управления качеством образования создан в 2020 году. Сотрудники Центра проводят исследования по вопросам изучения систем оценки качества образования, повышения качества образования и оценки эффективности образовательных систем.')}</Text>
                <Stack gap={12}>
                  <Text variant='larger'>Цель:</Text>
                  <Text variant='large'>{nbspacer('организация и проведение исследований, подготовка публикаций и осуществление консультационной деятельности по вопросам повышения качества образования и оценки эффективности образовательных систем.')}</Text>
                </Stack>

              </Stack>
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <ExpandableCard
            title='Задачи Центра'
          >
            <Layout variant='horizontal-50pct-50pct'>
              <Layout variant='vertical'>
                <ListItem text='Формирование и развитие системы информационного обеспечения управления качеством образования на федеральном, региональном, муниципальном уровнях' />
                <ListItem text='Цифровизация системы государственного статистического наблюдения в сфере образования по показателям (индикаторам) качества образования' />
              </Layout>
              <Layout variant='vertical'>
                <ListItem text='Цифровизация технологий, методического инструментария и процедур оценки качества образования и управления качеством образования' />
                <ListItem text='Формирование и развитие опыта применения результатов педагогических измерений для управления качеством образования на региональном и муниципальном уровнях' />
              </Layout>
            </Layout>
          </ExpandableCard>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Направления деятельности Центра</Heading>
            <Layout variant='horizontal-50pct-50pct'>
              <Layout variant='vertical'>
                <ListItem text='Проведение исследований в области оценки и управления качеством образования' />
                <ListItem text='Проведение исследований в области оценки и управления качеством образования' />
                <ListItem text='Разработка педагогических измерительных материалов' />
              </Layout>
              <Layout variant='vertical'>
                <ListItem text='Разработка и реализация различных инструментов оценки образовательных и иных достижений обучающихся' />
                <ListItem text='Оказание услуг в получении достоверной информации о состоянии и развитии образовательных систем различного уровня' />
              </Layout>
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Цифры</Heading>

            <Layout variant='grid-30pct-70pct--30pct-30pct-30pct'>
              {numbersItems.map((item, index) => (
                <ContentCard
                  key={index}
                  variant={item.imageSrc ? 'bg-image-text-description' : 'icon-text-description'}
                  style={{
                    gridArea: `grid-30pct-70pct--30pct-30pct-30pct__item-${index + 1}`
                  }}
                  icon={item.icon ?? 'book'}
                  text={item.text}
                  textColor={item.textColor}
                  description={item.description}
                  descriptionColor={item.descriptionColor}
                  bgColor={item.bgColor}
                  imageSrc={item.imageSrc}
                />
              ))}
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Результаты деятельности</Heading>
            <Grid
              cols={Sizes.cols3[device]}
              columnGap={Sizes.gap32[device]}
              rowGap={Sizes.gap30[device]}
            >
              <Link to='scientific-works/research'>
                <ContentCard
                  icon='book-with-bookmark'
                  variant='accent-icon-text'
                  text='Научные исследования'
                />
              </Link>
              <Link to='scientific-works/publication'>
                <ContentCard
                  icon='pages-t'
                  variant='accent-icon-text'
                  text='Публикации'
                />
              </Link>
              <Link to='scientific-works/ia_result'>
                <ContentCard
                  icon='pages-t'
                  variant='accent-icon-text'
                  text='РИД'
                />
              </Link>
            </Grid>
          </Layout>
        </PageSection>

        <PageSection>
          <CenterContacts
            name='Илюхин Борис Валентинович'
            email='ilyukhin-bv@ranepa.ru'
            phone='+7 499 956-94-52'
            address='Москва, ул.3-я Кабельная, д.1, стр.1'
          />
        </PageSection>
      </Layout>
    </BasePage>
  )
}

export default EducationQualityPage
