import React from 'react'
import Horizontal30pct70pct from '../Horizontal30pct70pct/Horizontal30pct70pct'
import Image from 'components/v1/Image'

type ImageContentProps = {
  children?: React.ReactNode;
  imageSrc: string;
  imageMinHeight?: React.CSSProperties['minHeight'];
}

const ImageContent = (props: ImageContentProps) => {
  return (
    <Horizontal30pct70pct
      style={{
        minHeight: props.imageMinHeight ?? '370px'
      }}
    >
      <Image
        src={props.imageSrc}
        borderRadius='30px'
        objectFit='cover'
        width='100%'
        height='100%'
      />
      {props.children}
    </Horizontal30pct70pct>
  )
}

export default ImageContent
