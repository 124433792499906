import Sizes from 'common/Sizes'
import colors from 'common/colors'
import nbspacer from 'common/nbspacer'
import useDevice from 'common/useDevice'
import Banner from 'components/v1/Banner'
import Button from 'components/v1/Button'
import Card from 'components/v1/Card'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import Image from 'components/v1/Image'
import Layout from 'components/v1/Layout/Layout'
import Link from 'components/v1/Link'
import ListItem from 'components/v1/ListItem/ListItem'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'

const DocumentsPage = () => {
  const device = useDevice()
  const gridTemplateColumns = {
    desktop: '6fr 40%',
    smallScreen: '1fr',
    tablet: '1fr',
    phone: '1fr'
  }
  return (
    <BasePage>
      <Layout variant='page-sections'>
        {/* Баннер */}
        <PageSection>
          <Banner
            heading='Документы, необходимые для поступления'
            bgColor='green-light'
            breadcrumbs={[
              { name: 'Главная', url: '/' },
              { name: 'Образование', url: null },
              { name: 'Аспирантура', url: '/graduate-school' },
              { name: 'Документы, необходимые для поступления', url: null }
            ]}
          />
        </PageSection>

        {/* Содержание */}
        <PageSection>
          <Layout
            variant='horizontal-70pct-30pct'
          >
            {/* left */}
            <Layout variant='vertical'>
              <Heading variant='h4' color='black-dark'>{nbspacer('Перечень документов, необходимых для поступления:')}</Heading>
              <Stack gap={20}>
                {[
                  '1. Заявление о приеме на обучение (заполняется онлайн)',
                  '2. Документ (документы), удостоверяющий личность, гражданство. Заграничный паспорт гражданина Российской Федерации не является документом, удостоверяющим личность гражданина Российской Федерации на территории Российской Федерации.',
                  '3. Документ об образовании и о квалификации, удостоверяющий образование соответствующего уровня (далее – документ установленного образца) Поступающий может предоставить один или несколько документов установленного образца. Свидетельство о признании иностранного образования (при необходимости) предоставляется в те же сроки, что и документ установленного образца.',
                  '4. Документ, подтверждающий регистрацию в системе индивидуального (персонифицированного) учета (при наличии).',
                  '5. При необходимости создания специальных условий при проведении вступительных испытаний – документ, подтверждающий инвалидность. Указанный документ принимается Академией, если срок его действия истекает не ранее дня подачи заявления о приеме; если в документе не указан срок его действия, то документ действителен в течение года, начиная с даты его выдачи.',
                  '6. Документы, подтверждающие индивидуальные достижения поступающего, результаты которых учитываются при приеме на обучение в соответствии с Правилами приема (представляются по усмотрению поступающего).',
                  '7. Иные документы (представляются по усмотрению поступающего).',
                  '8. Две фотографии поступающего.'
                ].map((text, index) => (
                  <Text
                    key={index}
                  >{nbspacer(text)}</Text>
                ))}
              </Stack>
            </Layout>
            {/* right */}
            <Layout variant='vertical'>
              <Image
                src='/static/img/pages/graduate-school/documents/image1.jpg'
                width='100%'
                borderRadius='30px'
                style={{
                  border: `1px solid ${colors['grey-medium']}`
                }}
              />
              <Card
                borderColor='green-medium'
              >
                <Layout variant='vertical'>
                  <Text>{nbspacer('Организационными вопросами аспирантуры в ФИРО РАНХиГС занимаются:')}</Text>
                  <Stack gap={20}>
                    <Link to='mailto:Alekseeva-as@ranepa.ru' underline>Алексеева Анна Станиславовна</Link>
                    <Link to='mailto:Sharipova-ei@ranepa.ru' underline>Шарипова Эльвира Ирековна </Link>
                  </Stack>
                </Layout>
              </Card>
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Card
              borderColor='green-medium'
              bgColor='blue-light'
            >
              <Layout variant='vertical'>
                <Heading
                  variant='h3'
                  color='green-medium'
                >{nbspacer('Почтовый адрес для направления документов:')}</Heading>
                <Text>{nbspacer('119571, г. Москва, пр-т Вернадского, 82.')}</Text>
              </Layout>
            </Card>
            <Card
              borderColor='green-medium'
              bgColor='blue-light'
            >
              <div
                style={{
                  display: 'grid',
                  boxSizing: 'border-box',
                  columnGap: '32px',
                  rowGap: 30,
                  gridTemplateColumns: gridTemplateColumns[device]
                }}
              >
                <Layout variant='vertical'>
                  <Heading
                    variant='h3'
                    color='green-medium'
                  >{nbspacer('Прием документов в электронной форме')}</Heading>
                  <Text>{nbspacer('осуществляется через  личный кабинет поступающего в РАНХиГС.')}</Text>
                  <ListItem text='Тексты и реквизиты всех документы отсканированных или сфотографированных документов должны быть в читаемом виде.' />
                  <ListItem text='Все документы из списка принимаются, если они поступили в Академию не позднее срока завершения приема документов, установленного Правилами приема.' />
                </Layout>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    to='https://lk.ranepa.ru/pk/auth.php'
                    openInNewTab
                    label='Личный кабинет поступающего'
                    size='small'
                    variant='secondary'
                  />
                </div>
              </div>
            </Card>
            <Card
              borderColor='green-medium'
              bgColor='blue-light'
            >
              <Layout variant='vertical'>
                <Heading
                  variant='h3'
                  color='green-medium'
                >{nbspacer('Контакты и адреса для направления документов:')}</Heading>
                <Grid
                  cols={Sizes.cols3[device]}
                  columnGap={Sizes.gap32[device]}
                  rowGap={Sizes.gap30[device]}
                >
                  {[
                    { key: 'Директор Центра', value: 'Федотова Татьяна Вячеславовна' },
                    { key: 'Телефон', value: '+7 (499) 956 97 02' }
                  ].map(({ key, value }, index) => (
                    <Layout variant='vertical' key={index}>
                      <Text variant='small'>{nbspacer(key)}</Text>
                      <Text color='green-medium'>{nbspacer(value)}</Text>
                    </Layout>
                  ))}

                  <Layout variant='vertical' >
                    <Text variant='small'>Почта</Text>
                    <Text color='green-medium'>
                      <Link to='mailto:fedotova@ranepa.ru' underline color='green-medium'>fedotova@ranepa.ru</Link>
                    </Text>
                  </Layout>

                </Grid>
              </Layout>
            </Card>

            <div>
              <Button
                label='Подать заявку'
                size='small'
                openInNewTab
                to='https://lk.ranepa.ru/pk/auth.php'
              />
            </div>
          </Layout>
        </PageSection>
      </Layout>
    </BasePage>
  )
}

export default DocumentsPage
