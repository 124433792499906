import React from 'react'
import Color from 'common/Color.d'
import colors from 'common/colors'
import useDevice from 'common/useDevice'
import useHover from 'common/useHover'
import getResponsiveProp from 'common/getResponsiveProp'

type CardProps = {
  id?: string | number;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  bgColor?: Color;
  borderColor?: Color;
  onClick?: any;
  height?: React.CSSProperties['height'];
  minHeight?: React.CSSProperties['minHeight'];
  minWidth?: React.CSSProperties['minWidth'];
  width?: React.CSSProperties['width'];
  smallScreenWidth?: React.CSSProperties['width'];
  tabletWidth?: React.CSSProperties['width'];
  phoneWidth?: React.CSSProperties['width'];

  borderRadius?: React.CSSProperties['borderRadius'];
  borderWidth?: number;
  padding?: React.CSSProperties['padding'];
  flexGrow?: React.CSSProperties['flexGrow'];

  gridArea?: React.CSSProperties['gridArea'];
  flexShrink?: React.CSSProperties['flexShrink'];
}

const Card = (props: CardProps) => {
  const device = useDevice()
  const hover = useHover({
  })

  const handleClick = (e: any) => {
    props.onClick?.(e)
  }

  const bgColor: Color = props.bgColor ?? 'white'

  let style: React.CSSProperties = {
    background: colors[bgColor],
    border: props.borderColor ? `${props.borderWidth ?? 1}px solid ${colors[props.borderColor]}` : 'none',
    borderRadius: props.borderRadius ?? 20,
    flexGrow: props.flexGrow,
    padding: props.padding ?? 30,
    height: props.height,
    minHeight: props.minHeight,
    minWidth: props.minWidth,
    boxSizing: 'border-box',
    gridArea: props.gridArea,
    flexShrink: props.flexShrink,
    width: getResponsiveProp(device, props, 'width')
  }

  const componentProps: any = {
    id: props.id,
    style: { ...style, ...props.style, ...hover.style },
    className: props.className,
    onClick: handleClick,
    onMouseEnter: hover.onMouseEnter,
    onMouseLeave: hover.onMouseLeave
  }

  return (
    <div {...componentProps}>{props.children}</div>
  )
}

export default Card
export type { CardProps }
