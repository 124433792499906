import nbspacer from 'common/nbspacer'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import BannerCircleImg from 'components/v1/BannerCircleImg'
import Card from 'components/v1/Card'
import CardIconWithText from 'components/v1/CardIconWithText'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import Icon from 'components/v1/Icon'
import Image from 'components/v1/Image'
import LinkWithArrow from 'components/v1/LinkWithArrow'
import PageSection from 'components/v1/PageSection'
import Stack, { StackOrientation } from 'components/v1/Stack'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'

const ChildrenPage = () => {
  const device = useDevice()

  const gridTemplateColumns = {
    desktop: 'repeat(3, 1fr)',
    smallScreen: 'repeat(2, 1fr)',
    tablet: 'repeat(2, 1fr)',
    phone: 'repeat(1, 1fr)'
  }

  const gridTemplateAreas = {
    desktop: '"item1 item2 item3" "item4 item5 item5"',
    smallScreen: '"item1 item2" "item3 item4" "item5 item5"',
    tablet: '"item1 item2" "item3 item4" "item5 item5"',
    phone: '"item1" "item2" "item3" "item4" "item5"'
  }

  const imageSizes = {
    desktop: 300,
    smallScreen: 280,
    tablet: 200,
    phone: 180
  }

  const leftWidth = {
    desktop: '50%',
    smallScreen: '100%',
    tablet: '100%',
    phone: '100%'
  }

  return (
    <BasePage>
      <Stack
        gap={Sizes.gap80[device]}
      >
        <PageSection>
          <BannerCircleImg
            breadcrumbs={[
              { name: 'Проекты', url: null },
              { name: 'Инструментарий оценки качества дошкольного образования', url: null }
            ]}
            heading='Инструментарий оценки качества дошкольного образования'
            imageUrl='/static/img/pages/projects/children/image1.jpg'
          />
        </PageSection>

        <PageSection>
          <Stack
            orientation={Sizes.orientation[device] as StackOrientation}
            gap={Sizes.gap80[device]}
            width={Sizes.width90[device]}
            alignItems='center'
          >
            <Stack
              gap={16}
            >
              <Text variant='larger'>{nbspacer('Инструментарий оценки качества дошкольного образования')}</Text>
              <Text variant='large'>{nbspacer('(далее – Инструментарий) разработан Федеральным институтом развития образования РАНХиГС в 2019 году (Государственный контракт № 04.Z48.11.0008 от 11.06.2019) по заданию Министерства просвещения Российской Федерации.')}</Text>
            </Stack>

            <Icon
              variant='book'
              size={140}
              flexShrink={0}
              color='green-medium'
            />
          </Stack>
        </PageSection>

        <PageSection>
          <Stack
            orientation={Sizes.orientation[device] as StackOrientation}
            gap={Sizes.gap30[device]}
          >
            <Image
              width={Sizes.width33[device]}
              src='/static/img/pages/projects/children/image2.jpg'
              borderRadius='30px'
              // height={370}
              objectFit='cover'
              flexShrink={0}
              style={{
                minHeight: 370
              }}
            />
            <Card
              bgColor='green-medium'
              flexGrow={1}
            >
              <Stack
                justifyContent='flex-end'
                height='100%'
              >
                <Stack
                  gap={Sizes.gap30[device]}
                >
                  <Text
                    variant='larger'
                    color='white'
                  >Инструментарий —</Text>
                  <Text
                    variant='larger'
                    color='white'
                  >
                    {nbspacer('это комплект материалов, рекомендаций, цикл видеофильмов, позволяющих  проводить педагогическую диагностику достижения планируемых результатов ребенка дошкольного возраста.')}
                  </Text>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </PageSection>

        <PageSection>
          <Card
            borderColor='green-lighter'
          >
            <Grid
              cols={Sizes.cols2[device]}
              columnGap={Sizes.gap32[device]}
              rowGap={Sizes.gap30[device]}
            >
              <Stack gap={Sizes.gap30[device]}>
                <Heading variant='h3'>Инструментарий разработан</Heading>
                <Text>{nbspacer('в соответствии с требованиями Федерального государственного образовательного стандарта дошкольного образования (ФГОС ДО.) Приказ Министерства образования и науки Российской Федерации № 1155 от 17 10.2013 г.; п. 3.2.3')}</Text>
              </Stack>
              <Stack gap={Sizes.gap30[device]}>
                <Heading variant='h3'>Инструментарий соответствует</Heading>
                <Text>{nbspacer('Федеральной образовательной программе дошкольного образования (ФОП ДО). Приказ Минпросвещения Российской Федерации № 1028 от 25.11.22; п. 16.1-16.7.')}</Text>
              </Stack>
            </Grid>
          </Card>
        </PageSection>

        <PageSection>
          <Stack
            gap={Sizes.gap30[device]}
          >
            <Heading variant='h2'>Инструментарий предназначен</Heading>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: gridTemplateColumns[device],
                gridTemplateAreas: gridTemplateAreas[device],
                columnGap: 32,
                rowGap: 30
              }}
            >
              <CardIconWithText
                icon='people-with-clock'
                text='для специалистов и руководителей дошкольных образовательных организаций'
                bgColor='green-medium'
                color='white'
                gridArea='item1'
              />
              <CardIconWithText
                icon='family'
                text='для родителей детей дошкольного возраста от 3 до 7 лет'
                bgColor='green-light'
                color='white'
                gridArea='item2'
              />
              <CardIconWithText
                icon='book-with-finger'
                text='для специалистов органов управления образованием'
                bgColor='green-lighter'
                color='black-dark'
                gridArea='item3'
              />
              <CardIconWithText
                icon='person-with-laptop-and-clock'
                text='Инструментарий будет полезен специалистам дополнительного профессионального образования'
                bgColor='green-lighter'
                color='white'
                gridArea='item4'
              />
              <Card
                bgColor='green-light'
                gridArea='item5'
              >
                <Stack orientation={Sizes.orientation[device] as StackOrientation}
                  gap={Sizes.gap32[device]}
                  alignItems='flex-end'
                  style={{
                    flexWrap: 'wrap'
                  }}
                >
                  <Stack
                    height='100%'
                    justifyContent='flex-end'
                    flexGrow={1}
                    width={leftWidth[device]}
                  >
                    <Text variant='large'>
                      {nbspacer('Инструментарий предоставляет возможность не только педагогам детского сада, но и родителям детей дошкольного возраста в режиме «здесь и сейчас» повысить свою квалификацию')}
                    </Text>
                  </Stack>
                  <Image
                    src='/static/img/pages/projects/children/image3.jpg'
                    width={imageSizes[device]}
                    height={imageSizes[device]}
                    objectFit='cover'
                    borderRadius='50%'
                    flexShrink={0}
                  />
                </Stack>
              </Card>
            </div>
          </Stack>
        </PageSection>

        <PageSection>
          <LinkWithArrow
            to=''
            label='Скачать все разработки'
          />
        </PageSection>
      </Stack>
    </BasePage>
  )
}

export default ChildrenPage
