import Color from 'common/Color.d'
import Sizes from 'common/Sizes'
import nbspacer from 'common/nbspacer'
import useDevice from 'common/useDevice'
import Banner from 'components/v1/Banner'
import CenterContacts from 'components/v1/CenterContacts'
import ContentCard from 'components/v1/ContentCard/ContentCard'
import ExpandableCard from 'components/v1/ExpandableCard/ExpandableCard'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import { IconVariant } from 'components/v1/Icon'
import Layout from 'components/v1/Layout/Layout'
import Link from 'components/v1/Link'
import ListItem from 'components/v1/ListItem/ListItem'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'

const PriorityAreasPage = () => {
  const device = useDevice()

  const numbersItems: {
    icon?: IconVariant;
    text: string;
    textColor: Color;
    description: string;
    descriptionColor: Color;
    bgColor?: Color;
    imageSrc?: string;
  }[] =
    [
      {
        text: '22',
        textColor: 'white',
        description: 'реализованных проектов',
        descriptionColor: 'white',
        bgColor: 'green-medium'
      },
      {
        text: '23',
        description: 'НИР',
        textColor: 'black-dark',
        descriptionColor: 'black-dark',
        bgColor: 'green-lighter',
        icon: 'letter-a'
      },
      {
        text: '60+',
        description: 'проведенных мероприятий',
        textColor: 'white',
        descriptionColor: 'white',
        bgColor: 'green-light',
        icon: 'people-communication'
      },
      {
        text: '25+',
        description: 'методических разработок',
        descriptionColor: 'white',
        textColor: 'white',
        bgColor: 'green-medium',
        icon: 'book-simple'
      }
    ]

  return (
    <BasePage>
      <Layout variant='page-sections'>

        <PageSection>
          <Layout variant='vertical'>
            <Banner
              heading='Центр изучения приоритетных направлений развития образования'
              bgColor='green-light'
              breadcrumbs={[
                { name: 'Главная', url: '/' },
                { name: 'Центр изучения приоритетных направлений развития образования', url: null }
              ]}
            />
            <Layout
              variant='image-content'
              imageSrc='/static/img/pages/centers/priority-areas/image1.jpg'
            >
              <Stack gap={20}
                justifyContent='center'
              >
                <Text variant='large'>{nbspacer('Основной профиль научных интересов Центра: осуществление научно- исследовательских, проектных и экспертных работ, подготовка научных публикаций по актуальным проблемам приоритетных направлений развития образования')}</Text>
                <Text variant='large'>{nbspacer('В марте 2022 Центр переименован (прежнее название: научно-исследовательский центр социализации и персонализации образования детей).')}</Text>

                <Stack>
                  <Text
                    variant='large'
                    color='green-medium'
                  >
                    {nbspacer('Структурные подразделения Центра:')}
                  </Text>
                  <Text>{nbspacer('Лаборатория «Антропологических практик».')}</Text>
                </Stack>
              </Stack>
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <ExpandableCard
            title='Цель и задачи Центра'
          >
            <Layout variant='horizontal-50pct-50pct'>
              <Layout variant='vertical'>
                <Text
                  variant='larger'
                  color='green-medium'
                >
                  Цель
                </Text>

                <ListItem text='Проведение научно-исследовательской, экспертно-аналитической, консультационной деятельности по актуальным проблемам приоритетных направлений развития образования' />
              </Layout>
              <Layout variant='vertical'>
                <Text
                  variant='larger'
                  color='green-medium'
                >
                  Задачи
                </Text>
                <ListItem text='Исследование и разработка научно-методических и теоретических подходов приоритетных направлений развития образования' />
                <ListItem text='Проведение исследовательских и проектных работ по приоритетным направлениям развития образования' />
                <ListItem text='Проведение экспертизы результатов научно-исследовательских, экспериментальных и проектных работ в области приоритетных направлений развития образования' />
              </Layout>
            </Layout>
          </ExpandableCard>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Направления деятельности Центра</Heading>
            <Layout variant='horizontal-50pct-50pct'>
              <Layout variant='vertical'>
                <ListItem text='Анализ тенденций и приоритетов развития общего и дополнительного образования детей' />
                <ListItem text='Исследование влияния процессов цифровой трансформации на учебную деятельность, содержание образования и структуру образовательных достижений' />
                <ListItem text='Оценка цифровой зрелости образовательных организаций' />
                <ListItem text='Исследование эффективности новых подходов к педагогическому дизайну образовательных программ' />
              </Layout>
              <Layout variant='vertical'>
                <ListItem text='Анализ, разработка и внедрение моделей формирующего и адаптивного оценивания образовательных достижений' />
                <ListItem text='Содействие повышению уровня квалификации государственных гражданских служащих, ответственных за развитие общего и дополнительного образования детей на федеральном и региональном уровнях, в том числе в рамках приоритетных проектов в сфере «Образование».' />
              </Layout>
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Сегодня Центр изучения приоритетных направлений развития образования это:</Heading>

            <Layout variant='grid-100pct--30pct-30pct-30pct'>
              {numbersItems.map((item, index) => (
                <ContentCard
                  key={index}
                  variant={item.imageSrc ? 'bg-image-text-description' : (item.icon ? 'icon-text-description' : 'text-description')}
                  style={{
                    width: '100%',
                    gridArea: `grid-100pct--30pct-30pct-30pct__item-${index + 1}`
                  }}
                  icon={item.icon ?? 'book'}
                  text={item.text}
                  textColor={item.textColor}
                  description={item.description}
                  descriptionColor={item.descriptionColor}
                  bgColor={item.bgColor}
                  imageSrc={item.imageSrc}
                />
              ))}
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Результаты деятельности</Heading>
            <Grid
              cols={Sizes.cols3[device]}
              columnGap={Sizes.gap32[device]}
              rowGap={Sizes.gap30[device]}
            >
              <Link to='scientific-works/research'>
                <ContentCard
                  icon='book-with-bookmark'
                  variant='accent-icon-text'
                  text='Научные исследования'
                />
              </Link>
              <Link to='scientific-works/publication'>
                <ContentCard
                  icon='pages-t'
                  variant='accent-icon-text'
                  text='Публикации'
                />
              </Link>
              <Link to='scientific-works/ia_result'>
                <ContentCard
                  icon='pages-t'
                  variant='accent-icon-text'
                  text='РИД'
                />
              </Link>
            </Grid>
          </Layout>
        </PageSection>

        <PageSection>
          <CenterContacts
            name='Тарасова Наталья Владимировна'
            email='tarasova-nv@ranepa.ru'
            phone='+7 499 956-94-45'
            address='Москва, ул.3-я Кабельная, д.1, стр.1'
          />
        </PageSection>
      </Layout>
    </BasePage>
  )
}

export default PriorityAreasPage
