import React from 'react'
import { IconVariant } from 'components/v1/Icon'
import Color from 'common/Color.d'
import IconTextDescription from './IconTextDescription/IconTextDescription'
import BgImageTextDescription from './BgImageTextDescription/BgImageTextDescription'
import AccentIconText from './AccentIconText/AccentIconText'
import TextDescription from './TextDescription/TextDescription'

type ContentCardVariant = 'icon-text-description' |
  'bg-image-text-description' |
  'accent-icon-text' |
  'text-description';

type ContentCardProps = {
  variant: ContentCardVariant;

  text?: string;
  description?: string;

  icon?: IconVariant;
  imageSrc?: string;

  iconColor?: Color;
  iconAccentColor?: Color;

  textColor?: Color;
  descriptionColor?: Color;

  bgColor?: Color;

  minHeight?: React.CSSProperties['minHeight'];
  style?: React.CSSProperties;
}

const ContentCard = (props: ContentCardProps) => {
  const style: React.CSSProperties = {
  }

  const componentProps = {
    style: { ...style, ...props.style }
  }

  return (
    <>
      {props.variant === 'icon-text-description' &&
        <IconTextDescription
          {...componentProps}
          icon={props.icon ?? 'book'}
          text={props.text ?? ''}
          description={props.description ?? ''}
          textColor={props.textColor}
          descriptionColor={props.descriptionColor}
          bgColor={props.bgColor}
        />}
      {props.variant === 'accent-icon-text' &&
        <AccentIconText
          {...componentProps}
          icon={props.icon ?? 'book'}
          text={props.text ?? ''}
          textColor={props.textColor}
          iconAccentColor={props.iconAccentColor}
          bgColor={props.bgColor}
        />}
      {props.variant === 'bg-image-text-description' &&
        <BgImageTextDescription
          {...componentProps}
          text={props.text ?? ''}
          description={props.description ?? ''}
          imageSrc={props.imageSrc ?? ''}
        />}

      {props.variant === 'text-description' &&
        <TextDescription
          {...componentProps}
          text={props.text ?? ''}
          description={props.description ?? ''}
          bgColor={props.bgColor}
        />
      }
    </>
  )
}

export default ContentCard
