import useDevice from 'common/useDevice'
import React from 'react'

type PageSectionProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  marginLeft?: React.CSSProperties['marginLeft'];
  marginRight?: React.CSSProperties['marginRight'];
}

const PageSection = (props: PageSectionProps) => {
  const device = useDevice()

  const getMarginX = () => {
    if (device === 'phone') {
      return 16
    }
    if (device === 'tablet') {
      return 64
    }
    return 128
  }

  const style: React.CSSProperties = {
    marginLeft: props.marginLeft ?? getMarginX(),
    marginRight: props.marginRight ?? getMarginX(),
    boxSizing: 'border-box'
  }

  const componentProps = {
    style: { ...style, ...props.style },
    className: props.className
  }

  return (
    <section {...componentProps}>{props.children}</section>
  )
}

export default PageSection
