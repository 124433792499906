import React from 'react'
import useDevice from 'common/useDevice'
import getResponsiveProp from 'common/getResponsiveProp'

type GridProps = {
  id?: number | string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;

  cols?: React.CSSProperties['gridTemplateColumns'];
  smallScreenCols?: React.CSSProperties['gridTemplateColumns'];
  tabletCols?: React.CSSProperties['gridTemplateColumns'];
  phoneCols?: React.CSSProperties['gridTemplateColumns'];

  rows?: React.CSSProperties['gridTemplateRows'];
  smallScreenRows?: React.CSSProperties['gridTemplateRows'];
  tabletRows?: React.CSSProperties['gridTemplateRows'];
  phoneRows?: React.CSSProperties['gridTemplateRows'];

  flow?: React.CSSProperties['gridAutoFlow'];
  smallScreenFlow?: React.CSSProperties['gridAutoFlow'];
  tabletFlow?: React.CSSProperties['gridAutoFlow'];
  phoneFlow?: React.CSSProperties['gridAutoFlow'];

  columnGap?: React.CSSProperties['columnGap'];
  smallScreenColumnGap?: React.CSSProperties['columnGap'];
  tabletColumnGap?: React.CSSProperties['columnGap'];
  phoneColumnGap?: React.CSSProperties['columnGap'];

  rowGap?: React.CSSProperties['rowGap'];
  smallScreenRowGap?: React.CSSProperties['rowGap'];
  tabletRowGap?: React.CSSProperties['rowGap'];
  phoneRowGap?: React.CSSProperties['rowGap'];

  alignItems?: React.CSSProperties['alignItems'];
  justifyItems?: React.CSSProperties['justifyItems'];
}

const Grid = (props: GridProps) => {
  const device = useDevice()

  const cols = getResponsiveProp(device, props, 'cols')
  const rows = getResponsiveProp(device, props, 'rows')

  const style: React.CSSProperties = {
    display: 'grid',
    gridTemplateColumns: cols ? `repeat(${cols}, minmax(min(200px,100%), 1fr))` : undefined,
    gridTemplateRows: rows ? `repeat(${rows}, min-content)` : undefined,
    gridAutoFlow: getResponsiveProp(device, props, 'flow'),
    columnGap: getResponsiveProp(device, props, 'columnGap'),
    rowGap: getResponsiveProp(device, props, 'rowGap'),
    alignItems: props.alignItems,
    justifyItems: props.justifyItems,
    width: '100%'
  }

  const componentProps = {
    className: props.className,
    style: { ...style, ...props.style }
  }

  return (
    <div {...componentProps}>{props.children}</div>
  )
}

export default Grid
export type { GridProps }
