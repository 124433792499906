// import useDevice from 'common/useDevice'
import React from 'react'
import Card from 'components/v1/Card'
import Stack from 'components/v1/Stack'
// import Sizes from 'common/Sizes'
import SvgIcon, { SvgIconVariant } from 'components/v1/SvgIcon'
import Text from 'components/v1/Text'

type CardWithSvgIconProps = {
  icon: SvgIconVariant;
  name: string;
  description: string;
  style?: React.CSSProperties;
}

const CardWithSvgIcon = (props: CardWithSvgIconProps) => {
  // const device = useDevice()

  return (
    <Card
      bgColor='green-medium'
      style={props.style}
    >
      <Stack
        gap={20}
        style={{
          minHeight: '370px'
        }}
      >
        <SvgIcon
          variant={props.icon}
          size={100}
        />
        <Stack>
          <Text
            variant='largest'
            color='white'
          >
            {props.name}
          </Text>
          <Text
            variant='larger'
            color='white'
          >
            {props.description}
          </Text>
        </Stack>
      </Stack>
    </Card>
  )
}

export default CardWithSvgIcon
