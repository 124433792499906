export default {
  gap20: {
    'desktop': 20,
    'smallScreen': 18,
    'tablet': 12,
    'phone': 10
  },
  gap28: {
    'desktop': 28,
    'smallScreen': 27,
    'tablet': 22,
    'phone': 14
  },
  gap30: {
    'desktop': 30,
    'smallScreen': 28,
    'tablet': 23,
    'phone': 15
  },
  gap32: {
    'desktop': 32,
    'smallScreen': 30,
    'tablet': 24,
    'phone': 16
  },
  gap40: {
    'desktop': 40,
    'smallScreen': 36,
    'tablet': 29,
    'phone': 19
  },
  gap60: {
    'desktop': 60,
    'smallScreen': 56,
    'tablet': 46,
    'phone': 39
  },
  gap80: {
    'desktop': 80,
    'smallScreen': 75,
    'tablet': 60,
    'phone': 40
  },
  gap128: {
    'desktop': 128,
    'smallScreen': 128,
    'tablet': 64,
    'phone': 16
  },
  cols4: {
    desktop: 4,
    smallScreen: 2,
    tablet: 2,
    phone: 1
  },
  cols3: {
    desktop: 3,
    smallScreen: 2,
    tablet: 1,
    phone: 1
  },
  cols2: {
    desktop: 2,
    smallScreen: 2,
    tablet: 1,
    phone: 1
  },
  width33: {
    desktop: '33%',
    smallScreen: '33%',
    tablet: '50%',
    phone: '100%'
  },
  width40: {
    desktop: '40%',
    smallScreen: '40%',
    tablet: '50%',
    phone: '100%'
  },
  width70: {
    desktop: '70%',
    smallScreen: '85%',
    tablet: '100%',
    phone: '100%'
  },
  width90: {
    desktop: '90%',
    smallScreen: '95%',
    tablet: '100%',
    phone: '100%'
  },
  orientation: {
    desktop: 'horizontal',
    smallScreen: 'horizontal',
    tablet: 'vertical',
    phone: 'vertical'
  },
  componentHeight: 370
}
