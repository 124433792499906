import nbspacer from 'common/nbspacer'
import Banner from 'components/v1/Banner'
import Button from 'components/v1/Button'
import Card from 'components/v1/Card'
import Heading from 'components/v1/Heading'
import Icon from 'components/v1/Icon'
import Layout from 'components/v1/Layout/Layout'
import Link from 'components/v1/Link'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'

const DeadlinesPage = () => {
  return (
    <BasePage>
      <Layout variant='page-sections'>
        {/* Баннер */}
        <PageSection>
          <Banner
            heading='Сроки приема документов'
            bgColor='green-light'
            breadcrumbs={[
              { name: 'Главная', url: '/' },
              { name: 'Образование', url: null },
              { name: 'Аспирантура', url: '/graduate-school' },
              { name: 'Сроки приема документов', url: null }
            ]}
          />
        </PageSection>

        {/* Содержание */}
        <PageSection>
          <Layout
            variant='horizontal-70pct-30pct'
          >
            {/* left */}
            <Layout variant='page-sections'>
              <Layout variant='vertical'>
                <Icon
                  color='green-medium'
                  variant='pages-t'
                  size={120}
                />
                <Heading variant='h4' color='black-dark'>{nbspacer('Документы на обучение по программам группы научных специальностей 5.8. Педагогика принимаются')}</Heading>
                <Heading variant='h3' color='black-dark'>{nbspacer(' с 1 июня по 29 сентября 2023 года.')}</Heading>
              </Layout>

              <div>
                <Button
                  label='Подать заявку'
                  size='small'
                  openInNewTab
                  to='https://lk.ranepa.ru/pk/auth.php'
                />
              </div>
            </Layout>
            {/* right */}
            <Layout variant='vertical'>
              <Card
                borderColor='green-medium'
              >
                <Layout variant='vertical'>
                  <Text>{nbspacer('Организационными вопросами аспирантуры в ФИРО РАНХиГС занимаются:')}</Text>
                  <Stack gap={20}>
                    <Link to='mailto:Alekseeva-as@ranepa.ru' underline>Алексеева Анна Станиславовна</Link>
                    <Link to='mailto:Sharipova-ei@ranepa.ru' underline>Шарипова Эльвира Ирековна </Link>
                  </Stack>
                </Layout>
              </Card>
            </Layout>
          </Layout>
        </PageSection>
      </Layout>
    </BasePage>
  )
}

export default DeadlinesPage
