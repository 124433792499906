import colors from 'common/colors'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import React, { useState } from 'react'
import Text from './Text'

type TagButtonProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  active?: boolean;
}

const TagButton = (props: TagButtonProps) => {
  const device = useDevice()
  const [hover, setHover] = useState(false)

  const getTextColor = () => {
    if (props.disabled) {
      return 'grey-medium'
    }

    if (props.active) {
      return 'white'
    }
    if (hover) {
      return 'black-dark'
    }
    return 'black-dark'
  }

  const getButtonBackground = () => {
    if (props.disabled) {
      return 'none'
    }

    if (props.active) {
      return colors['green-medium']
    }
    if (hover) {
      return colors['blue-light']
    }
    return 'none'
  }

  const getButtonBorderColor = () => {
    if (props.active) {
      return colors['green-medium']
    }
    return colors['grey-medium']
  }

  const style: React.CSSProperties = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '30px',
    padding: `10px ${Sizes.gap32[device]}px`,
    outline: 'none',
    boxShadow: 'none',
    border: `1px solid ${getButtonBorderColor()}`,
    cursor: props.disabled ? 'default' : 'pointer',
    background: getButtonBackground()
  }
  const componentProps = {
    style: { ...style, ...props.style },
    disabled: props.disabled,
    onClick: props.onClick,
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false)
  }

  return (
    <button {...componentProps}>
      <Text
        variant='medium'
        color={getTextColor()}
      >
        {props.children}
      </Text>
    </button>
  )
}

export default TagButton
