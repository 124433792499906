import Sizes from 'common/Sizes'
import nbspacer from 'common/nbspacer'
import useDevice from 'common/useDevice'
import Breadcrumbs, { Breadcrumb } from 'components/v1/Breadcrumbs'
import Heading from 'components/v1/Heading'
import Layout from 'components/v1/Layout/Layout'
import Link from 'components/v1/Link'
import ListItemContent from 'components/v1/ListItemContent/ListItemContent'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import TagButton from 'components/v1/TagButton'
import BasePage from 'pages/BasePage'
import React from 'react'
import { useParams } from 'react-router-dom'

const CentersScientificWorksPage = () => {
  const device = useDevice()
  const [selectedYear, setSelectedYear] = React.useState<string | null>(null)
  const [items, setItems] = React.useState<any>([])
  const { workType, center } = useParams()

  const getCenterName = () => {
    if (center === 'education-quality') {
      return 'Центр систем оценки и управления качеством образования'
    }
    if (center === 'monitoring-and-statistics') {
      return 'Центр мониторинга и статистики образования'
    }
    if (center === 'professional-education') {
      return 'Центр профессионального образования и систем квалификаций'
    }
    if (center === 'priority-areas') {
      return 'Центр изучения приоритетных направлений развития образования'
    }
    if (center === 'theoretical-and-applied-aspects') {
      return 'Центр изучения теоретических и прикладных аспектов образования'
    }
    return ''
  }

  const getHeading = () => {
    if (workType === 'publication') {
      return 'Публикации'
    }
    if (workType === 'research') {
      return 'Научные исследования'
    }
    if (workType === 'ia_result') {
      return 'РИД'
    }

    return ''
  }

  const breadcrumbs: Breadcrumb[] = [
    {
      name: 'Главная',
      url: '/'
    },
    {
      name: getCenterName(),
      url: `/centers/${center}`
    },
    {
      name: getHeading(),
      url: null
    }
  ]

  const getCenterId = () => {
    if (center === 'education-quality') {
      return 6
    }
    if (center === 'monitoring-and-statistics') {
      return 3
    }
    if (center === 'professional-education') {
      return 4
    }
    if (center === 'priority-areas') {
      return 1
    }
    if (center === 'theoretical-and-applied-aspects') {
      return 2
    }
    return null
  }

  React.useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchItems = async () => {
    const url = `/api/science-centers/scientific-works/`
    let params: any = {
      work_type: workType,
      science_center_id: getCenterId()
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setItems(data)
    }
  }

  const getYears = () => {
    let uniqueYears: string[] = []

    items.forEach((obj: any) => {
      if (!uniqueYears.includes(obj.year)) {
        uniqueYears.push(obj.year)
      }
    })

    return uniqueYears.sort((a: string, b: string) => {
      return parseInt(b) - parseInt(a)
    })
  }

  const getFilteredItems = () => {
    if (selectedYear === null) {
      return items
    }

    return items.filter((item: any) => item.year === selectedYear)
  }

  return (
    <BasePage>
      <PageSection>
        <Layout variant='vertical'>
          <Breadcrumbs
            color='grey-medium'
            items={breadcrumbs}
          />
          <Heading
            variant='h1'
          >
            {getHeading()}
          </Heading>

          <Stack
            orientation='horizontal'
            gap={Sizes.gap40[device]}
          >
            <TagButton
              active={selectedYear === null}
              onClick={() => setSelectedYear(null)}
            >
              Все
            </TagButton>
            {getYears().map((item: string) => (
              <TagButton
                active={selectedYear === item}
                key={item}
                onClick={() => setSelectedYear(item)}
              >
                {item}
              </TagButton>
            ))
            }
          </Stack >

          <Layout variant='vertical'>
            {getFilteredItems().map((item: any) => (
              <ListItemContent
                key={item.id}
              >
                {nbspacer(item.name)}
                {item.url && <> <Link to={item.url} underline>{item.url}</Link></>}
              </ListItemContent>
            ))}
          </Layout>
        </Layout>
      </PageSection>
    </BasePage>
  )
}

export default CentersScientificWorksPage
