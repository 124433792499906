import { Link as ReactLink } from 'react-router-dom'
import React from 'react'
import Color from 'common/Color.d'
import colors from 'common/colors'
import useDevice from 'common/useDevice'

const defaultLinkColor: Color = 'black-dark'

const FontSizes = {
  'desktop': '20px',
  'smallScreen': '18px',
  'tablet': '15px',
  'phone': '15px'
}

const LineHeights = {
  'desktop': '28px',
  'smallScreen': '25px',
  'tablet': '21px',
  'phone': '18px'
}

type LinkProps = {
  children?: React.ReactNode;
  to: string;
  style?: React.CSSProperties;
  className?: string;
  underline?: boolean;
  color?: Color;
  fontWeight?: React.CSSProperties['fontWeight'];
  onMouseEnter?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Link = (props: LinkProps) => {
  const device = useDevice()
  const color = props.color ?? defaultLinkColor

  const style: React.CSSProperties = {
    fontFamily: 'ALS Hauss, sans-serif',
    fontSize: FontSizes[device],
    fontWeight: props.fontWeight ?? 500,
    lineHeight: LineHeights[device],
    textDecoration: props.underline ? 'underline' : 'none',
    color: colors[color]
  }

  const linkProps: any = {
    to: props.to,
    className: props.className,
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    style: {...style, ...props.style}
  }

  const aProps: any = {
    href: props.to,
    className: props.className,
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    onClick: props.onClick,
    target: '_blank',
    rel: 'noopener noreferrer',
    style: {...style, ...props.style}
  }

  return (
    <>
      {(props.to.startsWith('http') || props.to.startsWith('mailto')) && <a {...aProps}>{props.children}</a>}
      {!props.to.startsWith('http') && !props.to.startsWith('mailto') && <ReactLink {...linkProps}>{props.children}</ReactLink>}
    </>
  )
}

export default Link
