import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Breadcrumbs from 'components/v1/Breadcrumbs'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import PageSection from 'components/v1/PageSection'
import ShowMoreButton from 'components/v1/ShowMoreButton'
import Stack from 'components/v1/Stack'
import BasePage from 'pages/BasePage'
import React from 'react'
import NewsItem from 'models/NewsItem.d'
import NewsCategory from 'models/NewsCategory.d'
import NewsCategories from './NewsCategories'
import Image from 'components/v1/Image'
import Link from 'components/v1/Link'

const PAGE_SIZE = 9

const NewsPage = () => {
  const device = useDevice()

  const [canFetchMore, setCanFetchMore] = React.useState(true)
  const [page, setPage] = React.useState(1)
  const [items, setItems] = React.useState<NewsItem[]>([])
  const [category, setCategory] = React.useState<NewsCategory | null>(null)

  React.useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchItems()
  }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (page === 1) {
      fetchItems()
    } else {
      setPage(1)
    }
  }, [category]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchItems = async () => {
    const url = `/api/news/`
    let params: any = {
      page_size: PAGE_SIZE,
      page: page,
      category_id: category?.id ?? ''
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      if (page > 1) {
        setItems([...items, ...data.results])
      } else {
        setItems(data.results)
      }

      if (page * PAGE_SIZE >= data.count) {
        setCanFetchMore(false)
      } else {
        setCanFetchMore(true)
      }
    } else {
      setCanFetchMore(false)
    }
  }

  return (
    <BasePage>
      <PageSection>
        <Stack
          gap={Sizes.gap30[device]}
        >
          <Breadcrumbs
            items={[
              { name: 'Главная', url: '/' },
              { name: 'Новости', url: null }
            ]}
            color='grey-medium'
          />

          <Heading variant='h1'>Новости</Heading>

          <NewsCategories onChange={(category: NewsCategory | null) => setCategory(category)} />

          <Stack
            gap={Sizes.gap40[device]}
          >
            <Grid
              cols={Sizes.cols3[device]}
              columnGap={Sizes.gap32[device]}
              rowGap={Sizes.gap30[device]}
            >
              {items.map((item: NewsItem) => (

                <Link
                  key={item.id}
                  to={`/news/${item.id}`}
                >
                  <Stack

                    gap={12}
                  >
                    <Image
                      src={item.image_url}
                      style={{
                        height: '260px',
                        borderRadius: '30px',
                        objectFit: 'cover'
                      }}
                    />
                    <Heading variant='h4'>
                      {item.name}
                    </Heading>
                  </Stack>
                </Link>
              ))}
            </Grid>
          </Stack>

          <ShowMoreButton
            disabled={!canFetchMore}
            onClick={() => setPage(page + 1)}
          />
        </Stack>
      </PageSection>
    </BasePage>
  )
}

export default NewsPage
