import Card from 'components/v1/Card'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import Link from 'components/v1/Link'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import React from 'react'
import Sizes from 'common/Sizes'
import nbspacer from 'common/nbspacer'

type ScientificCenter = {
  id: number;
  name: string;
  address: string;
  email: string;
  phone: string;
  director: string;
}

const ScienceCentersSection = () => {
  const [items, setItems] = React.useState<ScientificCenter[]>([])

  const getCenterPage = (id: number) => {
    if (id === 6) {
      return '/centers/education-quality'
    }
    if (id === 3) {
      return '/centers/monitoring-and-statistics'
    }
    if (id === 4) {
      return '/centers/professional-education'
    }
    if (id === 1) {
      return '/centers/priority-areas'
    }
    if (id === 2) {
      return '/centers/theoretical-and-applied-aspects'
    }

    return ''
  }

  React.useEffect(() => {
    fetchItems()
  }, [])

  const fetchItems = async () => {
    const url = `/api/science-centers/`
    let params: any = {}
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setItems(data)
    }
  }

  return (
    <PageSection>
      <Stack
        gap={Sizes.gap30.desktop}
        smallScreenGap={Sizes.gap30.smallScreen}
        tabletGap={Sizes.gap30.tablet}
        phoneGap={Sizes.gap30.phone}
      >
        <Heading variant='h2'>Научные центры</Heading>
        <Grid
          cols={3}
          smallScreenCols={2}
          tabletCols={1}
          phoneCols={1}

          columnGap={Sizes.gap32.desktop}
          smallScreenColumnGap={Sizes.gap32.smallScreen}
          tabletColumnGap={Sizes.gap32.tablet}
          phoneColumnGap={Sizes.gap32.phone}

          rowGap={Sizes.gap30.desktop}
          smallScreenRowGap={Sizes.gap30.smallScreen}
          tabletRowGap={Sizes.gap30.tablet}
          phoneRowGap={Sizes.gap30.phone}
        >
          {items.map((item) => {
            return (
              <Link to={getCenterPage(item.id)} key={item.id}>
                <Card
                  padding='20px'
                  height='100%'
                  borderColor='green-medium'
                  style={{
                    overflow: 'hidden'
                  }}
                >
                  <Stack gap={20}>
                    <Text variant='medium' color='green-medium'>{nbspacer(item.name)}</Text>
                    {false && <Text variant='medium' color='black-medium'>Директор - {item.director}</Text>}
                    {false && <Text variant='medium' color='black-medium'>{item.phone}</Text>}
                    {false && <Link to={item.email} underline style={{ whiteSpace: 'nowrap' }}>{item.email}</Link>}
                  </Stack>
                </Card>
              </Link>
            )
          })}
        </Grid>
      </Stack>
    </PageSection>
  )
}

export default ScienceCentersSection
