import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Card from 'components/v1/Card'
import EmployeeCard from 'components/v1/EmployeeCard'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import Stack from 'components/v1/Stack'
import Division from 'models/Division.d'
import Employee from 'models/Employee.d'
import React from 'react'

type DivisionCardProps = {
  division?: Division;
  employees: Employee[];
}

const DivisionCard = (props: DivisionCardProps) => {
  const device = useDevice()

  const getDivisionEmployees = () => {
    const employees = props.employees.filter(employee => employee.division_id === (props.division?.id || null))
    return employees as Employee[] || []
  }

  const getHead = () => {
    const head = getDivisionEmployees().find(employee => employee.position_type === 'head')
    return head as Employee || null
  }

  const getDeputies = () => {
    const employees = getDivisionEmployees().filter(employee => employee.position_type === 'deputy')
    return employees as Employee[] || []
  }

  const getEmployees = () => {
    const employees = getDivisionEmployees().filter(employee => employee.position_type === 'employee')
    return employees as Employee[] || []
  }

  return (
    <Card borderColor='green-medium'>
      <Stack gap={Sizes.gap30[device]}>
        {/* title */}
        {props.division &&
          <Heading
            variant='h3'
            color='green-medium'
          >
            {props.division.name}
          </Heading>
        }
        <Stack gap={Sizes.gap20[device]}>
          {/* heads */}
          <Grid
            cols={Sizes.cols3[device]}
            columnGap={Sizes.gap32[device]}
            rowGap={Sizes.gap30[device]}
          >
            {getHead() && <EmployeeCard {...getHead()} />}
            {getDeputies().map(deputy => <EmployeeCard key={deputy.id} {...deputy} />)}
          </Grid>

          {/* divider */}
          {(getHead() || getDeputies().length > 0) && getEmployees().length > 0 &&
            <div
              style={{
                width: '100%',
                height: 1,
                backgroundColor: '#C4C4C4'
              }}
            />
          }

          {/* employees */}
          <Grid
            columnGap={Sizes.gap32[device]}
            rowGap={Sizes.gap30[device]}
            cols={Sizes.cols3[device]}
          >
            {getEmployees().map(employee => <EmployeeCard key={employee.id} {...employee} />)}
          </Grid>
        </Stack>
      </Stack>
    </Card>
  )
}

export default DivisionCard
