import colors from 'common/colors'
import nbspacer from 'common/nbspacer'
import useDevice from 'common/useDevice'
import Banner from 'components/v1/Banner'
import Button from 'components/v1/Button'
import Card from 'components/v1/Card'
import Heading from 'components/v1/Heading'
import Image from 'components/v1/Image'
import Layout from 'components/v1/Layout/Layout'
import Link from 'components/v1/Link'
import ListItem from 'components/v1/ListItem/ListItem'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'

const ConditionsPage = () => {
  const device = useDevice()

  const gridTemplateColumns = {
    desktop: '1fr 15%',
    smallScreen: '1fr',
    tablet: '1fr',
    phone: '1fr'
  }

  return (
    <BasePage>
      <Layout variant='page-sections'>
        {/* Баннер */}
        <PageSection>
          <Banner
            heading='Условия поступления в аспирантуру'
            bgColor='green-light'
            breadcrumbs={[
              { name: 'Главная', url: '/' },
              { name: 'Образование', url: null },
              { name: 'Аспирантура', url: '/graduate-school' },
              { name: 'Условия поступления в аспирантуру', url: null }
            ]}
          />
        </PageSection>

        {/* Содержание */}
        <PageSection>
          <Layout
            variant='horizontal-70pct-30pct'
          >
            {/* left */}
            <Layout variant='vertical'>
              <Heading variant='h4' color='black-dark'>{nbspacer('Академия проводит прием на обучение по следующим условиям поступления с проведением отдельного конкурса по каждой совокупности условий поступления:')}</Heading>
              <Stack gap={20}>
                {[
                  'раздельно для обучения в Академии и для обучения в каждом филиале Академии;',
                  'по нескольким научным специальностям в пределах группы научных специальностей;',
                  'раздельно на места в рамках контрольных цифр и на места по договорам об оказании платных образовательных услуг;',
                  'раздельно на места в пределах целевой квоты и на места в рамках контрольных цифр за вычетом целевой квоты.'
                ].map((text, index) => (
                  <ListItem
                    key={index}
                    text={text}
                  />
                ))}
              </Stack>

              <div
                style={{
                  display: 'grid',
                  boxSizing: 'border-box',
                  columns: 2,
                  columnGap: 2,
                  rowGap: 20,
                  gridTemplateColumns: gridTemplateColumns[device]
                }}
              >
                {[
                  'Контрольные цифры приема аспирантов ФИРО РАНХиГС в 2023/2024 учебном году на бюджетной основе по группе научных специальностей 5.8. Педагогика',
                  '0 человек',
                  'Контрольные цифры приема аспирантов ФИРО РАНХиГС в 2023/2024 учебном году на договорной основе по группе научных специальностей 5.8. Педагогика',
                  '25 человек'
                ].map((text, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '13px 30px',
                      boxSizing: 'border-box',
                      backgroundColor: colors['blue-light']
                    }}
                  >
                    <Text>{nbspacer(text)}</Text>
                  </div>
                ))}
              </div>

              <div>
                <Button
                  label='Подать заявку'
                  size='small'
                  openInNewTab
                  to='https://lk.ranepa.ru/pk/auth.php'
                />
              </div>
            </Layout>
            {/* right */}
            <Layout variant='vertical'>
              <Image
                src='/static/img/pages/graduate-school/conditions/image1.jpg'
                width='100%'
                borderRadius='30px'
                style={{
                  border: `1px solid ${colors['grey-medium']}`
                }}
              />
              <Card
                borderColor='green-medium'
              >
                <Layout variant='vertical'>
                  <Text>{nbspacer('Организационными вопросами аспирантуры в ФИРО РАНХиГС занимаются:')}</Text>
                  <Stack gap={20}>
                    <Link to='mailto:Alekseeva-as@ranepa.ru' underline>Алексеева Анна Станиславовна</Link>
                    <Link to='mailto:Sharipova-ei@ranepa.ru' underline>Шарипова Эльвира Ирековна </Link>
                  </Stack>
                </Layout>
              </Card>
            </Layout>
          </Layout>
        </PageSection>
      </Layout>
    </BasePage>
  )
}

export default ConditionsPage
