import colors from 'common/colors'
import nbspacer from 'common/nbspacer'
import useDevice from 'common/useDevice'
import BannerCircleImg from 'components/v1/BannerCircleImg'
import Card from 'components/v1/Card'
import Heading from 'components/v1/Heading'
import Icon from 'components/v1/Icon'
import Image from 'components/v1/Image'
import Layout from 'components/v1/Layout/Layout'
import LinkWithArrow from 'components/v1/LinkWithArrow'
import ListItem from 'components/v1/ListItem/ListItem'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'

const ProjectsPage = () => {
  const device = useDevice()
  const imgSize = {
    desktop: 300,
    smallScreen: 280,
    tablet: 200,
    phone: 190
  }

  return (
    <BasePage>
      <Layout variant='page-sections'>
        <PageSection>
          <BannerCircleImg
            breadcrumbs={[
              { name: 'Проекты', url: null },
              { name: 'Навигатор образовательных программ по дошкольному образованию', url: null }
            ]}
            heading='Навигатор образовательных программ по дошкольному образованию'
            imageUrl='/static/img/pages/projects/image1.jpg'
          />
        </PageSection>

        <PageSection>
          <Layout variant='horizontal-50pct-50pct'>
            <Layout variant='vertical'>
              <Stack>
                <Text variant='larger'>Навигатор образовательных программ по дошкольному образованию</Text>
                <Text>(далее — Навигатор ДО)</Text>
              </Stack>
              <Text variant='larger'>{nbspacer('Это уникальный научно-методический ресурс по сохранению и развитию практики использования авторских программ и образовательных технологий по дошкольному образованию в Российской Федерации.')}</Text>
            </Layout>
            <Stack gap={12}>
              <div
                style={{
                  width: '200px',
                  height: '8px',
                  backgroundColor: colors['green-lighter']
                }}
              />
              <Text>{nbspacer('Навигатор ДО создан Федеральным институтом развития образования в 2015 году в рамках реализации Государственного контракта с Минобрнауки России № 08.028.11.0017 от 19 июля 2013 г. «Апробация и внедрение федерального государственного образовательного стандарта дошкольного образования, включая разработку учебно-методического обеспечения его введения». Актуализирован в 2019 году по инициативе Департамента государственной политики в сфере общего образования Минпросвещения России.')}</Text>
            </Stack>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout
            variant='image-content'
            imageSrc='/static/img/pages/projects/image2.jpg'
          >
            <Card
              bgColor='green-medium'
            >
              <Stack justifyContent='flex-end' height='100%'>
                <Stack gap={20}>
                  <Text variant='larger' color='white'>{nbspacer('Навигатор ДО —')}</Text>
                  <Text variant='larger' color='white'>{nbspacer('это вариативный выбор авторских программ и образовательных технологий для каждого детского сада при разработке собственной образовательной программы.')}</Text>
                </Stack>
              </Stack>
            </Card>
          </Layout>
        </PageSection>

        <PageSection>
          <Card
            borderColor='green-lighter'
            borderWidth={1}
          >
            <Layout variant='vertical'>
              <Heading variant='h4'>{nbspacer('Навигатор ДО - это методическая поддержка в проектировании образовательной деятельности организации с учетом')}</Heading>
              <Layout variant='horizontal-50pct-50pct'>
                <Layout variant='vertical'>
                  <ListItem text='психолого-возрастных особенностей контингента воспитанников' />
                  <ListItem text='уровня подготовки кадрового состава' />
                </Layout>
                <Layout variant='vertical'>
                  <ListItem text='профессиональных компетенций и интересов представителей педагогического коллектива' />
                  <ListItem text='актуальных потребностей родительской аудитории' />
                </Layout>
              </Layout>
            </Layout>
          </Card>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Layout
              variant='image-content'
              imageSrc='/static/img/pages/projects/image3.jpg'
            >
              <Card
                bgColor='green-lighter'
              >
                <Stack justifyContent='flex-end' height='100%'>
                  <Stack gap={20}>
                    <Heading variant='h2'>{nbspacer('Навигатор ДО сегодня —')}</Heading>
                    <Text variant='large'>{nbspacer('это 44 авторских программы по дошкольному образованию (21 комплексная и 23 парциальных программ).')}</Text>
                  </Stack>
                </Stack>
              </Card>
            </Layout>

            <Layout
              variant='horizontal-70pct-30pct'
            >
              <Card
                bgColor='green-medium'
                style={{
                  minHeight: '370px'
                }}
              >
                <Stack justifyContent='flex-end' height='100%'>
                  <Stack gap={20}>
                    <Heading variant='h2' color='white'>{nbspacer('Навигатор ДО в развитии — ')}</Heading>
                    <Text variant='large' color='white'>{nbspacer('это систематическое обновление и расширение пула авторских программ и образовательных технологий с учетом современных тенденций в области развития дошкольного образования в России.')}</Text>
                  </Stack>
                </Stack>
              </Card>
              <Card
                bgColor='green-lighter'
              >
                <Stack
                  height='100%'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Image
                    src='/static/img/pages/projects/image3.jpg'
                    width={imgSize[device]}
                    height={imgSize[device]}
                    objectFit='cover'
                    borderRadius='50%'
                  />
                </Stack>
              </Card>
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <LinkWithArrow
            to='/media/navigator_programm_do.zip'
            label='Получить доступ к материалам в Навигаторе ДО'
          />
        </PageSection>

        <PageSection>
          <Layout
            variant='image-content'
            imageSrc='/static/img/pages/projects/image3.jpg'
          >
            <Card bgColor='green-medium'>
              <Stack justifyContent='space-between' height='100%'>
                <Icon
                  variant='exclamation-point'
                  size={54}
                  color='white'
                />
                <Heading variant='h3' color='white'>
                  {nbspacer('Информация для авторов (разработчиков) образовательных программ по дошкольному образованию')}
                  <Icon
                    variant='arrow-right'
                    size={32} color='white'
                    style={{
                      position: 'relative',
                      top: '7px',
                      left: '4px'
                    }}
                  />
                </Heading>
              </Stack>
            </Card>
          </Layout>
        </PageSection>

      </Layout>
    </BasePage>
  )
}

export default ProjectsPage
