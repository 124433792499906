
import Stack from 'components/v1/Stack'
import ScienceCentersSection from './sections/ScienceCentersSection'
import BasePage from 'pages/BasePage'
import Sizes from 'common/Sizes'
import EducationSection from './sections/EducationSection'
import ActivitiesSection from './sections/ActivitiesSection'
import PageSection from 'components/v1/PageSection'
import Banner from 'components/v1/Banner'
import NewsSection from './sections/NewsSection'
import useDevice from 'common/useDevice'

const HomePage = () => {
  const device = useDevice()

  return (
    <BasePage>
      <Stack
        gap={Sizes.gap80[device]}
      >
        <PageSection>
          <Banner
            heading='ФИРО. Эксклюзивные экспертные работы и проекты в сфере образования'
            imageUrl='/static/img/pages/home/home-banner.jpg'
            linkTitle='Открыт набор в аспирантуру 2023'
            linkTo='/graduate-school'
          />
        </PageSection>
        <ScienceCentersSection />
        <EducationSection />
        <ActivitiesSection />
        <NewsSection />
      </Stack>
    </BasePage>
  )
}

export default HomePage
