import React from 'react'
import styles from './ListItemContent.module.css'
import Text, { TextVariant } from 'components/v1/Text'
import Color from 'common/Color.d'
import colors from 'common/colors'

type ListItemContentProps = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  textVariant?: TextVariant;
  textColor?: Color;
  bulletColor?: Color;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ListItemContent = (props: ListItemContentProps) => {
  const style: React.CSSProperties = {
  }

  const componentProps = {
    style: { ...style, ...props.style },
    className: styles['ListItemContent'],
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    onClick: props.onClick
  }

  return (
    <div {...componentProps}>
      <div
        className={styles['ListItemContent__bullet']}
        style={{ backgroundColor: colors[props.bulletColor ?? 'green-medium'] }}
      />
      <Text
        flexGrow={1}
        variant={props.textVariant}
        color={props.textColor}
      >{props.children}</Text>
    </div>
  )
}

export default ListItemContent
