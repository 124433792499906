import React from 'react'
import Card from 'components/v1/Card'
import Stack from 'components/v1/Stack'
import Text, { TextVariant } from 'components/v1/Text'
import Icon, { IconVariant } from 'components/v1/Icon'
import Color from 'common/Color.d'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import nbspacer from 'common/nbspacer'

type CardIconWithTextProps = {
  style?: React.CSSProperties;
  icon: IconVariant;
  iconWidth?: number;

  text: string;
  textVariant?: TextVariant;

  color?: Color;
  bgColor?: Color;
  minHeight?: React.CSSProperties['minHeight'];
  gridArea?: React.CSSProperties['gridArea'];
}

const CardIconWithText = (props: CardIconWithTextProps) => {
  const device = useDevice()

  const minHeights = {
    desktop: 370,
    smallScreen: 350,
    tablet: 300,
    phone: 250
  }

  const minHeight = props.minHeight ?? minHeights[device]
  const color = props.color ?? 'black-dark'
  const iconWidth = props.iconWidth ?? 120
  const textVariant = props.textVariant ?? 'large'
  return (
    <Card
      bgColor={props.bgColor}
      style={props.style}
      minHeight={minHeight}
      gridArea={props.gridArea}
    >
      <Stack
        gap={Sizes.gap30[device]}
        height='100%'
        justifyContent='space-between'
      >
        <Icon
          color={color}
          variant={props.icon}
          size={iconWidth}
        />
        <Text
          variant={textVariant}
          color={color}
        >
          {nbspacer(props.text)}
        </Text>
      </Stack>
    </Card>
  )
}

export default CardIconWithText
