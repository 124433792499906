import nbspacer from 'common/nbspacer'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Employee from 'models/Employee.d'
import React from 'react'
import Heading from './Heading'
import Icon from './Icon'
import Image from './Image'
import Link from './Link'
import Stack from './Stack'
import Text from './Text'

type EmployeeCardProps = {
} & Employee

const EmployeeCard = (props: EmployeeCardProps) => {
  const device = useDevice()
  return (
    <Stack
      orientation='horizontal'
      gap={Sizes.gap20[device]}
    >
      {props.position_type !== 'employee' && <Image
        height={85}
        width={85}
        src={props.image_url ?? ''}
        style={{ flexShrink: 0, objectFit: 'cover', borderRadius: '50%' }}
      />}
      <Stack gap={10}>
        {props.website &&
          <Link
            to={props.website ?? ''}
            underline
          >
            <Heading variant='h4'>{props.name}</Heading>
          </Link>
        }
        {!props.website &&
          <Heading variant='h4'>{props.name}</Heading>
        }
        <Text variant='small'>{nbspacer(props.position)}</Text>
        {props.telegram &&
          <Link to={props.telegram ?? ''}>
            <Icon
              variant='telegram'
              color='black-dark'
              size={48}
            />
          </Link>
        }
      </Stack>
    </Stack>
  )
}

export default EmployeeCard
