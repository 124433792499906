import React from 'react'
import Horizontal70pct30pct from '../Horizontal70pct30pct/Horizontal70pct30pct'
import Image from 'components/v1/Image'

type ImageContentProps = {
  children?: React.ReactNode;
  imageSrc: string;
  imageHeight?: React.CSSProperties['height'];
}

const ImageContent = (props: ImageContentProps) => {
  return (
    <Horizontal70pct30pct
      style={{
        minHeight: '370px'
      }}
    >
      {props.children}
      <Image
        src={props.imageSrc}
        borderRadius='30px'
        objectFit='cover'
        width='100%'
        height='100%'
      />

    </Horizontal70pct30pct>
  )
}

export default ImageContent
