import React from 'react'
import Heading from 'components/v1/Heading'
import Stack from 'components/v1/Stack'
import Breadcrumbs, { Breadcrumb } from 'components/v1/Breadcrumbs'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import nbspacer from 'common/nbspacer'
import colors from 'common/colors'
import Color from 'common/Color.d'
import Link from './Link'

type BannerProps = {
  heading?: string;
  bgColor?: Color;
  imageUrl?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  breadcrumbs?: Breadcrumb[];
  linkTitle?: string;
  linkTo?: string;
}

const Banner = (props: BannerProps) => {
  const device = useDevice()

  let style: React.CSSProperties = {
    minHeight: '370px',
    borderRadius: '30px',
    width: '100%',
    boxSizing: 'border-box',
    padding: Sizes.gap30[device]
  }

  if (props.bgColor) {
    style['backgroundColor'] = colors[props.bgColor ?? 'white']
  }

  if (props.imageUrl) {
    style['background'] = `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props.imageUrl}) no-repeat center center / cover`
  }

  const componentProps = {
    style: { ...style, ...props.style },
    className: props.className,
    onClick: props.onClick
  }

  return (
    <div {...componentProps}>
      <Stack
        justifyContent={(props.breadcrumbs || props.linkTo) ? 'space-between' : 'flex-end'}
        height='100%'
        style={{
          boxSizing: 'border-box',
          minHeight: '340px'
        }}
        gap={Sizes.gap30.desktop}
        smallScreenGap={Sizes.gap30.smallScreen}
        tabletGap={Sizes.gap30.tablet}
        phoneGap={Sizes.gap30.phone}
      >
        {props.breadcrumbs &&
          <Breadcrumbs
            items={props.breadcrumbs}
            color='white'
          />
        }
        <Heading variant='h1' color='white'>{nbspacer(props.heading ?? '')}</Heading>
        {props.linkTo &&
          <Link to={props.linkTo ?? ''}><Heading variant='h3' color='white' style={{textDecoration: 'underline'}}>{nbspacer(props.linkTitle ?? '')}</Heading></Link>
        }
      </Stack>
    </div>
  )
}

export default Banner
