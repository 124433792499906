import dateFormatter from 'common/dateFormatter'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Carousel from 'components/v1/Carousel/Carousel'
import Heading from 'components/v1/Heading'
import Link from 'components/v1/Link'
import LinkWithArrow from 'components/v1/LinkWithArrow'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import Activity from 'models/Activity.d'
import React from 'react'

const ActivitiesSection = () => {
  const device = useDevice()
  const [items, setItems] = React.useState<Activity[]>([])

  React.useEffect(() => {
    fetchItems()
  }, [])

  const fetchItems = async () => {
    const url = `/api/activities/`
    let params: any = {}
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setItems(data.results)
    }
  }

  const getFormattedTime = (time: string) => {
    const parts = time.split(':')
    if (parts.length < 2) return time
    return `${parts[0]}.${parts[1]}`
  }

  return (
    <PageSection>
      <Stack
        gap={Sizes.gap30[device]}
      >
        <Heading variant='h2'>Мероприятия</Heading>
        <Heading variant='h3'>Анонсы мероприятий ФИРО</Heading>
        <Carousel
          quantity={Sizes.cols3[device]}
          gap={Sizes.gap30[device]}
          circular={true}
          autoScroll={true}
        >
          {items.map((item: Activity) => (
            <Stack
              key={item.id}
              gap={Sizes.gap30[device]}
            >
              <Text variant='larger' color='green-medium'>
                {dateFormatter.dayMonth(item.date)}
                {item.time && ` в ${getFormattedTime(item.time)} МСК`}
              </Text>
              <Text variant='medium' color='green-medium'>{item.name}</Text>
              <Text variant='small'>{item.science_center_name}</Text>
              {item.info_url && <Link to={item.info_url || ''} underline>Информация о мероприятии</Link>}
              {item.registration_url && <Link to={item.registration_url || ''} underline>Ссылка на регистрацию</Link>}
              {item.stream_url && <Link to={item.stream_url || ''} underline>Ссылка на трансляцию</Link>}
            </Stack>
          ))}
        </Carousel>
        <LinkWithArrow
          label='Анонсы всех мероприятий'
          to='/activities'
        />
      </Stack>
    </PageSection>
  )
}

export default ActivitiesSection
