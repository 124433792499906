import Color from 'common/Color.d'
import Sizes from 'common/Sizes'
import nbspacer from 'common/nbspacer'
import useDevice from 'common/useDevice'
import Banner from 'components/v1/Banner'
import Card from 'components/v1/Card'
import ContentCard from 'components/v1/ContentCard/ContentCard'
import ExpandableCard from 'components/v1/ExpandableCard/ExpandableCard'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import Icon, { IconVariant } from 'components/v1/Icon'
import Layout from 'components/v1/Layout/Layout'
import Link from 'components/v1/Link'
import ListItem from 'components/v1/ListItem/ListItem'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'

const MonitoringAndStatisticsPage = () => {
  const device = useDevice()

  const numbersItems: {
    icon?: IconVariant;
    text: string;
    textColor: Color;
    description: string;
    descriptionColor: Color;
    bgColor?: Color;
    imageSrc?: string;
  }[] =
    [
      {
        icon: 'letter-a',
        text: '103',
        textColor: 'white',
        description: 'научных статей',
        descriptionColor: 'white',
        bgColor: 'green-medium'
      },
      {
        text: '15 лет',
        textColor: 'white',
        description: 'представляли Россию в крупнейшей международной программе по сравнению образовательных систем',
        descriptionColor: 'white',
        imageSrc: '/static/img/pages/centers/monitoring-and-statistics/image3.jpg'
      },
      {
        icon: 'book-simple',
        text: '15',
        textColor: 'white',
        description: 'монографий и глав в монографиях',
        descriptionColor: 'white',
        bgColor: 'green-light'
      },
      {
        icon: 'page-with-check',
        text: '11',
        description: 'статистическо-аналитических сборника, включая иностранные',
        textColor: 'black-dark',
        descriptionColor: 'black-dark',
        bgColor: 'green-lighter'
      },
      {
        icon: 'people',
        text: '27',
        description: 'рейтингов спроектировано',
        textColor: 'white',
        descriptionColor: 'white',
        bgColor: 'green-medium'
      },
      {
        icon: 'book-with-cog-opened',
        text: '27',
        description: 'методик оценки образовательной политики разработано',
        textColor: 'white',
        descriptionColor: 'white',
        bgColor: 'green-light'
      },
      {
        text: '73 981',
        textColor: 'white',
        description: 'учителей участвовало в самом масштабном мониторинговом исследовании',
        descriptionColor: 'white',
        bgColor: 'green-medium'
      },
      {
        icon: 'book-simple',
        text: '14',
        description: 'всероссийских мониторинговых исследований проведено',
        textColor: 'white',
        descriptionColor: 'white',
        bgColor: 'green-light'
      },
      {
        icon: 'page-with-check',
        text: '23',
        description: 'НИР',
        textColor: 'black-dark',
        descriptionColor: 'black-dark',
        bgColor: 'green-lighter'
      },
      {
        icon: 'people',
        text: '30',
        description: 'РИД',
        textColor: 'white',
        descriptionColor: 'white',
        bgColor: 'green-medium'
      }
    ]

  return (
    <BasePage>
      <Layout variant='page-sections'>

        <PageSection>
          <Layout variant='vertical'>
            <Banner
              heading='Центр мониторинга и статистики образования'
              imageUrl='/static/img/pages/centers/monitoring-and-statistics/image1.jpg'
              breadcrumbs={[
                { name: 'Главная', url: '/' },
                { name: 'Центры', url: null }
              ]}
            />
            <Stack
              orientation='horizontal'
              phoneOrientation='vertical'
              gap={100}
              phoneGap={20}
            >
              <Stack
                justifyContent='center'
              >
                <Icon
                  variant='hat'
                  size={145}
                  color='green-medium'
                />
              </Stack>
              <Stack
                gap={20}
                height='100%'
                justifyContent='center'
                width='80%'
                tabletWidth='100%'
                phoneWidth='100%'
              >
                <Text variant='large'>{nbspacer('Один из старейших в России научных центров, занимающийся теорией и практикой информационно-аналитического обеспечения управления в сфере образования. Эксперты центра имеют многолетний опыт международной компаративистики и представления Российской Федерации в крупных международных программах по статистике образования.')}</Text>
                <Stack gap={10}>
                  <Heading variant='h3'>Цель Центра</Heading>
                  <Text>{nbspacer('проведение фундаментальных и прикладных исследований в интересах развития национального научного потенциала, а также комплексное экспертно-аналитическое и методическое обеспечение органов государственной власти и местного самоуправления по вопросам компаративистики, мониторинга, прогноза и оценки образовательных систем и образовательной политики.')}</Text>
                </Stack>
              </Stack>
            </Stack>
          </Layout>
        </PageSection>

        <PageSection>
          <ExpandableCard
            title='Задачи центра'
          >
            <Layout variant='horizontal-50pct-50pct'>
              <Layout variant='vertical'>
                <ListItem text='Методология формирования информационной базы управления, включая разработку индикаторов' />
                <ListItem text='Международная компаративистика' />
                <ListItem text='Анализ, оценка, сравнение и прогнозирование на основе всех видов данных' />
                <ListItem text='Коллаборация, включая международную, в сфере информационно-аналитического сопровождения системы образования.' />
              </Layout>
              <Layout variant='vertical'>
                <ListItem text='Разработка рейтингов и иных сравнительно-оценочных механизмов управления' />
                <ListItem text='Просветительская и образовательная деятельность' />
                <ListItem text='Мониторинги и мониторинговые исследования' />
              </Layout>
            </Layout>
          </ExpandableCard>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Цифры</Heading>

            <Layout variant='grid-30pct-70pct--30pct-30pct-30pct'>
              {numbersItems.map((item, index) => (
                <ContentCard
                  key={index}
                  variant={item.imageSrc ? 'bg-image-text-description' : (item.icon ? 'icon-text-description' : 'text-description')}
                  style={{
                    gridArea: `grid-30pct-70pct--30pct-30pct-30pct__item-${index + 1}`
                  }}
                  icon={item.icon ?? 'book'}
                  text={item.text}
                  textColor={item.textColor}
                  description={item.description}
                  descriptionColor={item.descriptionColor}
                  bgColor={item.bgColor}
                  imageSrc={item.imageSrc}
                />
              ))}
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Результаты деятельности</Heading>
            <Grid
              cols={Sizes.cols3[device]}
              columnGap={Sizes.gap32[device]}
              rowGap={Sizes.gap30[device]}
            >
              <Link to='scientific-works/research'>
                <ContentCard
                  icon='book-with-bookmark'
                  variant='accent-icon-text'
                  text='Научные исследования'
                />
              </Link>
              <Link to='scientific-works/publication'>
                <ContentCard
                  icon='pages-t'
                  variant='accent-icon-text'
                  text='Публикации'
                />
              </Link>
              <Link to='scientific-works/ia_result'>
                <ContentCard
                  icon='pages-t'
                  variant='accent-icon-text'
                  text='РИД'
                />
              </Link>
            </Grid>
          </Layout>
        </PageSection>

        <PageSection>
          <Card
            borderColor='green-medium'
          >
            <Layout variant='vertical'>
              <Heading
                variant='h3'
                color='green-medium'
              >
                Наши контакты
              </Heading>
              <Grid
                cols={Sizes.cols4[device]}
                columnGap={Sizes.gap32[device]}
                rowGap={Sizes.gap30[device]}
              >
                <Stack gap={12}>
                  <Text
                    variant='small'
                    color='black-medium'
                  >
                    Директор Центра
                  </Text>
                  <Text
                    color='green-medium'
                  >
                    Селиверстова Ирина Валериевна
                  </Text>
                </Stack>
                <Stack gap={12}>
                  <Text
                    variant='small'
                    color='black-medium'
                  >
                    Почта
                  </Text>
                  <Link
                    to='mailto:seliverstova-iv@ranepa.ru'
                    color='green-medium'
                    underline
                  >
                    seliverstova-iv@ranepa.ru
                  </Link>
                </Stack>
                <Stack gap={12}>
                  <Text
                    variant='small'
                    color='black-medium'
                  >
                    Телефон
                  </Text>
                  <Text
                    color='green-medium'
                  >
                    +7 (499) 956 94 43
                  </Text>
                </Stack>
                <Stack gap={12}>
                  <Text
                    variant='small'
                    color='black-medium'
                  >
                    Адрес
                  </Text>
                  <Text
                    color='green-medium'
                  >
                    {nbspacer('Москва, ул.3-я Кабельная, д.1, стр.1')}
                  </Text>

                </Stack>
              </Grid>
            </Layout>
          </Card>
        </PageSection>
      </Layout>
    </BasePage>
  )
}

export default MonitoringAndStatisticsPage
