import React from 'react'
import BasePage from 'pages/BasePage'
import HeaderSection from './sections/HeaderSection'
import Stack from 'components/v1/Stack'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import YearsSection from './sections/YearsSection'
import LinkWithArrow from 'components/v1/LinkWithArrow'
import PageSection from 'components/v1/PageSection'
import MissionSection from './sections/MissionSection'
import TacticalGoalsSection from './sections/TacticalGoalsSection'
import StrategicCoalsSection from './sections/StrategicCoalsSection'
import TodaySection from './sections/TodaySection'

const AboutPage = () => {
  const device = useDevice()
  return (
    <BasePage>
      <Stack
        gap={Sizes.gap80[device]}
      >
        <HeaderSection />

        <YearsSection />

        <TodaySection />

        <PageSection>
          <LinkWithArrow
            label='Стратегия развития'
            to='/'
          />
        </PageSection>

        <MissionSection />

        <TacticalGoalsSection />

        <StrategicCoalsSection />
      </Stack>
    </BasePage>
  )
}

export default AboutPage
