import Color from 'common/Color.d'
import Sizes from 'common/Sizes'
import nbspacer from 'common/nbspacer'
import useDevice from 'common/useDevice'
import Banner from 'components/v1/Banner'
import CenterContacts from 'components/v1/CenterContacts'
import ContentCard from 'components/v1/ContentCard/ContentCard'
import ExpandableCard from 'components/v1/ExpandableCard/ExpandableCard'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import Icon, { IconVariant } from 'components/v1/Icon'
import Image from 'components/v1/Image'
import Layout from 'components/v1/Layout/Layout'
import Link from 'components/v1/Link'
import ListItem from 'components/v1/ListItem/ListItem'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'

const ProfessionalEducationPage = () => {
  const device = useDevice()

  const numbersItems: {
    icon?: IconVariant;
    text: string;
    textColor: Color;
    description: string;
    descriptionColor: Color;
    bgColor?: Color;
    imageSrc?: string;
  }[] =
    [
      {
        text: '35',
        description: 'НИР',
        textColor: 'black-dark',
        descriptionColor: 'black-dark',
        bgColor: 'green-lighter',
        icon: 'book-simple'
      },
      {
        text: '22',
        description: 'научно -методических площадок',
        textColor: 'white',
        descriptionColor: 'white',
        bgColor: 'green-light',
        icon: 'people'
      },
      {
        text: '70',
        description: 'организаций- партнеров',
        descriptionColor: 'white',
        textColor: 'white',
        bgColor: 'green-medium',
        icon: 'letter-a'
      },
      {
        text: '25',
        description: 'методических разработок',
        descriptionColor: 'white',
        textColor: 'white',
        bgColor: 'green-medium',
        icon: 'book-simple'
      },
      {
        text: '57',
        description: 'реализованных проекта',
        textColor: 'black-dark',
        descriptionColor: 'black-dark',
        bgColor: 'green-lighter',
        icon: 'half-cog'
      },
      {
        text: '85',
        description: 'проведенных мероприятий',
        textColor: 'white',
        descriptionColor: 'white',
        bgColor: 'green-light',
        icon: 'people-communication'
      }
    ]

  return (
    <BasePage>
      <Layout variant='page-sections'>

        <PageSection>
          <Layout variant='vertical'>
            <Banner
              heading='Центр профессионального образования и систем квалификаций'
              bgColor='green-light'
              breadcrumbs={[
                { name: 'Главная', url: '/' },
                { name: 'Центр профессионального образования и систем квалификаций', url: null }
              ]}
            />
            <Stack
              orientation='horizontal'
              phoneOrientation='vertical'
              gap={100}
              phoneGap={20}
            >
              <Stack
                justifyContent='center'
              >
                <Icon
                  variant='certificate'
                  color='green-medium'
                  size={145}
                />
              </Stack>
              <Layout
                variant='vertical'
                justifyContent='center'
              >
                <Text variant='large'>{nbspacer('Центр выполняет научно-исследовательские работы в сфере прогнозирования развития профессионального образования и подготовки конкурентоспособных рабочих кадров.')}</Text>
                <Text variant='large'>{nbspacer('В круг научных интересов Центра входят вопросы формирования современной системы квалификаций, подходы к разработке профессиональных стандартов и отраслевых рамок квалификаций.')}</Text>
                <Layout variant='vertical'>
                  <Link to='https://disk.yandex.ru/i/OLKQ7WtNesTEkA' underline>{'Проект профессионального стандарта "Педагог профессионального обучения, СПО"'}</Link>
                  <Link to='https://disk.yandex.ru/i/17uXefZXe0qsRQ' underline>{'Проект профессионального стандарта "Руководитель профессиональной образовательной организации"'}</Link>
                </Layout>
              </Layout>
            </Stack>

          </Layout>
        </PageSection>

        <PageSection>
          <ExpandableCard
            title='Цель и задачи Центра'
          >
            <Layout variant='horizontal-50pct-50pct'>
              <Layout variant='vertical'>
                <Text
                  variant='larger'
                  color='green-medium'
                >
                  Цель
                </Text>

                <ListItem text='Научно-методическое, экспертно-аналитическое и организационное сопровождение развития профессионального образования.' />
              </Layout>
              <Layout variant='vertical'>
                <Text
                  variant='larger'
                  color='green-medium'
                >
                  Задачи
                </Text>
                <ListItem text='Получение новых научно-педагогических знаний в сфере развития профессионального образования' />
                <ListItem text='Разработка научно-методических и нормативно-методических материалов в целях обеспечения образовательных и управленческих процессов в профессиональном образовании' />
                <ListItem text='Обеспечение взаимодействия экспертного сообщества с научными, образовательными и производственными организациями в целях распространения и внедрения лучших практик профессионального образования.' />
              </Layout>
            </Layout>
          </ExpandableCard>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Направления деятельности Центра</Heading>
            <Layout variant='horizontal-50pct-50pct'>
              <Layout variant='vertical'>
                <ListItem text='Выполнение научно-исследовательских работ.' />
                <ListItem text='Образовательная деятельность.' />
                <ListItem text='Организация взаимодействия научных, образовательных и производственных организаций по распространению научного знания, передового педагогического опыта и лучших практик сотрудничества с бизнес-сообществом.' />
              </Layout>
              <Layout variant='vertical'>
                <ListItem text='Сопровождение инновационных практик в системе высшего и среднего профессионального образования.' />
                <ListItem text='Экспертно-аналитическая деятельность.' />
                <ListItem text='Проектная деятельность.' />
              </Layout>
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Цифры</Heading>

            <Grid
              cols={Sizes.cols3[device]}
              columnGap={Sizes.gap32[device]}
              rowGap={Sizes.gap30[device]}
            >
              {numbersItems.map((item, index) => (
                <ContentCard
                  key={index}
                  variant={item.imageSrc ? 'bg-image-text-description' : 'icon-text-description'}
                  icon={item.icon ?? 'book'}
                  text={item.text}
                  textColor={item.textColor}
                  description={item.description}
                  descriptionColor={item.descriptionColor}
                  bgColor={item.bgColor}
                  imageSrc={item.imageSrc}
                />
              ))}
            </Grid>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Результаты деятельности</Heading>

            <Layout variant='grid-30pct-70pct--30pct-30pct-30pct'>
              <ContentCard
                icon='folder-with-star'
                variant='accent-icon-text'
                text='Научно-методические площадки'
                style={{
                  gridArea: `grid-30pct-70pct--30pct-30pct-30pct__item-1`
                }}
              />
              <Image
                borderRadius='30px'
                width='100%'
                height='100%'
                src='/static/img/pages/centers/professional-education/image3.jpg'
                objectFit='cover'
                style={{
                  gridArea: `grid-30pct-70pct--30pct-30pct-30pct__item-2`
                }}
              />
              <Link to='scientific-works/research'>
                <ContentCard
                  icon='book-with-bookmark'
                  variant='accent-icon-text'
                  text='Научные исследования'
                  style={{
                    gridArea: `grid-30pct-70pct--30pct-30pct-30pct__item-3`
                  }}
                />
              </Link>
              <Link to='scientific-works/publication'>
                <ContentCard
                  icon='pages-t'
                  variant='accent-icon-text'
                  text='Публикации'
                  style={{
                    gridArea: `grid-30pct-70pct--30pct-30pct-30pct__item-4`
                  }}
                />
              </Link>
              <Link to='scientific-works/ia_result'>
                <ContentCard
                  icon='pages-t'
                  variant='accent-icon-text'
                  text='РИД'
                  style={{
                    gridArea: `grid-30pct-70pct--30pct-30pct-30pct__item-5`
                  }}
                />
              </Link>
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <CenterContacts
            name='Блинов Владимир Игоревич'
            email='Blinov-vi@ranepa.ru'
            phone='+7 499 956-94-41'
            address='Москва, ул.3-я Кабельная, д.1, стр.1'
          />
        </PageSection>
      </Layout>
    </BasePage>
  )
}

export default ProfessionalEducationPage
