import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Heading from 'components/v1/Heading'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import React from 'react'
import Image from 'components/v1/Image'

const MissionSection = () => {
  const device = useDevice()

  return (
    <PageSection>
      <Stack
        orientation='horizontal'
        phoneOrientation='vertical'
        gap={Sizes.gap32[device]}
        alignItems='center'
      >
        <Stack gap={Sizes.gap30[device]}>
          <Heading variant='h2'>Миссия института</Heading>
          <Text variant='large'>Решение фундаментальных и прикладных задач в сфере образования посредством интеграции научно-исследовательской, экспертно-аналитической, научно-методической, образовательной, информационной деятельности Института для достижения национальных целей образования Российской Федерации.</Text>
        </Stack>
        <Image
          height='245px'
          width={Sizes.width33[device]}
          src='/static/img/pages/about/image4.jpg'
          style={{ flexShrink: 0, objectFit: 'cover', borderRadius: '30px' }}
        />
      </Stack>
    </PageSection>
  )
}

export default MissionSection
