import React, { useState } from 'react'
import Layout from '../Layout/Layout'
import Text from 'components/v1/Text'
import styles from './ExpandableCard.module.css'

type ExpandableCardProps = {
  title: string;
  children?: React.ReactNode;
}

const ExpandableCard = (props: ExpandableCardProps) => {
  const [hover, setHover] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const getTextColor = () => {
    if (hover) {
      return 'green-dark'
    }
    return 'green-medium'
  }

  const getWidth = () => {
    if (expanded) {
      return '100%'
    }
    return undefined
  }

  const getArrowOffset = () => {
    if (hover && !expanded) {
      return -3
    }
    return 0
  }

  const getArrowTransform = () => {
    if (expanded) {
      return 'rotate(90deg)'
    }
    return 'rotate(0deg)'
  }

  return (
    <div
      className={styles['ExpandableCard']}
      style={{
        width: getWidth()
      }}
    >
      <Layout
        variant='vertical'
      >
        <Layout
          variant='horizontal'
          alignItems='flex-start'
          style={{
            cursor: 'pointer'
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setExpanded(!expanded)}
        >
          <Text
            variant='larger'
            color={getTextColor()}
            flexGrow={1}
          >{props.title}</Text>
          <svg
            style={{
              position: 'relative',
              right: getArrowOffset(),
              transform: getArrowTransform()
            }}
            width="50" height="53" viewBox="0 0 50 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.65625" y1="26.0664" x2="46.8645" y2="26.0664" stroke="#3A3A3A" strokeWidth="3" />
            <line x1="23.3575" y1="1.93934" x2="48.1734" y2="26.7552" stroke="#3A3A3A" strokeWidth="3" />
            <line x1="21.2362" y1="51.3222" x2="46.052" y2="26.5063" stroke="#3A3A3A" strokeWidth="3" />
          </svg>
        </Layout>
        {expanded && props.children}
      </Layout>
    </div>
  )
}

export default ExpandableCard
