import dateFormatter from 'common/dateFormatter'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Breadcrumbs from 'components/v1/Breadcrumbs'
import Card from 'components/v1/Card'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import Link from 'components/v1/Link'
import PageSection from 'components/v1/PageSection'
import ShowMoreButton from 'components/v1/ShowMoreButton'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import Activity from 'models/Activity.d'
import ActivityTag from 'models/ActivityTag.d'
import BasePage from 'pages/BasePage'
import React from 'react'
import ActivityTags from './ActivityTags'

const PAGE_SIZE = 9

const EventsPage = () => {
  const device = useDevice()

  const [canFetchMore, setCanFetchMore] = React.useState(true)
  const [page, setPage] = React.useState(1)
  const [items, setItems] = React.useState<Activity[]>([])
  const [tag, setTag] = React.useState<ActivityTag | null>(null)

  const getFormattedTime = (time: string) => {
    const parts = time.split(':')
    if (parts.length < 2) return time
    return `${parts[0]}.${parts[1]}`
  }

  React.useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetchItems()
  }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (page === 1) {
      fetchItems()
    } else {
      setPage(1)
    }
  }, [tag]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchItems = async () => {
    const url = `/api/activities/`
    let params: any = {
      page_size: PAGE_SIZE,
      page: page,
      tag_id: tag?.id ?? ''
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      if (page > 1) {
        setItems([...items, ...data.results])
      } else {
        setItems(data.results)
      }

      if (page * PAGE_SIZE >= data.count) {
        setCanFetchMore(false)
      } else {
        setCanFetchMore(true)
      }
    } else {
      setCanFetchMore(false)
    }
  }

  return (
    <BasePage>
      <PageSection>
        <Stack
          gap={Sizes.gap30[device]}
        >
          <Breadcrumbs
            items={[
              { name: 'Главная', url: '/' },
              { name: 'Мероприятия', url: null }
            ]}
            color='grey-medium'
          />

          <Heading variant='h1'>Анонсы всех мероприятий</Heading>

          <ActivityTags onChange={(tag: ActivityTag | null) => setTag(tag)} />

          <Stack
            gap={Sizes.gap40[device]}
          >
            <Grid
              cols={Sizes.cols3[device]}
              columnGap={Sizes.gap32[device]}
              rowGap={Sizes.gap30[device]}
            >
              {items.map((item: Activity) => (
                <Card
                  key={item.id}
                  padding={`${Sizes.gap30[device]}px`}
                  borderColor='green-medium'
                  borderWidth={2}
                >
                  <Stack
                    gap={Sizes.gap30[device]}
                  >
                    <Text
                      variant='larger'
                      color='green-medium'
                    >
                      {dateFormatter.dayMonth(item.date)}
                      {item.time && ` в ${getFormattedTime(item.time)} МСК`}
                    </Text>
                    <Text color='green-medium'>
                      {item.name}
                    </Text>
                    <Text variant='small'>
                      {item.science_center_name}
                    </Text>
                    {item.stream_url && <Link to={item.stream_url || ''} underline>Ссылка на трансляцию</Link>}
                  </Stack>
                </Card>
              ))}
            </Grid>
          </Stack>

          <ShowMoreButton
            disabled={!canFetchMore}
            onClick={() => setPage(page + 1)}
          />
        </Stack>
      </PageSection>
    </BasePage>
  )
}

export default EventsPage
