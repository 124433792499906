import React from 'react'
import Card from 'components/v1/Card'
import Layout from 'components/v1/Layout/Layout'
import Icon, { IconVariant } from 'components/v1/Icon'
import Text from 'components/v1/Text'
import nbspacer from 'common/nbspacer'
import Color from 'common/Color.d'
import Stack from 'components/v1/Stack'

type IconTextDescriptionProps = {
  icon: IconVariant;
  text: string;
  description: string;

  iconColor?: Color;
  textColor?: Color;
  descriptionColor?: Color;
  bgColor?: Color;

  minHeight?: React.CSSProperties['height'];
  style?: React.CSSProperties;
}

const defaultColor = 'white'
const defaultBgColor = 'green-medium'

const IconTextDescription = (props: IconTextDescriptionProps) => {
  return (
    <Card
      bgColor={props.bgColor ?? defaultBgColor}
      minHeight={props.minHeight ?? '370px'}
      style={props.style}
    >
      <Layout
        variant='vertical'
        alignItems='flex-start'
      >
        <Icon
          size={100}
          color={props.iconColor ?? defaultColor}
          variant={props.icon}
        />
        <Stack>
          <Text
            variant='largest'
            color={props.textColor ?? defaultColor}
          >
            {nbspacer(props.text)}
          </Text>
          <Text
            variant='larger'
            color={props.descriptionColor ?? defaultColor}
          >
            {nbspacer(props.description ?? defaultColor)}
          </Text>
        </Stack>
      </Layout>
    </Card>
  )
}

export default IconTextDescription
