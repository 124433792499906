import React, { useState } from 'react'
import Link from 'components/v1/Link'
import Stack from 'components/v1/Stack'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Heading from './Heading'
import Text from './Text'
import colors from 'common/colors'

type LinkWithArrowAndButtonProps = {
  to: string;
  label?: string;
  width?: number | string;
  buttonLabel?: string;
  buttonUrl?: string;
}

const LinkWithArrowAndButton = (props: LinkWithArrowAndButtonProps) => {
  const device = useDevice()
  const [hover, setHover] = useState(false)

  const getArrowOffset = () => {
    if (hover) {
      // return -3
      return 0
    }
    return 0
  }

  const handleClick = (event: any) => {
    event.stopPropagation()
  }

  const defaultWidths = {
    desktop: '100%',
    smallScreen: '100%',
    tablet: '100%',
    phone: '100%'
  }

  return (
    <Link
      to={props.to}
      style={{
        padding: 27,
        backgroundColor: hover ? colors['green-medium'] : colors['green-dark'],
        borderRadius: '16px',
        display: 'inline-block',
        boxSizing: 'border-box',
        width: props.width ?? defaultWidths[device]
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Stack
        orientation='horizontal'
        alignItems={'center'}
        justifyContent='space-between'
        gap={Sizes.gap32[device]}
      >
        <Heading
          variant='h3'
          color='white'
        >
          {props.label}
        </Heading>

        {props.buttonUrl &&
          <Link to={props.buttonUrl ?? ''} onClick={handleClick}>
            <Text color='green-medium' style={{ backgroundColor: 'white', padding: '14px 33px', borderRadius: '1000px' }}>{props.buttonLabel}</Text>
          </Link>}

        {/* arrow */}
        <svg
          style={{
            position: 'relative',
            right: getArrowOffset()
          }}
          width="50" height="53" viewBox="0 0 50 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.65625" y1="26.0664" x2="46.8645" y2="26.0664" stroke="#FFF" strokeWidth="3" />
          <line x1="23.3575" y1="1.93934" x2="48.1734" y2="26.7552" stroke="#FFF" strokeWidth="3" />
          <line x1="21.2362" y1="51.3222" x2="46.052" y2="26.5063" stroke="#FFF" strokeWidth="3" />
        </svg>
      </Stack>
    </Link>
  )
}

export default LinkWithArrowAndButton
