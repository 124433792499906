import nbspacer from 'common/nbspacer'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Breadcrumbs from 'components/v1/Breadcrumbs'
import Heading from 'components/v1/Heading'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import Division from 'models/Division.d'
import Employee from 'models/Employee.d'
import BasePage from 'pages/BasePage'
import React from 'react'
import DivisionCard from './components/DivisionCard'

const TeamPage = () => {
  const device = useDevice()
  const [employees, setEmployees] = React.useState<Employee[]>([])
  const [divisions, setDivisions] = React.useState<Division[]>([])

  React.useEffect(() => {
    fetchData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    await fetchDivisions()
    await fetchEmployees()
    console.log(employees)
  }

  const fetchEmployees = async () => {
    const url = `/api/employees/`
    let params: any = {
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setEmployees(data)
    }
  }

  const fetchDivisions = async () => {
    const url = `/api/employee-divisions/`
    let params: any = {
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setDivisions(data)
    }
  }

  const smallTextWidths = {
    desktop: '80%',
    smallScreen: '80%',
    tablet: '100%',
    phone: '100%'
  }

  return (
    <BasePage>
      <PageSection>
        <Stack
          gap={Sizes.gap30[device]}
        >
          <Breadcrumbs
            items={[
              { name: 'Главная', url: '/' },
              { name: 'Команда', url: null }
            ]}
            color='grey-medium'
          />

          <Heading
            variant='h1'
          >
            Команда
          </Heading>

          <Stack
            gap={Sizes.gap30[device]}
          >
            <DivisionCard
              employees={employees}
            />

            <Stack
              alignItems='center'
            >
              <Text
                variant='small'
                textAlign='center'
                style={{
                  width: smallTextWidths[device]
                }}
              >
                {nbspacer('Команды ФИРО, выполняющие научно-исследовательские работы в рамках государственного задания № 075-00690-23 ПР на 2023 год и на плановый период 2024 и 2025 годов, утвержденного " 26 " декабря 2022 г. № 15842п-П8')}
              </Text>
            </Stack>

            {divisions.map((division) => (
              <div key={division.id}>
                <DivisionCard
                  division={division}
                  employees={employees}
                />
              </div>
            ))}
          </Stack>
        </Stack>
      </PageSection>
    </BasePage>
  )
}

export default TeamPage
