const DayMonth = (date: string) => {
  const monthNames = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря'
  ]

  const dateObject = new Date(date)
  const monthNumber = dateObject.getMonth()
  const day = dateObject.getDate()
  return `${day} ${monthNames[monthNumber]}`
}

export default {
  dayMonth: DayMonth
}
