import colors from 'common/colors'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import React from 'react'

const TacticalGoalsSection = () => {
  const device = useDevice()

  const items1 = [
    'Упрочнение признания научных, исследовательских и методических достижений Института российскими и зарубежными учеными, руководителями образовательных организаций, научными организациями и органами государственной власти в сфере образования.',
    'Обеспечение стабильного развития Института и высокого уровня адаптивности к внешним вызовам и переменам.',
    'Проведение опережающих комплексных научных исследований и разработок в сфере образования, соответствующих приоритетным направлениям развития системы образования Российской Федерации.',
    'Обеспечение эффективного решения общесистемных задач образования, преемственности разных уровней образования.'
  ]

  const items2 = [
    'Развитие партнерских связей в сфере научного прогнозирования исследовательской деятельности Института и формирования пула бенефициаров перспективных проектов.',
    'Укрепление единства педагогической науки и практики, теории и методики образования путем распространения результатов научных исследований и знаний в профессиональной среде через образовательную, экспертную, консультационную деятельность.',
    'Обеспечение стабильного развития Института и высокого уровня готовности к внешним вызовам и переменам.',
    'Развитие корпоративной культуры, обеспечивающей поддержку индивидуальности и научного потенциала работников Института на принципах системности, диалогичности и сотрудничества.'
  ]

  return (
    <PageSection>
      <Stack gap={Sizes.gap40[device]}>
        <Heading variant='h1'>Тактические цели:</Heading>
        <Grid
          cols={Sizes.cols2[device]}
          columnGap={Sizes.gap128[device]}
          rowGap={Sizes.gap30[device]}
        >
          <Stack
            gap={Sizes.gap30[device]}
            alignItems='flex-start'
          >
            {items1.map((item, index) => (
              <Stack
                key={index}
                orientation='horizontal'
                gap={Sizes.gap20[device]}
              >
                <div
                  style={{
                    height: '10px',
                    width: '10px',
                    background: colors['green-medium'],
                    borderRadius: '50%',
                    flexShrink: 0
                  }}
                />
                <Text>{item}</Text>
              </Stack>
            ))}
          </Stack>
          <Stack
            gap={Sizes.gap30[device]}
          >
            {items2.map((item, index) => (
              <Stack
                key={index}
                orientation='horizontal'
                gap={Sizes.gap20[device]}
              >
                <div
                  style={{
                    height: '10px',
                    width: '10px',
                    background: colors['green-medium'],
                    borderRadius: '50%',
                    flexShrink: 0
                  }}
                />
                <Text>{item}</Text>
              </Stack>
            ))}
          </Stack>
        </Grid>
      </Stack>
    </PageSection >
  )
}

export default TacticalGoalsSection
