import colors from 'common/colors'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import React, { useState } from 'react'
import Text from './Text'
// import styles from './ShowMoreButton.module.css'

type ShowMoreButtonProps = {
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const ShowMoreButton = (props: ShowMoreButtonProps) => {
  const device = useDevice()
  const [hover, setHover] = useState(false)

  const getTextColor = () => {
    if (props.disabled) {
      return 'grey-medium'
    }
    if (hover) {
      return 'green-dark'
    }
    return 'green-medium'
  }

  const style: React.CSSProperties = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '30px',
    padding: Sizes.gap28[device],
    outline: 'none',
    boxShadow: 'none',
    border: `1px solid ${colors['grey-medium']}`,
    cursor: props.disabled ? 'default' : 'pointer',
    background: 'none'
  }
  const componentProps = {
    style: { ...style, ...props.style },
    // className: styles['showMoreButton'],
    disabled: props.disabled,
    onClick: props.onClick,
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false)
  }

  return (
    <button {...componentProps}>
      <Text
        variant='larger'
        color={getTextColor()}
      >
        Показать еще
      </Text>
    </button>
  )
}

export default ShowMoreButton
