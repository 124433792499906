import React from 'react'
import styles from './Grid30p70pAnd30p30p30p.module.css'

type Grid30p70pAnd30p30p30plProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Grid30p70pAnd30p30p30p = (props: Grid30p70pAnd30p30p30plProps) => {
  const style: React.CSSProperties = {
  }

  const componentProps = {
    style: { ...style, ...props.style },
    className: styles['Grid30p70pAnd30p30p30p'],
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    onClick: props.onClick
  }

  return (
    <div {...componentProps}>{props.children}</div>
  )
}

export default Grid30p70pAnd30p30p30p
