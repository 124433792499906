import React from 'react'
import Image from 'components/v1/Image'

type LogoProps = {
  variant: 'ranepa' | 'firo' | 'firo-with-text';
  width: React.CSSProperties['width'];
}

const Logo = (props: LogoProps) => {
  const src = `/static/img/logos/${props.variant}.svg`
  return (
    <Image
      src={src}
      width={props.width}
    />
  )
}

export default Logo
