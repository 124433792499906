import React from 'react'
import useDevice from 'common/useDevice'
import getResponsiveProp from 'common/getResponsiveProp'

type StackOrientation = 'horizontal' | 'vertical'

type StackProps = {
  id?: number | string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;

  gap?: React.CSSProperties['gap'];
  smallScreenGap?: React.CSSProperties['gap'];
  tabletGap?: React.CSSProperties['gap'];
  phoneGap?: React.CSSProperties['gap'];

  alignItems?: React.CSSProperties['alignItems'];
  smallScreenAlignItems?: React.CSSProperties['alignItems'];
  tabletAlignItems?: React.CSSProperties['alignItems'];
  phoneAlignItems?: React.CSSProperties['alignItems']

  justifyContent?: React.CSSProperties['justifyContent'];
  smallScreenJustifyContent?: React.CSSProperties['justifyContent'];
  tabletJustifyContent?: React.CSSProperties['justifyContent'];
  phoneJustifyContent?: React.CSSProperties['justifyContent'];

  height?: React.CSSProperties['height'];
  smallScreenHeight?: React.CSSProperties['height'];
  tabletHeight?: React.CSSProperties['height'];
  phoneHeight?: React.CSSProperties['height'];

  width?: React.CSSProperties['width'];
  smallScreenWidth?: React.CSSProperties['width'];
  tabletWidth?: React.CSSProperties['width'];
  phoneWidth?: React.CSSProperties['width'];

  reverse?: boolean;
  smallScreenReverse?: boolean;
  tabletReverse?: boolean;
  phoneReverse?: boolean;
  flexGrow?: React.CSSProperties['flexGrow'];

  orientation?: StackOrientation;
  smallScreenOrientation?: StackOrientation;
  tabletOrientation?: StackOrientation;
  phoneOrientation?: StackOrientation;

  wrap?: React.CSSProperties['flexWrap'];
  ref?: any;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Stack = (props: StackProps) => {
  const device = useDevice()

  const reverse = getResponsiveProp(device, props, 'reverse')
  const orientation = getResponsiveProp(device, props, 'orientation')
  const flexDirection = (orientation === 'horizontal' ? 'row' : 'column') ?? 'column' + (reverse ? '-reverse' : '')

  const style: React.CSSProperties = {
    display: 'flex',
    gap: getResponsiveProp(device, props, 'gap'),
    alignItems: getResponsiveProp(device, props, 'alignItems'),
    justifyContent: getResponsiveProp(device, props, 'justifyContent'),
    flexGrow: props.flexGrow,
    height: getResponsiveProp(device, props, 'height'),
    width: getResponsiveProp(device, props, 'width'),
    flexDirection: flexDirection,
    flexWrap: props.wrap
  }

  const componentProps: any = {
    style: {...style, ...props.style},
    className: props.className,
    id: props.id,
    ref: props.ref,
    onClick: props.onClick
  }

  return (
    <div {...componentProps}>{props.children}</div>
  )
}

export default Stack
export type { StackOrientation }
