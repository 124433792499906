import nbspacer from 'common/nbspacer'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import BannerCircleImg from 'components/v1/BannerCircleImg'
import Icon, { IconVariant } from 'components/v1/Icon'
import PageSection from 'components/v1/PageSection'
import Stack, { StackOrientation } from 'components/v1/Stack'
import BasePage from 'pages/BasePage'
import React from 'react'
import Text from 'components/v1/Text'
import Image from 'components/v1/Image'
import Card from 'components/v1/Card'
import Heading from 'components/v1/Heading'
import Grid from 'components/v1/Grid'
import CardIconWithText from 'components/v1/CardIconWithText'
import LinkWithArrow from 'components/v1/LinkWithArrow'

const ChildrenSelfRealizationPage = () => {
  const device = useDevice()

  const orientation = {
    desktop: 'horizontal',
    smallScreen: 'horizontal',
    tablet: 'vertical',
    phone: 'vertical'
  }

  const imageSizes = {
    desktop: 300,
    smallScreen: 280,
    tablet: 200,
    phone: 180
  }

  const items1: { icon: IconVariant, text: string }[] = [
    { icon: 'page-with-puzzle', text: 'Диагностические методики' },
    { icon: 'book-with-cog', text: 'Методические рекомендации' },
    { icon: 'scroll-with-diagram', text: 'Технологические карты' },
    { icon: 'book-stack', text: 'Пособия' }
  ]
  const items2: { icon: IconVariant, text: string }[] = [
    { icon: 'computer', text: 'Пакет презентационных материалов, отражающих внедрение и тиражирование вариативных моделей с детьми 5-11 классов, в том числе видеоролик' },
    { icon: 'notebook', text: 'Информационные буклеты' }
  ]

  return (
    <BasePage>
      <Stack
        gap={Sizes.gap80[device]}
      >
        <PageSection>
          <BannerCircleImg
            breadcrumbs={[
              { name: 'Проекты', url: null },
              { name: 'Самореализация детей, находящихся в трудной жизненной ситуации', url: null }
            ]}
            heading='Самореализация детей, находящихся в трудной жизненной ситуации'
            imageUrl='/static/img/pages/projects/children-self-realization/image1.jpg'
          />
        </PageSection>

        <PageSection>
          <Stack
            orientation={Sizes.orientation[device] as StackOrientation}
            gap={Sizes.gap80[device]}
            width={Sizes.width90[device]}
            alignItems='flex-start'
          >
            <Stack
              gap={16}
            >
              <Text variant='larger'>{nbspacer('Самореализация детей, находящихся в трудной жизненной ситуации —')}</Text>
              <Text variant='large'>{nbspacer('это информационно-дидактический ресурс (далее - ИДР Самореализация) разработанный Федеральным институтом развития образования РАНХиГС в 2019-2020 г.г. ')}</Text>
              <Text>{nbspacer('(Государственный контракт № 06.S05.12.0029 по проекту от 15.11.2019 года по заданию Министерства просвещения Российской Федерации в рамках реализации Федерального проекта «Успех каждого ребенка» направления (подпрограммы) «Развитие дополнительного образования детей и реализация мероприятий молодежной политики» государственной программы Российской Федерации «Развитие образования»).')}</Text>
            </Stack>

            <Icon
              variant='laptop'
              size={150}
              flexShrink={0}
              color='green-medium'
            />
          </Stack>
        </PageSection>

        <PageSection>
          <Stack
            orientation={orientation[device] as StackOrientation}
            gap={Sizes.gap32[device]}
            alignItems='center'
          >
            <Image
              src='/static/img/pages/projects/children-self-realization/image2.jpg'
              height={imageSizes[device]}
              width={imageSizes[device]}
              objectFit='cover'
              flexShrink={0}
              borderRadius='50%'
            />
            <Card
              bgColor='green-lighter'
            >
              <Stack
                gap={Sizes.gap30[device]}
              >
                <Heading variant='h3'>
                  {nbspacer('ИДР Самореализация —')}
                </Heading>
                <Text variant='large'>{nbspacer('это модели работы с детьми 5-11 классов, находящимися в трудной жизненной ситуации, позволяющих осуществлять анализ их индивидуальных потребностей и построение на основе результатов анализа для них образовательных траекторий в сфере дополнительного образования с возможностью их интеграции с индивидуальным учебным планом освоения основных общеобразовательных программ, направленных на их успешную социальную самореализацию и профессиональное самоопределение.')}</Text>
              </Stack>
            </Card>
          </Stack>
        </PageSection>

        <PageSection>
          <Card
            borderColor='green-lighter'
            borderWidth={1}
          >
            <Stack
              gap={Sizes.gap30[device]}
            >
              <Heading
                variant='h2'
                color='green-medium'
              >
                {nbspacer('ИДР Самореализация – это комплект материалов:')}
              </Heading>
              <Grid
                cols={Sizes.cols2[device]}
                columnGap={Sizes.gap32[device]}
                rowGap={Sizes.gap30[device]}
              >
                <Stack
                  gap={Sizes.gap30[device]}
                >
                  {items1.map((item, index) => (
                    <Stack
                      key={index}
                      gap={Sizes.gap20[device]}
                      orientation='horizontal'
                      alignItems='flex-start'
                    >
                      <Icon
                        variant={item.icon as IconVariant}
                        color='black-dark'
                        flexShrink={0}
                        size={30}
                      />
                      <Text variant='large'>{nbspacer(item.text)}</Text>
                    </Stack>
                  ))}
                </Stack>
                <Stack
                  gap={Sizes.gap30[device]}
                >
                  {items2.map((item, index) => (
                    <Stack
                      key={index}
                      gap={Sizes.gap20[device]}
                      orientation='horizontal'
                      alignItems='flex-start'
                    >
                      <Icon
                        variant={item.icon as IconVariant}
                        color='black-dark'
                        flexShrink={0}
                        size={30}
                      />
                      <Text variant='large'>{nbspacer(item.text)}</Text>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            </Stack>
          </Card>
        </PageSection>

        <PageSection>
          <Stack
            gap={Sizes.gap30[device]}
          >
            <Stack
              orientation={orientation[device] as StackOrientation}
              gap={Sizes.gap32[device]}
              alignItems='flex-start'
            >
              <Stack
                gap={Sizes.gap30[device]}
              >
                <Heading variant='h2'>
                  {nbspacer('ИДР Самореализация предназначен')}
                </Heading>
                <Text variant='large'>{nbspacer('для широкого внедрении моделей самореализации с детьми 5-11 классов, находящимися в трудной жизненной ситуации в образовательные организации, реализующие программы дополнительного образования, с возможностью их интеграции с индивидуальным учебным планом освоения основных общеобразовательных программ, обеспечивающих социальную самореализацию и профессиональное самоопределение детей, находящихся в трудной жизненной ситуации.')}</Text>
              </Stack>
              <Card
                bgColor='green-medium'
                minWidth={Sizes.width33[device]}
                flexShrink={0}
              >
                <Stack
                  alignItems='center'
                >
                  <Image
                    src='/static/img/pages/projects/children-self-realization/image3.jpg'
                    height={imageSizes[device]}
                    width={imageSizes[device]}
                    objectFit='cover'
                    flexShrink={0}
                    borderRadius='50%'
                  />
                </Stack>
              </Card>
            </Stack>
            <Stack
              orientation={orientation[device] as StackOrientation}
              gap={Sizes.gap32[device]}
              alignItems='flex-start'
            >
              <CardIconWithText
                icon='people-with-clock'
                text='ИДР Самореализация – это научно-методическая поддержка педагогов, ориентированных на работу с детьми, находящимися в трудной жизненной ситуации, в системе повышения квалификации работников образования.'
                bgColor='green-lighter'
              />

              <Image
                src='/static/img/pages/projects/children-self-realization/image4.jpg'
                height={370}
                width={Sizes.width33[device]}
                objectFit='cover'
                flexShrink={0}
                borderRadius='30px'
              />
            </Stack>
          </Stack>
        </PageSection>

        <PageSection>
          <LinkWithArrow
            to=''
            label='Скачать все разработки'
          />
        </PageSection>
      </Stack>
    </BasePage>
  )
}

export default ChildrenSelfRealizationPage
