import React from 'react'
import styles from './ListItem.module.css'
import Text, { TextVariant } from 'components/v1/Text'
import nbspacer from 'common/nbspacer'
import Color from 'common/Color.d'
import colors from 'common/colors'

type ListItemProps = {
  style?: React.CSSProperties;
  text: string;
  textVariant?: TextVariant;
  textColor?: Color;
  bulletColor?: Color;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ListItem = (props: ListItemProps) => {
  const style: React.CSSProperties = {
  }

  const componentProps = {
    style: { ...style, ...props.style },
    className: styles['ListItem'],
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    onClick: props.onClick
  }

  return (
    <div {...componentProps}>
      <div
        className={styles['ListItem__bullet']}
        style={{ backgroundColor: colors[props.bulletColor ?? 'green-medium'] }}
      />
      <Text
        flexGrow={1}
        variant={props.textVariant}
        color={props.textColor}
      >{nbspacer(props.text)}</Text>
    </div>
  )
}

export default ListItem
