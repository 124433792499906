import React from 'react'
import Image from 'components/v1/Image'
import useDevice from 'common/useDevice'
import getResponsiveProp from 'common/getResponsiveProp'
import Color from 'common/Color.d'
// import colors from 'common/colors'
import useHover from 'common/useHover'

type SvgIconVariant =
  'letter-a' |
  'book' |
  'book-curved' |
  'scroll-with-feather' |
  'book-with-bookmark' |
  'book-with-bookmark-and-cog' |
  'science-hat' |
  'page-with-check' |
  'people' |
  'tg-white'

type SvgIconProps = {
  variant: SvgIconVariant;
  style?: React.CSSProperties;
  className?: string;

  color?: Color;
  hoverColor?: Color;

  cursor?: React.CSSProperties['cursor'];

  size?: React.CSSProperties['width'];
  smallScreenSize?: React.CSSProperties['width'];
  tabletSize?: React.CSSProperties['width'];
  phoneSize?: React.CSSProperties['width'];

  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const SvgIcon = (props: SvgIconProps) => {
  const device = useDevice()

  const hover = useHover({
    // stroke: colors[props.hoverColor ?? 'black-dark']
  })

  const handleClick = (e: any) => {
    props.onClick?.(e)
  }

  const size = getResponsiveProp(device, props, 'size')

  const style: React.CSSProperties = {
    cursor: props.cursor,
    height: size,
    width: size
  }

  const src = `/static/img/svg-icons/${props.variant}.svg`

  const componentProps = {
    src: src,
    style: { ...style, ...props.style, ...hover.style },
    className: props.className,
    onClick: handleClick,
    onMouseEnter: hover.onMouseEnter,
    onMouseLeave: hover.onMouseLeave
  }

  return <Image {...componentProps} />
}

export default SvgIcon
export type { SvgIconVariant }
