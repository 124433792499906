import React from 'react'
import styles from './Horizontal50pct50pct.module.css'

type Horizontal50pct50pctProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Horizontal50pct50pct = (props: Horizontal50pct50pctProps) => {
  const style: React.CSSProperties = {
  }

  const componentProps = {
    style: { ...style, ...props.style },
    className: styles['Horizontal50pct50pct'],
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    onClick: props.onClick
  }

  return (
    <div {...componentProps}>{props.children}</div>
  )
}

export default Horizontal50pct50pct
