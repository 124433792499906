import colors from 'common/colors'
import nbspacer from 'common/nbspacer'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Breadcrumbs from 'components/v1/Breadcrumbs'
import Heading from 'components/v1/Heading'
import PageSection from 'components/v1/PageSection'
import Image from 'components/v1/Image'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'
import Stack from 'components/v1/Stack'
import Card from 'components/v1/Card'
import Layout from 'components/v1/Layout/Layout'
import ExpandableCard from 'components/v1/ExpandableCard/ExpandableCard'
import ListItem from 'components/v1/ListItem/ListItem'
import Icon from 'components/v1/Icon'
import Grid from 'components/v1/Grid'
import ButtonLink from 'components/v1/ButtonLink/ButtonLink'

const DnaOfRussia = () => {
  const device = useDevice()

  const maxWidths = {
    desktop: '80%',
    smallScreen: '50%',
    tablet: '100%',
    phone: '100%'
  }

  const maxWidths2 = {
    desktop: '90%',
    smallScreen: '90%',
    tablet: '100%',
    phone: '100%'
  }

  const image1Sizes = {
    desktop: 300,
    smallScreen: 250,
    tablet: 200,
    phone: 150
  }

  const imageSizes = {
    desktop: 200,
    smallScreen: 190,
    tablet: 150,
    phone: 100
  }

  // const planItems1 = [
  //   {
  //     title: '15.08.2022 -16.09.2022',
  //     items: [
  //       'Старт проекта «Основы и принципы Российской государственности».',
  //       'Старт разработки УМК.',
  //       'Гранты ЭИСИ.',
  //       'Установочный семинар с ВУЗами — победителями грантов.'
  //     ]
  //   },
  //   {
  //     title: '25.10.2022 - 31.10.2022',
  //     items: [
  //       'Научно-просветительская конференция «ДНК России» (г. Сочи).',
  //       'Разработка содержания УМК.'
  //     ]
  //   },
  //   {
  //     title: '01.11.2022 -30.12.2022',
  //     items: [
  //       'Преподавательские конференции исполнителей грантов ЭИСИ.'
  //     ]
  //   },
  //   {
  //     title: 'февраль 2023',
  //     items: [
  //       'Завершение разработки УМК, предоставление УМК на рассмотрение комиссии Госсовета РФ по науке.'
  //     ]
  //   },
  //   {
  //     title: 'март 2023',
  //     items: [
  //       'Определение тем грантов ЭИСИ для запуска преподавания УМК.',
  //       'Рабочие конференции проекта.',
  //       'Гранты ЭМСИ.'
  //     ]
  //   },
  //   {
  //     title: 'апрель-май 2023',
  //     items: [
  //       'Всероссийская конференция проекта.',
  //       'Публичная презентация УМК.',
  //       'Определение 20 ВУЗов — центров компетенций.',
  //       'Концепция учебного пособия.'
  //     ]
  //   }
  // ]

  // const planItems2 = [
  //   {
  //     title: '16.08.2022',
  //     items: [
  //       'Заседание экпертного совета комиссии Госсовета по науке.',
  //       'Выдача рекомендаций МОН РФ.'
  //     ]
  //   },
  //   {
  //     title: '22.09.2022-01.10.2022',
  //     items: [
  //       'Госзадание на разработку УМК.',
  //       'Формирование авторской группы УМК.',
  //       'Тестирование учебно-методических материалов.'
  //     ]
  //   },
  //   {
  //     title: '15.11.2022',
  //     items: [
  //       'Второе заседание экспертного совета комиссии Госсовета РФ по науке.',
  //       'Заседание по разработке методики оценки ФГОС и УМК.'
  //     ]
  //   },
  //   {
  //     title: 'февраль 2023',
  //     items: [
  //       'Третье заседание экспертного совета.',
  //       'Подведение итогов конференций.',
  //       'Рассмотрение и принятие методики оценки ФГОС и УМК.'
  //     ]
  //   },
  //   {
  //     title: 'февраль-март 2023',
  //     items: [
  //       'Издание приказа МОН о пересмотре ФГОС и УМК по общественным дисциплинам.',
  //       'Утверждение ВУЗов — центров компетенций (март).'
  //     ]
  //   }
  // ]

  // const planItems3 = [
  //   {
  //     title: '15.09.2022',
  //     items: [
  //       'Создание центра подготовки кадров на основе МИФИ, МГУ.',
  //       'Разработка учебно-методических материалов (20 фильмов-лекций).'
  //     ]
  //   },
  //   {
  //     title: '26.09.2022-01.10.2022',
  //     items: [
  //       'Тестовый курс для руководства и ППС ВУЗов по программе подготовки преподавателей.'
  //     ]
  //   },
  //   {
  //     title: 'октябрь — декабрь 2022',
  //     items: [
  //       'Развертывание программы подготовки преподавателей (600 слушателей).'
  //     ]
  //   },
  //   {
  //     title: 'январь 2023',
  //     items: [
  //       'Создание федеральных центров подготовки преподавателей (10+).',
  //       'Разработка учебно-методических материалов.'
  //     ]
  //   },
  //   {
  //     title: 'март-август 2023',
  //     items: [
  //       'Реализация программы подготовки преподавателей (6000 слушателей).',
  //       'Разработка учебно-методических материалов (100 фильмов-лекций).'
  //     ]
  //   }
  // ]

  // const philosophicalFoundationsItems = [
  //   {
  //     name: 'Коммунитаризм',
  //     description: 'Согласованность и согласие граждан, равноценность и собственность как общие ориентиры.'
  //   },
  //   {
  //     name: 'Сергей Гесен',
  //     description: 'Личность — движение к сверхличным, объективным началам жизни, Равенство —равноценность незаменимых в своем Различии личностей и Собственность — служение Общности.'
  //   },
  //   {
  //     name: 'Консерватизм',
  //     description: 'Логичное и исторически обусловленное развитие собственных ценностей и нравственных сил.'
  //   },
  //   {
  //     name: 'Николай Страхов',
  //     description: 'Все дело в том и состоит, чтобы ничего не переносить, ничему не подражать, а всеразвивать из того, что есть, и не разрушая того, что есть.'
  //   },
  //   {
  //     name: 'Русский космизм',
  //     description: 'Всеединство и прорыв в новую технологическую и социальную реальность.'
  //   },
  //   {
  //     name: 'Владимир Соловьв',
  //     description: 'Истинная будущность человечества, над которой нам надлежит потрудиться, есть вселенское братство, исходящее из вселенского отчества через непрестанное моральное и социальное сыновство.'
  //   },
  //   {
  //     name: 'Солидаризм',
  //     description: 'Самореализация человека через братство со своими соотечественниками и служение общему сверхличностному идеалу.'
  //   },
  //   {
  //     name: 'Алексей Хомяков',
  //     description: 'Надобно, чтобы жизнь каждого была в полном согласии с жизнью всех, чтобы не было раздвоения ни в лицах, ни в обществе. Частное мышление может быть сильно и плодотворно только при сильном развитии мышления общего.'
  //   }
  // ]

  return (
    <BasePage>
      <Layout
        variant='page-sections'
      >
        <PageSection>
          <div
            style={{
              minHeight: 360,
              width: '100%',
              backgroundImage: `url('/static/img/pages/dna-of-russia/v3/image1.svg')`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '30% 100%',
              backgroundColor: colors['blue-light'],
              borderRadius: '30px',
              padding: 30,
              boxSizing: 'border-box'
            }}
          >
            <Stack
              justifyContent='space-between'
              height='100%'
              gap={Sizes.gap30[device]}
            >
              <Stack
                gap={Sizes.gap30[device]}
                justifyContent='space-between'
                height='100%'
              >
                <Breadcrumbs
                  color='grey-medium'
                  items={[
                    { name: 'Главная', url: '/' },
                    { name: 'Проект “ДНК России”', url: null }
                  ]}
                />
                <Text
                  variant='large'
                  style={{
                    maxWidth: maxWidths[device]
                  }}
                >{nbspacer('1 апреля 2023 года на базе ФИРО РАНХиГС создан проектный офис проекта «ДНК России»')}</Text>
              </Stack>
              <Heading
                variant='h2'
                color='green-medium'
                style={{
                  maxWidth: maxWidths2[device]
                }}
              >
                {nbspacer('Центр изучения социо-гуманитарных вопросов развития человеческого капитала (ДНК РОССИИ)')}
              </Heading>
            </Stack>
          </div>
        </PageSection>

        <PageSection>
          <Layout
            variant='horizontal-30pct-70pct'
          >
            <Stack
              alignItems='center'
              justifyContent='center'
            >
              <Image
                src='/static/img/pages/dna-of-russia/v3/image2.svg'
                width={image1Sizes[device]}
              />
            </Stack>
            <Stack
              height='100%'
              justifyContent='center'
            >
              <Text variant='large'>
                {nbspacer('Проект «ДНК России» направлен на вовлечение академического сообщества в учебно-методическое и научно-исследовательское сопровождение государственной политики в области просвещения, высшего образования и молодежной политики.')}
              </Text>
            </Stack>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Card
              bgColor='blue-light'
            >
              <Stack
                orientation='horizontal'
                phoneOrientation='vertical'
                gap={Sizes.gap80[device]}
                alignItems='center'
              >
                <Image
                  height={imageSizes[device]}
                  width={imageSizes[device]}
                  borderRadius='50%'
                  objectFit='cover'
                  flexShrink={0}
                  src='/static/img/pages/dna-of-russia/v3/image3.jpg'
                />
                <Stack gap={20}>
                  <Heading variant='h3'>{nbspacer('Научный руководитель проекта «ДНК России»')}</Heading>
                  <Stack gap={4}>
                    <Text variant='larger'>{nbspacer('Полосин Андрей Владимирович')}</Text>
                    <Text variant='large'>{nbspacer('кандидат психологических наук, доктор политических наук')}</Text>
                  </Stack>
                </Stack>
              </Stack>
            </Card>

            <Layout variant='horizontal-50pct-50pct'>
              <Card
                bgColor='green-medium'
              >
                <Stack
                  gap={Sizes.gap60[device]}
                >
                  <Heading variant='h3' color='white'>{nbspacer('Цель проекта —')}</Heading>
                  <Text variant='large' color='white'>{nbspacer('глубокое и открытое вовлечение академического сообщества Российской Федерации для учебно-методической, научно-исследовательской профессиональной дискуссии о российском мировоззрении, изменении государственной политики в области просвещения, высшего образования и молодежной')}</Text>
                </Stack>
              </Card>
              <Card
                bgColor='blue-light'
              >
                <Stack gap={20}>
                  <Heading variant='h3'>{nbspacer('Принципы проекта')}</Heading>
                  <Stack gap={10}>
                    <ListItem text='Исследование проблемы, разработка содержания и апробация решения производятся в рамках единого процесса, позволяя более чем в три раза сократить сроки' />
                    <ListItem text='Решение задачи разрабатывается в сотрудничестве более 2 000 представителей экспертного сообщества' />
                    <ListItem text='Обеспечивается взаимодействие с существующими экспертными институтами и объединениями  экспертов общественных наук' />
                  </Stack>
                </Stack>
              </Card>
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <ExpandableCard
              title='История проекта'
            >
              <Stack gap={Sizes.gap60[device]}>
                <Stack gap={20}>
                  <Text
                    variant='large'
                  >
                    Предпосылки:
                  </Text>
                  <Grid
                    cols={Sizes.cols4[device]}
                    columnGap={Sizes.gap60[device]}
                    rowGap={Sizes.gap60[device]}
                  >
                    <Stack gap={10}>
                      <Text variant='larger' color='green-medium'>2020 г.</Text>
                      <Text>{nbspacer('Утверждены национальные цели развития страны (Указ Президента РФ от 21 июля 2020 г. № 474 “О национальных целях развития Российской Федерации на период до 2030 года”)')}</Text>
                    </Stack>
                    <Stack gap={10}>
                      <Text variant='larger' color='green-medium'>2021 г.</Text>
                      <Text>{nbspacer('Определены стратегические национальные приоритеты (Указ Президента РФ от 02.07.2021 N 400 "О Стратегии национальной безопасности Российской Федерации")')}</Text>
                    </Stack>
                    <Stack gap={10}>
                      <Text variant='larger' color='green-medium'>2023 г.</Text>
                      <Text>{nbspacer('Внесены изменения в Основы государственной культурной политики (Указ Президента РФ от 25 января 2023 г. № 35 "О внесении изменений в Основы государственной культурной политики, утвержденные Указом Президента Российской Федерации от 24 декабря 2014 г. N 808")')}</Text>
                    </Stack>
                    <Stack gap={10}>
                      <Text variant='larger' color='green-medium'>2023 г.</Text>
                      <Text>{nbspacer('Поручение Президента РФ по итогам заседания Государственного Совета Российской Федерации, состоявшегося 22 декабря 2022 года Минобрнауки России: разработать и включить в образовательные программы высшего образования курс (модуль) «Основы российской государственности». Срок – 1 августа 2023 г.')}</Text>
                    </Stack>
                  </Grid>
                </Stack>
                <Stack gap={20}>
                  <Text
                    variant='large'
                  >
                    Результаты:
                  </Text>
                  <Grid
                    cols={Sizes.cols4[device]}
                    columnGap={Sizes.gap60[device]}
                    rowGap={Sizes.gap60[device]}
                  >
                    <Stack gap={12}>
                      <Icon color='green-medium' size={78} variant='people' />
                      <Heading variant='h3' color='green-medium'>&gt;2 000</Heading>
                      <Text variant='large'>{nbspacer('ППС вовлечено в проект «ДНК России»')}</Text>
                    </Stack>
                    <Stack gap={12}>
                      <Icon color='green-medium' size={78} variant='hat' />
                      <Heading variant='h3' color='green-medium'>10</Heading>
                      <Text variant='large'>{nbspacer('ВУЗов сформировали ядро реализации ДПО')}</Text>
                    </Stack>
                    <Stack gap={12}>
                      <Icon color='green-medium' size={78} variant='scientists' />
                      <Heading variant='h3' color='green-medium'>&gt;1 000</Heading>
                      <Text variant='large'>{nbspacer('преподавателей прошли переподготовку в рамках мультипредметного ДПО')}</Text>
                    </Stack>
                    <Stack gap={36}>
                      <Icon color='green-medium' size={78} variant='scroll-with-feather' />
                      <Text variant='large'>{nbspacer('Разработана концепция курса «Основы российской государственности»')}</Text>
                    </Stack>
                    <Stack gap={36}>
                      <Icon color='green-medium' size={78} variant='speaking-person' />
                      <Text variant='large'>{nbspacer('Большая конференция для более 250 преподавателей и ученых из 84 ВУЗов РФ')}</Text>
                    </Stack>
                    <Stack gap={12}>
                      <Icon color='green-medium' size={78} variant='chat' />
                      <Heading variant='h3' color='green-medium'>&gt;200</Heading>
                      <Text variant='large'>{nbspacer('конференций и семинаров')}</Text>
                    </Stack>
                    <Stack gap={12}>
                      <Icon color='green-medium' size={78} variant='certificate' />
                      <Heading variant='h3' color='green-medium'>26</Heading>
                      <Text variant='large'>{nbspacer('реализованных грантов')}</Text>
                    </Stack>
                    <Stack gap={12}>
                      <Icon color='green-medium' size={78} variant='goal' />
                      <Heading variant='h3' color='green-medium'>6000</Heading>
                      <Text variant='large'>{nbspacer('преподавателей общественных наук — целевой показатель к 2024 году')}</Text>
                    </Stack>
                  </Grid>
                </Stack>
              </Stack>
            </ExpandableCard>

            <ExpandableCard
              title='Этапы проекта'
            >
              <Image
                width='100%'
                src='/static/img/pages/dna-of-russia/v3/image4.svg'
              />
            </ExpandableCard>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Card
              bgColor='green-medium'
            >
              <Text
                variant='larger'
                color='white'
              >
                {nbspacer('Разработка и включение в образовательные программы высшего образования курса «Основы российской государственности» включает 3 этапа:')}
              </Text>
            </Card>

            <Grid
              cols={Sizes.cols3[device]}
              columnGap={Sizes.gap32[device]}
              rowGap={Sizes.gap30[device]}
            >
              <Card bgColor='blue-light'>
                <Stack justifyContent='space-between' height='100%' gap={20}>
                  <Stack gap={Sizes.gap40[device]}>
                    <Stack gap={12}>
                      <Text variant='large'>1 этап</Text>
                      <Text variant='larger'>{nbspacer('Реализация программы ДПП «Технология организации преподавания основ российской государственности»')}</Text>
                    </Stack>
                    <Stack gap={20}>
                      <Stack orientation='horizontal' gap={10}>
                        <Icon variant='clock' size={24} />
                        <Text>72 ак.ч.</Text>
                      </Stack>
                      <Stack gap={12}>
                        <Text variant='large' color='green-medium'>{nbspacer('Очно-дистанционный формат обучения сотрудников НМИЦ')}</Text>
                        <Text variant='large'>{nbspacer('с 17 апреля по 8 мая 2023 г.')}</Text>
                      </Stack>
                      <Stack gap={12}>
                        <Text variant='large' color='green-medium'>{nbspacer('Очный модуль организуется на базе проектного офиса, г. Москва')}</Text>
                        <Text variant='large'>{nbspacer('с 17 по 20 апреля 2023 г.')}</Text>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>

              <Card bgColor='blue-light'>
                <Stack gap={Sizes.gap40[device]}>
                  <Stack gap={12}>
                    <Text variant='large'>2 этап</Text>
                    <Text variant='larger'>{nbspacer('Реализация программы ДПП «Методика преподавания основ российской государственности»')}</Text>
                  </Stack>

                  <Stack gap={20}>
                    <Stack orientation='horizontal' gap={10}>
                      <Icon variant='clock' size={24} />
                      <Text>72 ак.ч.</Text>
                    </Stack>
                    <Text variant='large' color='green-medium'>{nbspacer('Очно-дистанционный формат обучения сотрудников профессорско-преподавательского состава ВУЗов.')}</Text>
                    <Text variant='large' color='green-medium'>{nbspacer('В форме сетевого обучения Проектный офис-НМИЦ.')}</Text>
                    <Text variant='large' color='green-medium'>{nbspacer('Очный модуль организуется на базе НМИЦ в соответствии с графиком.')}</Text>
                  </Stack>
                  <ButtonLink to='https://my-cdto.gspm.ranepa.ru/registration/commerce'>
                    <Text
                      color='white'
                      variant='large'
                    >Регистрация на программу</Text>
                  </ButtonLink>
                </Stack>
              </Card>

              <Card bgColor='blue-light'>
                <Stack justifyContent='space-between' height='100%' gap={20}>
                  <Stack gap={Sizes.gap40[device]}>
                    <Stack gap={12}>
                      <Text variant='large'>3 этап</Text>
                      <Text variant='larger'>{nbspacer('Разработка и реализация программы ДПП «Особенности преподавания основ российской государственности»')}</Text>
                    </Stack>
                    <Stack gap={20}>
                      <Stack orientation='horizontal' gap={10}>
                        <Icon variant='clock' size={24} />
                        <Text>36 ак.ч.</Text>
                      </Stack>
                      <Stack gap={12}>
                        <Text variant='large' color='green-medium'>{nbspacer('Дистанционный формат обучения. Организует Проектный офис')}</Text>
                        {false && <Text variant='large'>{nbspacer('с 1 июля по 25 августа 2023 г.')}</Text>}
                      </Stack>
                    </Stack>
                  </Stack>
                  {false && <ButtonLink to=''>
                    <Text
                      color='white'
                      variant='large'
                    >Регистрация на программу</Text>
                  </ButtonLink>}
                </Stack>
              </Card>
            </Grid>
          </Layout>
        </PageSection>

      </Layout>
    </BasePage>
  )
}

export default DnaOfRussia
