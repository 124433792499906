import React from 'react'
import styles from './Horizontal30pct70pct.module.css'

type Horizontal30pct70pctProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Horizontal30pct70pct = (props: Horizontal30pct70pctProps) => {
  const style: React.CSSProperties = {
  }

  const componentProps = {
    style: { ...style, ...props.style },
    className: styles['Horizontal30pct70pct'],
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    onClick: props.onClick
  }

  return (
    <div {...componentProps}>{props.children}</div>
  )
}

export default Horizontal30pct70pct
