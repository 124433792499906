import React from 'react'
import styles from './ButtonLink.module.css'

type ButtonLinkProps = {
  children?: React.ReactNode;
  to: string;
}

const ButtonLink = (props: ButtonLinkProps) => {
  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      href={props.to}
      className={styles.ButtonLink}
    >{props.children}</a>
  )
}

export default ButtonLink
