import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Breadcrumbs from 'components/v1/Breadcrumbs'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import Image from 'components/v1/Image'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import Partner from 'models/Partner.d'
import BasePage from 'pages/BasePage'
import React from 'react'

const PartnersPage = () => {
  const device = useDevice()
  const [items, setItems] = React.useState<Partner[]>([])

  React.useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchItems = async () => {
    const url = `/api/partners/`
    let params: any = {
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setItems(data)
    }
  }

  return (
    <BasePage>
      <PageSection>
        <Stack gap={Sizes.gap20[device]}>
          <Breadcrumbs
            items={[
              { name: 'Главная', url: '/' },
              { name: 'Партнеры', url: null }
            ]}
            color='grey-medium'
          />
          <Stack
            gap={Sizes.gap40[device]}
          >
            <Heading variant='h1'>Партнеры</Heading>
            <Grid
              cols={Sizes.cols4[device]}
              columnGap={Sizes.gap80[device]}
              rowGap={Sizes.gap30[device]}
            >
              {items.map((item) => (
                <Stack
                  key={item.id}
                  gap={10}
                  alignItems='center'
                >
                  <Image
                    src={item.image_url}
                    style={{
                      maxHeight: '120px',
                      maxWidth: '300px'
                    }}
                  />
                  <Text
                    variant='small'
                    textAlign='center'
                  >
                    {item.description}
                  </Text>
                </Stack>
              ))}
            </Grid>
          </Stack>
        </Stack>
      </PageSection>
    </BasePage>
  )
}

export default PartnersPage
