import React from 'react'
import Heading from 'components/v1/Heading'
import Stack, { StackOrientation } from 'components/v1/Stack'
import Breadcrumbs, { Breadcrumb } from 'components/v1/Breadcrumbs'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Image from 'components/v1/Image'
import colors from 'common/colors'
import nbspacer from 'common/nbspacer'

type BannerCircleImgProps = {
  heading?: string;
  imageUrl?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  breadcrumbs?: Breadcrumb[];
}

const BannerCircleImg = (props: BannerCircleImgProps) => {
  const device = useDevice()

  const imageSizes = {
    desktop: 320,
    smallScreen: 280,
    tablet: 200,
    phone: 160
  }

  const leftWidth = {
    desktop: '50%',
    smallScreen: '100%',
    tablet: '100%',
    phone: '100%'
  }

  const orientation = {
    desktop: 'horizontal',
    smallScreen: 'vertical',
    tablet: 'vertical',
    phone: 'vertical'
  }

  const style: React.CSSProperties = {
    minHeight: '400px',
    borderRadius: '30px',
    width: '100%',
    boxSizing: 'border-box',
    padding: Sizes.gap30[device],
    backgroundColor: colors['green-lighter']
  }

  const componentProps = {
    style: { ...style, ...props.style },
    className: props.className,
    onClick: props.onClick
  }

  return (
    <div {...componentProps}>
      <Stack
        orientation={orientation[device] as StackOrientation}
        gap={Sizes.gap30[device]}
        alignItems='center'
        style={{
          flexWrap: 'wrap'
        }}
      >
        <Stack
          flexGrow={1}
          gap={Sizes.gap30[device]}
          justifyContent={props.breadcrumbs ? 'space-between' : 'flex-end'}
          height='100%'
          width={leftWidth[device]}
          style={{
            boxSizing: 'border-box',
            minHeight: '340px'
          }}
        >
          {props.breadcrumbs &&
            <Breadcrumbs
              items={props.breadcrumbs}
              color='white'
            />
          }
          <Heading
            variant='h1'
          >
            {nbspacer(props.heading ?? '')}
          </Heading>
        </Stack>
        <Image
          width={imageSizes[device]}
          height={imageSizes[device]}
          objectFit='cover'
          src={props.imageUrl ?? ''}
          borderRadius='50%'
        />
      </Stack>
    </div>
  )
}

export default BannerCircleImg
