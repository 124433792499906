import React from 'react'
import { MainMenuContext } from 'App'
import Stack from '../Stack'
import useDevice from 'common/useDevice'
import MenuItem from './MenuItem/MenuItem'

type MainMenuItem = {
  id: number;
  url: string;
  name: string;
  level: number;
  parent: number | null;
  children: MainMenuItem[];
}

const MainMenu = () => {
  const menuItems = React.useContext(MainMenuContext)
  const device = useDevice()

  const getPaddingX = () => {
    if (device === 'phone') {
      return 0
    }
    if (device === 'tablet') {
      return 64
    }
    return 128
  }

  return (
    <div
      style={{
        display: 'flex',
        paddingTop: 12,
        paddingLeft: getPaddingX(),
        paddingRight: getPaddingX()
      }}>
      <Stack
        orientation='horizontal'
        gap={30}
        justifyContent='space-between'
        flexGrow={1}
        style={{
          flexWrap: 'wrap'
        }}
      >
        {menuItems.filter(
          (item: MainMenuItem) => item.level === 0
        ).map((item: MainMenuItem) => {
          return <MenuItem
            key={item.id}
            childrenItems={menuItems.filter((sItem: any) => sItem.parent === item.id)}
            {...item}
          />
        })}
      </Stack>
    </div>
  )
}

export default MainMenu
