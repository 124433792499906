import React from 'react'

const StackSpacer = () => {
  return (
    <div
      style={{
        flexGrow: 1
      }}
    ></div>
  )
}

export default StackSpacer
