import React from 'react'
import Color from 'common/Color.d'
import colors from 'common/colors'
import useDevice from 'common/useDevice'

type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4'

const defaultHeadingVariant: HeadingVariant = 'h2'
const defaultHeadingColor: Color = 'black-dark'

const FontWeights = {
  'h1': {
    'desktop': 500,
    'smallScreen': 500,
    'tablet': 500,
    'phone': 500
  },
  'h2': {
    'desktop': 500,
    'smallScreen': 500,
    'tablet': 500,
    'phone': 500
  },
  'h3': {
    'desktop': 500,
    'smallScreen': 500,
    'tablet': 500,
    'phone': 500
  },
  'h4': {
    'desktop': 500,
    'smallScreen': 500,
    'tablet': 500,
    'phone': 500
  }
}

const FontSizes = {
  'h1': {
    'desktop': '64px',
    'smallScreen': '64px',
    'tablet': '49px',
    'phone': '40px'
  },
  'h2': {
    'desktop': '48px',
    'smallScreen': '48px',
    'tablet': '36px',
    'phone': '26px'
  },
  'h3': {
    'desktop': '32px',
    'smallScreen': '32px',
    'tablet': '24px',
    'phone': '24px'
  },
  'h4': {
    'desktop': '24px',
    'smallScreen': '24px',
    'tablet': '22px',
    'phone': '20px'
  }
}

const LineHeights = {
  'h1': {
    'desktop': '72px',
    'smallScreen': '72px',
    'tablet': '55px',
    'phone': '45px'
  },
  'h2': {
    'desktop': '54px',
    'smallScreen': '54px',
    'tablet': '41px',
    'phone': '32px'
  },
  'h3': {
    'desktop': '44x',
    'smallScreen': '44px',
    'tablet': '33px',
    'phone': '28px'
  },
  'h4': {
    'desktop': '32px',
    'smallScreen': '32px',
    'tablet': '24px',
    'phone': '24px'
  }
}

type HeadingProps = {
  variant?: HeadingVariant;
  children?: React.ReactNode;
  color?: Color;
  className?: string;
  textAlign?: React.CSSProperties['textAlign'],
  textOverflow?: React.CSSProperties['textOverflow'],
  overflow?: React.CSSProperties['overflow'],
  overflowY?: React.CSSProperties['overflowY'],
  overflowX?: React.CSSProperties['overflowX'],
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => void;
  uppercase?: boolean;
  flexGrow?: React.CSSProperties['flexGrow'];
}

const Heading = (props: HeadingProps) => {
  const device = useDevice()

  const handleClick = (e: any) => {
    props.onClick?.(e)
  }

  const headingVariant = props.variant ?? defaultHeadingVariant
  const headingColor = props.color ?? defaultHeadingColor

  let style: React.CSSProperties = {
    fontFamily: 'ALS Hauss',
    fontSize: FontSizes[headingVariant][device],
    fontWeight: FontWeights[headingVariant][device],
    lineHeight: LineHeights[headingVariant][device],
    fontStyle: 'normal',
    textAlign: props.textAlign,
    color: colors[headingColor],
    textOverflow: props.textOverflow,
    overflow: props.overflow,
    overflowY: props.overflowY,
    overflowX: props.overflowX,
    textTransform: props.uppercase ? 'uppercase' : 'none',
    margin: 0,
    flexGrow: props.flexGrow
  }

  const componentProps = {
    style: {...style, ...props.style},
    className: props.className,
    onClick: handleClick
  }

  if (headingVariant === 'h1') {
    return <h1 {...componentProps}>{props.children}</h1>
  }
  if (headingVariant === 'h2') {
    return <h2 {...componentProps}>{props.children}</h2>
  }
  if (headingVariant === 'h3') {
    return <h3 {...componentProps}>{props.children}</h3>
  }
  if (headingVariant === 'h4') {
    return <h4 {...componentProps}>{props.children}</h4>
  }

  return <h2 {...componentProps}>{props.children}</h2>
}

export default Heading
export type { HeadingVariant }
