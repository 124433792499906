import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import { Breadcrumb } from 'components/v1/Breadcrumbs'
import FileLinkWithArrow from 'components/v1/FileLinkWithArrow'
import LinkWithArrow from 'components/v1/LinkWithArrow'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import NewsItem from 'models/NewsItem.d'
import BasePage from 'pages/BasePage'
import React from 'react'
import { useParams } from 'react-router-dom'
import styles from './NewsItemPage.module.css'
import BannerCircleImg from 'components/v1/BannerCircleImg'

const NewsItemPage = () => {
  const { id } = useParams()
  const device = useDevice()
  const [item, setItem] = React.useState<NewsItem>({} as NewsItem)

  React.useEffect(() => {
    fetchItem()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchItem = async () => {
    if (!id) return

    const url = `/api/news/${id}`
    const response = await fetch(url)
    if (response.ok) {
      const data = await response.json()
      setItem(data)
    }
  }
  return (
    <BasePage>
      <Stack gap={Sizes.gap30[device]}>
        {item.id &&
          <PageSection>
            <BannerCircleImg
              breadcrumbs={[
                { name: 'Главная', url: '/' },
                { name: 'Новости', url: '/news' }
              ] as Breadcrumb[]}
              heading={item.name}
              imageUrl={item.image_url}
            />
          </PageSection>
        }

        {item.stream_iframe && <PageSection>
          <div
            dangerouslySetInnerHTML={{ __html: item.stream_iframe ?? '' }}
          />
        </PageSection>}

        {item.stream_url && <PageSection>
          <LinkWithArrow
            to={item.stream_url ?? ''}
            label='Ссылка на трансляцию'
          />
        </PageSection>}
        {item.stream_url2 && <PageSection>
          <LinkWithArrow
            to={item.stream_url2 ?? ''}
            label='Ссылка на трансляцию'
          />
        </PageSection>}
        {item.stream_url3 && <PageSection>
          <LinkWithArrow
            to={item.stream_url3 ?? ''}
            label='Ссылка на трансляцию'
          />
        </PageSection>}

        {item.presentation_file_url && <PageSection>
          <FileLinkWithArrow
            to={item.presentation_file_url ?? ''}
            label='Ссылка на презентации'
          />
        </PageSection>}

        <PageSection>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: item.content ?? '' }}
          />
        </PageSection>
      </Stack>
    </BasePage>
  )
}

export default NewsItemPage
