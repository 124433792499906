import React, { useState } from 'react'
import Link from 'components/v1/Link'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'

type LinkWithArrowProps = {
  to: string;
  label?: string;
  width?: number | string;
}

const LinkWithArrow = (props: LinkWithArrowProps) => {
  const device = useDevice()
  const [hover, setHover] = useState(false)

  const getTextColor = () => {
    if (hover) {
      return 'green-dark'
    }
    return 'green-medium'
  }
  const getArrowOffset = () => {
    if (hover) {
      return -3
    }
    return 0
  }

  const defaultWidths = {
    desktop: '70%',
    smallScreen: '100%',
    tablet: '100%',
    phone: '100%'
  }

  return (
    <Link
      to={props.to}
      style={{
        padding: 27,
        border: '1px solid #C4C4C4',
        borderRadius: '30px',
        display: 'inline-block',
        boxSizing: 'border-box',
        width: props.width ?? defaultWidths[device]
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Stack
        orientation='horizontal'
        alignItems={'center'}
        gap={Sizes.gap32[device]}
      >
        <Text
          variant='larger'
          color={getTextColor()}
          style={{
            flexGrow: 1
          }}
        >
          {props.label}
        </Text>
        {/* arrow */}
        <svg
          style={{
            position: 'relative',
            right: getArrowOffset()
          }}
          width="50" height="53" viewBox="0 0 50 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.65625" y1="26.0664" x2="46.8645" y2="26.0664" stroke="#3A3A3A" strokeWidth="3" />
          <line x1="23.3575" y1="1.93934" x2="48.1734" y2="26.7552" stroke="#3A3A3A" strokeWidth="3" />
          <line x1="21.2362" y1="51.3222" x2="46.052" y2="26.5063" stroke="#3A3A3A" strokeWidth="3" />
        </svg>
      </Stack>
    </Link>
  )
}

export default LinkWithArrow
