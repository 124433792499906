import React from 'react'
import PageSection from 'components/v1/PageSection'
import Layout from 'components/v1/Layout/Layout'
import Breadcrumbs from 'components/v1/Breadcrumbs'
import Heading from 'components/v1/Heading'
import Text from 'components/v1/Text'
import nbspacer from 'common/nbspacer'
import Stack from 'components/v1/Stack'
import Icon from 'components/v1/Icon'

const HeaderSection = () => {
  return (
    <PageSection>
      <Layout variant='vertical'>
        <Breadcrumbs
          color='grey-medium'
          items={
            [{ name: 'Главная', url: '/' }, { name: 'О ФИРО', url: null }]
          }
        />

        <Heading variant='h1'>О Федеральном институте развития образования</Heading>
        <Stack
          orientation='horizontal'
          phoneOrientation='vertical'
          gap={100}
          phoneGap={20}
        >
          <Stack
            justifyContent='center'
          >
            <Icon
              variant='scientists'
              size={145}
              color='green-medium'
            />
          </Stack>
          <Stack justifyContent='center'>
            <Text variant='large'>{nbspacer('Федеральный институт развития образования является научно- образовательным подразделением Российской академии народного хозяйства и государственной службы при президенте России (РАНХиГС), обеспечивающим научно- исследовательское, экспертно-аналитическое, научно- методическое, образовательное, информационное сопровождение развития системы образования Российской Федерации.')}</Text>
          </Stack>
        </Stack>
      </Layout>

    </PageSection>
  )
}

export default HeaderSection
