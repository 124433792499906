import React from 'react'

type ImageProps = {
  src: string;
  alt?: string;
  height?: React.CSSProperties['height'];
  width?: React.CSSProperties['width'];
  className?: string;
  style?: React.CSSProperties;
  objectFit?: React.CSSProperties['objectFit'];
  onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  borderRadius?: React.CSSProperties['borderRadius'];
  flexShrink?: React.CSSProperties['flexShrink'];
}

const Image = (props: ImageProps) => {
  const handleClick = (e: any) => {
    props.onClick?.(e)
  }

  let style: React.CSSProperties = {
    objectFit: props.objectFit,
    borderRadius: props.borderRadius,
    flexShrink: props.flexShrink
  }

  const componentProps: any = {
    className: props.className,
    src: props.src,
    alt: props.alt,
    height: props.height,
    width: props.width,
    style: {...style, ...props.style},
    onClick: handleClick
  }

  return (
    <img {...componentProps} />
  )
}

export default Image
