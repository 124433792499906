import React from 'react'
import Card from 'components/v1/Card'
import Text from 'components/v1/Text'
import nbspacer from 'common/nbspacer'
import Color from 'common/Color.d'
import Stack from 'components/v1/Stack'

type TextDescriptionProps = {
  text: string;
  description: string;

  textColor?: Color;
  descriptionColor?: Color;
  bgColor?: Color;

  minHeight?: React.CSSProperties['height'];
  style?: React.CSSProperties;
}

const defaultColor = 'white'
const defaultBgColor = 'green-medium'

const TextDescription = (props: TextDescriptionProps) => {
  return (
    <Card
      bgColor={props.bgColor ?? defaultBgColor}
      minHeight={props.minHeight ?? '370px'}
      style={props.style}
    >
      <Stack
        justifyContent='center'
        height='100%'
      >
        <Text
          variant='largest'
          color={props.textColor ?? defaultColor}
        >
          {nbspacer(props.text)}
        </Text>
        <Text
          variant='larger'
          color={props.descriptionColor ?? defaultColor}
        >
          {nbspacer(props.description ?? defaultColor)}
        </Text>
      </Stack>
    </Card>
  )
}

export default TextDescription
