import React from 'react'

import styles from './Vertical.module.css'

type VerticalProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Vertical = (props: VerticalProps) => {
  const style: React.CSSProperties = {
    alignItems: props.alignItems,
    justifyContent: props.justifyContent
  }

  const componentProps = {
    style: { ...style, ...props.style },
    className: styles['Vertical'],
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    onClick: props.onClick
  }

  return (
    <div {...componentProps}>{props.children}</div>
  )
}

export default Vertical
