import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Stack from 'components/v1/Stack'
import TagButton from 'components/v1/TagButton'
import ActivityTag from 'models/ActivityTag.d'
import React from 'react'

type ActivityTagsProps = {
  onChange: (item: ActivityTag | null) => void;
}

const ActivityTags = (props: ActivityTagsProps) => {
  const device = useDevice()
  const [currentItem, setCurrentItem] = React.useState<ActivityTag | null>(null)
  const [items, setItems] = React.useState<ActivityTag[]>([])

  React.useEffect(() => {
    fetchItems()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    props.onChange?.(currentItem)
  }, [currentItem]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchItems = async () => {
    const url = `/api/activity-tags/`
    let params: any = {
      page_size: 100,
      page: 1
    }
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setItems(data.results)
    }
  }

  return (
    <Stack
      orientation='horizontal'
      gap={Sizes.gap40[device]}
    >
      <TagButton
        active={currentItem === null}
        onClick={() => setCurrentItem(null)}
      >
        Все
      </TagButton>
      {items.map((item: ActivityTag) => (
        <TagButton
          active={currentItem?.id === item.id}
          key={item.id}
          onClick={() => setCurrentItem(item)}
        >
          {item.name}
        </TagButton>
      ))
      }
    </Stack >
  )
}

export default ActivityTags
