import React from 'react'
import Link from 'components/v1/Link'
import Stack from 'components/v1/Stack'
import Color from 'common/Color.d'
import colors from 'common/colors'
import Text from './Text'

type Breadcrumb = {
  name: string;
  url: string | null;
}

type BreadcrumbsProps = {
  items: Breadcrumb[];
  color?: Color
}

const defaultColor = 'black-dark'

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const color = props.color ?? defaultColor
  return (
    <Stack
      orientation='horizontal'
      gap={4}
      alignItems='center'
    >
      {props.items.map((item: Breadcrumb, index: number) => {
        return (<Stack
          key={index}
          orientation='horizontal'
          gap={4}
          alignItems='center'
        >
          {item.url && <Link
            to={item.url ?? ''}
            color={color}
          >
            {item.name}
          </Link>}
          {!item.url && <Text color={color}>{item.name}</Text>}
          {index !== props.items.length - 1 &&
            <svg
              width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2155 3.99219L16.2246 10.0013L10.2155 16.0105" stroke={colors[color]} strokeWidth="1.20183" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15.0222 10.0003L3.00391 10.0003" stroke={colors[color]} strokeWidth="1.20183" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          }
        </Stack>)
      })}
    </Stack>
  )
}

export default Breadcrumbs
export type { Breadcrumb }
