import React from 'react'
import Heading from 'components/v1/Heading'
import Stack from 'components/v1/Stack'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Text from './Text'

type BannerHeadingTextProps = {
  heading: string;
  text: string;
  imageUrl?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const BannerHeadingText = (props: BannerHeadingTextProps) => {
  const device = useDevice()

  const style: React.CSSProperties = {
    minHeight: '400px',
    borderRadius: '30px',
    width: '100%',
    boxSizing: 'border-box',
    padding: Sizes.gap30[device],
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props.imageUrl}) no-repeat center center / cover`
  }

  const componentProps = {
    style: { ...style, ...props.style },
    className: props.className,
    onClick: props.onClick
  }

  return (
    <div {...componentProps}>
      <Stack
        justifyContent='center'
        height='100%'
        style={{
          boxSizing: 'border-box',
          minHeight: '340px'
        }}
        gap={Sizes.gap30[device]}
      >
        <Heading
          variant='h1'
          color='white'
        >
          {props.heading}
        </Heading>
        <Text
          variant='larger'
          color='white'
        >
          {props.text}
        </Text>
      </Stack>
    </div>
  )
}

export default BannerHeadingText
