import nbspacer from 'common/nbspacer'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Text, { TextVariant } from 'components/v1/Text'
import Color from 'common/Color.d'
import colors from 'common/colors'

type ButtonVariant = 'primary' | 'secondary'
type ButtonSize = 'small' | 'large'

type ButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  to?: string;
  label: string;
  openInNewTab?: boolean;
  onClick?: () => void;
}

const defaultButtonVariant: ButtonVariant = 'primary'
const defaultButtonSize: ButtonSize = 'large'

const Button = (props: ButtonProps) => {
  const [hover, setHover] = useState(false)

  const buttonVariant = props.variant ?? defaultButtonVariant
  const buttonSize = props.size ?? defaultButtonSize

  const paddings = {
    'small': '10px 70px',
    'large': '13px 63px'
  }

  const getButtonBackgroundColor = () => {
    if (buttonVariant === 'primary') {
      if (hover) {
        return colors['green-darkest']
      }
      return colors['green-medium']
    }
    return colors['white']
  }

  const getButtonBorder = () => {
    if (buttonVariant === 'primary') {
      return 'none'
    }
    if (hover) {
      return `2px solid ${colors['green-darkest']}`
    }
    return `2px solid ${colors['green-medium']}`
  }

  const buttonStyle: React.CSSProperties = {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    borderRadius: '1000px',
    padding: paddings[buttonSize],
    backgroundColor: getButtonBackgroundColor(),
    border: getButtonBorder()
  }

  let componentProps: any = {
    onClick: props.onClick,
    style: buttonStyle,
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false)
  }

  const getTextVariant = () => {
    if (buttonSize === 'small') {
      return 'large'
    }
    return 'larger'
  }

  const getButtonTextColor = () => {
    if (buttonVariant === 'primary') {
      return 'white'
    }
    if (hover) {
      return 'green-darkest'
    }
    return 'green-medium'
  }

  let textProps = {
    color: getButtonTextColor() as Color,
    variant: getTextVariant() as TextVariant
  }

  if (props.to) {
    if (props.openInNewTab) {
      componentProps = {
        ...componentProps,
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    }

    if (props.to.startsWith('http')) {
      componentProps = {
        ...componentProps,
        href: props.to
      }
      return (
        <a {...componentProps} >
          <Text {...textProps}>{nbspacer(props.label)}</Text>
        </a>
      )
    } else {
      componentProps = {
        ...componentProps,
        to: props.to
      }
      return (
        <Link {...componentProps}>
          <Text {...textProps}>{nbspacer(props.label)}</Text>
        </Link >
      )
    }
  }
  return (
    <button {...componentProps}>
      <Text {...textProps}>{nbspacer(props.label)}</Text>
    </button>
  )
}

export default Button
