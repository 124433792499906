const colors = {
  'green-lighter': '#B3DFE4',
  'green-light': '#70C4CC',
  'green-medium': '#0096A5',
  'green-dark': '#008795',
  'green-darkest': '#006C77',
  'blue-light': '#F0F4FD',
  'black-medium': '#3A3A3A',
  'black-dark': '#271919',
  'white': '#FFFFFF',
  'orange-light': '#FAF2F2',
  'orange-medium': '#FFAE93',
  'orange-dark': '#E78E24',
  'orange-darkest': '#EC6744',
  'grey-medium': '#C4C4C4',
  'grey-dark': '#9E9E9E'
}

export default colors
