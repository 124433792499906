import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Card from 'components/v1/Card'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import React from 'react'

const YearsSection = () => {
  const device = useDevice()

  const items = [
    {
      year: 2005,
      text: 'Федеральный институт развития образования (ФГУ ФИРО) был создан Постановлением правительства от 31 мая 2005 года путем слияния 5 институтов, курировавших ключевые области развития образования в РФ.'
    },
    {
      year: 2009,
      text: 'Под руководством доктора психологических наук, профессора Александра Григорьевича Асмолова ФГУ ФИРО Министерства образования и науки стал во главе инновационных преобразований, активно сотрудничая с ведущими российскими центрами развития образования.'
    },
    {
      year: 2018,
      text: 'Федеральный институт развития образования вошел в состав РАНХиГС (Институт).'
    }
  ]
  return (
    <PageSection>
      <Grid
        cols={Sizes.cols3[device]}
        columnGap={Sizes.gap32[device]}
        rowGap={Sizes.gap30[device]}
      >
        {items.map((item, index) => (
          <Card
            key={index}
            bgColor='blue-light'
            borderColor='green-medium'
            borderWidth={1}
          >
            <Stack
              gap={Sizes.gap20[device]}
            >
              <Heading
                variant='h3'
                color='green-medium'
              >
                {item.year}
              </Heading>
              <Text>{item.text}</Text>
            </Stack>
          </Card>
        ))}
      </Grid>
    </PageSection>
  )
}

export default YearsSection
