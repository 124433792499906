import { Routes, Route, useLocation } from 'react-router-dom'
import { useState, useEffect, createContext } from 'react'

import AboutPage from 'pages/About/AboutPage'
import ActivitiesPage from 'pages/Activities/ActivitiesPage'
import PartnersPage from 'pages/Partners/PartnersPage'
import HomePage from 'pages/Home/HomePage'

import NewsItemPage from 'pages/News/NewsItemPage'
import NewsPage from 'pages/News/NewsPage'
import TeamPage from 'pages/Team/TeamPage'
import ChildrenPage from 'pages/Projects/Children/ChildrenPage'
import ChildrenSelfRealizationPage from 'pages/Projects/ChildrenSelfRealization/ChildrenSelfRealizationPage'
import DnaOfRussia from 'pages/DnaOfRussia/v3/DnaOfRussia'
import MonitoringAndStatisticsPage from 'pages/Centers/MonitoringAndStatistics/MonitoringAndStatisticsPage'
import ComponentsPage from 'pages/Components/ComponentsPage'
import TheoreticalAndAppliedAspectsPage from 'pages/Centers/TheoreticaAndAppliedAspects/TheoreticalAndAppliedAspectsPage'
import ProfessionalEducationPage from 'pages/Centers/ProfessionalEducation/ProfessionalEducationPage'
import PriorityAreasPage from 'pages/Centers/PriorityAreas/PriorityAreasPage'
import EducationQualityPage from 'pages/Centers/EducationQuality/EducationQualityPage'
import CentersScientificWorksPage from 'pages/CentersScientificWorks/CentersScientificWorksPage'
import ProjectsPage from 'pages/Projects/ProjectsPage'
import GraduateSchoolPage from 'pages/GraduateSchool/GraduateSchoolPage'
import PedagogyPage from 'pages/GraduateSchool/PedagogyPage'
import MethodologyPage from 'pages/GraduateSchool/MethodologyPage'
import ConditionsPage from 'pages/GraduateSchool/ConditionsPage'
import DeadlinesPage from 'pages/GraduateSchool/DeadlinesPage'
import DocumentsPage from 'pages/GraduateSchool/DocumentsPage'

const MainMenuContext = createContext([])
const SettingsContext = createContext([])

function App () {
  const [menuItems, setMainMenuItems] = useState([])
  const [settings, setSettings] = useState([])

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    fetchMainMenu()
    fetchSettings()
  }, [])

  const fetchMainMenu = async () => {
    const response = await fetch('/api/main-menu')
    const data = await response.json()
    setMainMenuItems(data)
  }

  const fetchSettings = async () => {
    const response = await fetch('/api/settings')
    const data = await response.json()
    setSettings(data)
  }

  return (
    <div className="App">
      <MainMenuContext.Provider value={menuItems}>
        <SettingsContext.Provider value={settings}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/activities" element={<ActivitiesPage />} />

            <Route path="/centers/education-quality" element={<EducationQualityPage />} />
            <Route path="/centers/monitoring-and-statistics" element={<MonitoringAndStatisticsPage />} />
            <Route path="/centers/professional-education" element={<ProfessionalEducationPage />} />
            <Route path="/centers/priority-areas" element={<PriorityAreasPage />} />
            <Route path="/centers/theoretical-and-applied-aspects" element={<TheoreticalAndAppliedAspectsPage />} />

            <Route path="/centers/:center/scientific-works/:workType" element={<CentersScientificWorksPage />} />

            <Route path="/dna-of-russia" element={<DnaOfRussia />} />

            <Route path="/graduate-school" element={<GraduateSchoolPage />} />
            <Route path="/graduate-school/pedagogy" element={<PedagogyPage />} />
            <Route path="/graduate-school/methodology" element={<MethodologyPage />} />
            <Route path="/graduate-school/conditions" element={<ConditionsPage />} />
            <Route path="/graduate-school/deadlines" element={<DeadlinesPage />} />
            <Route path="/graduate-school/documents" element={<DocumentsPage />} />

            <Route path="/partners" element={<PartnersPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/news/:id" element={<NewsItemPage />} />

            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/projects/children" element={<ChildrenPage />} />
            <Route path="/projects/children-self-realization" element={<ChildrenSelfRealizationPage />} />

            <Route path="/team" element={<TeamPage />} />
            <Route path="/eeleip1M/components" element={<ComponentsPage />} />
          </Routes>
        </SettingsContext.Provider>
      </MainMenuContext.Provider>
    </div>
  )
}

export default App
export { MainMenuContext, SettingsContext }
