import React from 'react'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Heading from 'components/v1/Heading'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'

import style from './TodaySection.module.css'
import CardWithSvgIcon from 'components/v1/CardWithSvgIcon'
import BannerHeadingText from 'components/v1/BannerHeadingText'
import { SvgIconVariant } from 'components/v1/SvgIcon'
import Grid from 'components/v1/Grid'
import ContentCard from 'components/v1/ContentCard/ContentCard'

const TodaySection = () => {
  const device = useDevice()

  const items: {
    name: string;
    description: string;
    icon?: SvgIconVariant;
    imageUrl?: string;
  }[] = [
    {
      name: '6',
      description: 'центров',
      icon: 'letter-a'
    },
    {
      name: '96+',
      description: 'научных исследований и разработок',
      imageUrl: '/static/img/pages/about/image2.jpg'
    },
    {
      name: '8',
      description: 'докторов',
      icon: 'book'
    },
    {
      name: '17',
      description: 'кандидатов наук',
      icon: 'page-with-check'
    },
    {
      name: '200+',
      description: 'научных статей',
      icon: 'people'
    },
    {
      name: '200+',
      description: 'научно-методических площадок',
      icon: 'book-with-bookmark-and-cog'
    },
    {
      name: '70 000+',
      description: 'учителей участвуют мониторинговых научных исследованиях',
      imageUrl: '/static/img/pages/about/image3.jpg'
    },
    {
      name: '16',
      description: 'всероссийских мониторинговых исследований',
      icon: 'book'
    },
    {
      name: '27',
      description: 'сформированных рейтингов',
      icon: 'page-with-check'
    },
    {
      name: '16',
      description: 'организаций- партнеров',
      icon: 'people'
    }
  ]

  return (
    <PageSection>
      <Stack
        gap={Sizes.gap30[device]}
      >
        <Heading variant='h2'>Институт сегодня</Heading>

        {device === 'desktop' && <Stack gap={Sizes.gap30[device]}>
          <div className={style.grid}>
            <CardWithSvgIcon name={items[0].name} description={items[0].description} icon={items[0].icon ?? 'book'} />
            <BannerHeadingText heading={items[1].name} text={items[1].description} imageUrl={items[1].imageUrl} />
          </div>
          <div className={style.grid3}>
            <CardWithSvgIcon name={items[2].name} description={items[2].description} icon={items[2].icon ?? 'book'} />
            <CardWithSvgIcon name={items[3].name} description={items[3].description} icon={items[3].icon ?? 'book'} />
            <CardWithSvgIcon name={items[4].name} description={items[4].description} icon={items[4].icon ?? 'book'} />
          </div>
          <div className={style.grid}>
            <CardWithSvgIcon name={items[5].name} description={items[5].description} icon={items[5].icon ?? 'book'} />
            <ContentCard
              variant='text-description'
              bgColor='green-light'
              text={items[6].name}
              description={items[6].description}
            />
          </div>
          <div className={style.grid3}>
            <CardWithSvgIcon name={items[7].name} description={items[7].description} icon={items[7].icon ?? 'book'} />
            <CardWithSvgIcon name={items[8].name} description={items[8].description} icon={items[8].icon ?? 'book'} />
            <CardWithSvgIcon name={items[9].name} description={items[9].description} icon={items[9].icon ?? 'book'} />
          </div>
        </Stack>}
        {device !== 'desktop' &&
          <Grid
            cols={Sizes.cols3[device]}
            columnGap={Sizes.gap32[device]}
            rowGap={Sizes.gap30[device]}
          >
            <CardWithSvgIcon name={items[0].name} description={items[0].description} icon={items[0].icon ?? 'book'} />
            <BannerHeadingText heading={items[1].name} text={items[1].description} imageUrl={items[1].imageUrl} />
            <CardWithSvgIcon name={items[2].name} description={items[2].description} icon={items[2].icon ?? 'book'} />
            <CardWithSvgIcon name={items[3].name} description={items[3].description} icon={items[3].icon ?? 'book'} />
            <CardWithSvgIcon name={items[4].name} description={items[4].description} icon={items[4].icon ?? 'book'} />
            <CardWithSvgIcon name={items[5].name} description={items[5].description} icon={items[5].icon ?? 'book'} />
            <ContentCard
              bgColor='green-light'
              variant='text-description'
              text={items[6].name}
              description={items[6].description}
            />
            <CardWithSvgIcon name={items[7].name} description={items[7].description} icon={items[7].icon ?? 'book'} />
            <CardWithSvgIcon name={items[8].name} description={items[8].description} icon={items[8].icon ?? 'book'} />
            <CardWithSvgIcon name={items[9].name} description={items[9].description} icon={items[9].icon ?? 'book'} />
          </Grid>}
      </Stack>
    </PageSection>
  )
}

export default TodaySection
