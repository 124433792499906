import Link from 'components/v1/Link'
import MenuDropdown from '../MenuDropdown/MenuDropdown'

type MenuItemProps = {
  id: number;
  name: string;
  url?: any;
  childrenItems: any[];
}

const MenuItem = ({name, url, childrenItems}: MenuItemProps) => {
  return (
    <>
      {url && childrenItems.length === 0 && <Link to={url}>{name}</Link>}
      {childrenItems.length > 0 && <MenuDropdown items={childrenItems} name={name} />}
    </>
  )
}

export default MenuItem
