import dateFormatter from 'common/dateFormatter'
import Sizes from 'common/Sizes'
import useDevice from 'common/useDevice'
import Carousel from 'components/v1/Carousel/Carousel'
import Heading from 'components/v1/Heading'
import Image from 'components/v1/Image'
import Link from 'components/v1/Link'
import LinkWithArrow from 'components/v1/LinkWithArrow'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import React from 'react'

type NewsItem = {
  id: number;
  name: string;
  date: string;
  image_url: string;
}

const NewsSection = () => {
  const device = useDevice()
  const [items, setItems] = React.useState<NewsItem[]>([])

  React.useEffect(() => {
    fetchItems()
  }, [])

  const fetchItems = async () => {
    const url = `/api/news/`
    let params: any = {}
    let urlParams = new URLSearchParams(Object.entries(params))
    const response = await fetch(`${url}?${urlParams}`)
    if (response.ok) {
      const data = await response.json()
      setItems(data.results)
    }
  }

  return (
    <PageSection>
      <Stack
        gap={Sizes.gap30[device]}
      >
        <Heading variant='h2'>Новости</Heading>
        <Carousel
          quantity={Sizes.cols3[device]}
          gap={Sizes.gap30[device]}
          circular={true}
          autoScroll={true}
          autoScrollInterval={7000}
        >
          {items.map((item: NewsItem) => {
            return (<Link to={`/news/${item.id}`} key={item.id}>
              <Stack gap={15}>
                <Image
                  src={item.image_url}
                  width='100%'
                  style={{
                    borderRadius: 30,
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    objectFit: 'cover',
                    height: 200
                  }}
                />
                <Stack gap={4}>
                  <Text variant='medium'>{dateFormatter.dayMonth(item.date)}</Text>
                  <Text variant='medium'>{item.name}</Text>
                </Stack>
              </Stack>
            </Link>)
          })}
        </Carousel>
        <LinkWithArrow
          label='Смотреть все новости'
          to='/news'
        />
      </Stack>
    </PageSection>
  )
}

export default NewsSection
