import Color from 'common/Color.d'
import Sizes from 'common/Sizes'
import nbspacer from 'common/nbspacer'
import useDevice from 'common/useDevice'
import Banner from 'components/v1/Banner'
import CenterContacts from 'components/v1/CenterContacts'
import ContentCard from 'components/v1/ContentCard/ContentCard'
import ExpandableCard from 'components/v1/ExpandableCard/ExpandableCard'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import { IconVariant } from 'components/v1/Icon'
import Layout from 'components/v1/Layout/Layout'
import Link from 'components/v1/Link'
import ListItem from 'components/v1/ListItem/ListItem'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'

const TheoreticalAndAppliedAspectsPage = () => {
  const device = useDevice()

  const numbersItems: {
    icon?: IconVariant;
    text: string;
    textColor: Color;
    description: string;
    descriptionColor: Color;
    bgColor?: Color;
    imageSrc?: string;
  }[] =
    [
      {
        text: '12',
        description: 'НИР',
        textColor: 'white',
        descriptionColor: 'white',
        icon: 'letter-a',
        bgColor: 'green-medium'
      },
      {
        text: '70+',
        textColor: 'white',
        description: 'проведенных мероприятий',
        descriptionColor: 'white',
        imageSrc: '/static/img/pages/centers/theoretical-and-applied-aspects/image2.jpg'
      },
      {
        text: '20+',
        description: 'методических разработок',
        textColor: 'black-dark',
        descriptionColor: 'black-dark',
        icon: 'book-simple',
        bgColor: 'green-lighter'
      },
      {
        text: '20+',
        description: 'реализованных проекта',
        textColor: 'white',
        descriptionColor: 'white',
        bgColor: 'green-light',
        icon: 'half-cog'
      },
      {
        text: '33',
        description: 'организаций- партнеров',
        descriptionColor: 'white',
        textColor: 'white',
        bgColor: 'green-medium',
        icon: 'case'
      }
    ]

  return (
    <BasePage>
      <Layout variant='page-sections'>

        <PageSection>
          <Layout variant='vertical'>
            <Banner
              heading='Центр изучения теоретических и прикладных аспектов образования'
              bgColor='green-light'
              breadcrumbs={[
                { name: 'Главная', url: '/' },
                { name: 'Центры', url: null }
              ]}
            />
            <Layout
              variant='image-content'
              imageSrc='/static/img/pages/centers/theoretical-and-applied-aspects/image1.jpg'
            >
              <Stack gap={20} height='100%' justifyContent='center'>
                <Text variant='large'>{nbspacer('Деятельность Центра ориентирована на развитие системы российского образования посредством проведения научных исследований фундаментального и прикладного характера в области образования и внедрения полученных результатов в образовательную практику.')}</Text>
                <Text variant='large'>{nbspacer('Сотрудниками Центра выполняются современные научные исследования, направленные на решение актуальных задач в рамках реализации государственной политики в области российского образования.')}</Text>

                <Stack>
                  <Text
                    variant='large'
                    color='green-medium'
                  >
                    {nbspacer('Структурные подразделения Центра:')}
                  </Text>
                  <Text>{nbspacer('Лаборатория «Диалог культур».')}</Text>
                </Stack>
              </Stack>
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <ExpandableCard
            title='Цель и задачи Центра'
          >
            <Layout variant='horizontal-50pct-50pct'>
              <Layout variant='vertical'>

                <Text
                  variant='larger'
                  color='green-medium'
                >
                  Цель
                </Text>

                <ListItem text='Решение фундаментальных и прикладных задач в сфере развития российского образования.' />
              </Layout>
              <Layout variant='vertical'>
                <Text
                  variant='larger'
                  color='green-medium'
                >
                  Задачи
                </Text>
                <ListItem text='Проведение опережающих научных исследований и разработок по актуальным направлениям развития российского образования.' />
                <ListItem text='Поддержка преемственности разных уровней образования.' />
                <ListItem text='Обеспечение единства педагогической науки и образовательной практики.' />
              </Layout>
            </Layout>
          </ExpandableCard>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Направления деятельности Центра</Heading>
            <Layout variant='horizontal-50pct-50pct'>
              <ListItem text='Научно-методическое, психолого-педагогическое, экспертно-аналитическое, информационное сопровождение участников образовательных отношений в меняющейся геополитической ситуации' />
              <ListItem text='Научные исследования и проектные разработки в области реализации государственной образовательной политики с учетом современных отечественных и зарубежных трендов' />
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Цифры</Heading>

            <Layout variant='grid-30pct-70pct--30pct-30pct-30pct'>
              {numbersItems.map((item, index) => (
                <ContentCard
                  key={index}
                  variant={item.imageSrc ? 'bg-image-text-description' : 'icon-text-description'}
                  style={{
                    gridArea: `grid-30pct-70pct--30pct-30pct-30pct__item-${index + 1}`
                  }}
                  icon={item.icon ?? 'book'}
                  text={item.text}
                  textColor={item.textColor}
                  description={item.description}
                  descriptionColor={item.descriptionColor}
                  bgColor={item.bgColor}
                  imageSrc={item.imageSrc}
                />
              ))}
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='vertical'>
            <Heading variant='h2'>Результаты деятельности</Heading>
            <Grid
              cols={Sizes.cols3[device]}
              columnGap={Sizes.gap32[device]}
              rowGap={Sizes.gap30[device]}
            >
              <Link to='scientific-works/research'>
                <ContentCard
                  icon='book-with-bookmark'
                  variant='accent-icon-text'
                  text='Научные исследования'
                />
              </Link>
              <Link to='scientific-works/publication'>
                <ContentCard
                  icon='pages-t'
                  variant='accent-icon-text'
                  text='Публикации'
                />
              </Link>
              <Link to='scientific-works/ia_result'>
                <ContentCard
                  icon='pages-t'
                  variant='accent-icon-text'
                  text='РИД'
                />
              </Link>
            </Grid>
          </Layout>
        </PageSection>

        <PageSection>
          <CenterContacts
            name='Радионова Ольга Радиславовна'
            email='radionova-or@ranepa.ru'
            phone='+7 499 956-94-46'
            address='Москва, ул.3-я Кабельная, д.1, стр.1'
          />
        </PageSection>
      </Layout>
    </BasePage>
  )
}

export default TheoreticalAndAppliedAspectsPage
