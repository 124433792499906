import React from 'react'
import Horizontal30pct70pct from './variants/Horizontal30pct70pct/Horizontal30pct70pct'
import Horizontal70pct30pct from './variants/Horizontal70pct30pct/Horizontal70pct30pct'
import ImageContent from './variants/ImageContent/ImageContent'
import ContentImage from './variants/ContentImage/ContentImage'
import Vertical from './variants/Vertical/Vertical'
import Horizontal from './variants/Horizontal/Horizontal'
import PageSections from './variants/PageSections/PageSections'
import Horizontal50pct50pct from './variants/Horizontal50pct50pct/Horizontal50pct50pct'
import Grid30p70pAnd30p30p30p from './variants/Grid30p70pAnd30p30p30p/Grid30p70pAnd30p30p30p'
import Grid100pctAnd30pct30pct30pct from './variants/Grid100pctAnd30pct30pct30pct/Grid100pctAnd30pct30pct30pct'
import Grid30pct30pct30pctAnd30pct70pct from './variants/Grid30pct30pct30pctAnd30pct70pct/Grid30pct30pct30pctAnd30pct70pct'
import Horizontal30pct30pct30pct from './variants/Horizontal30pct30pct30pct/Horizontal30pct30pct30pct'

type LayoutVariant = 'horizontal-30pct-70pct' |
  'horizontal-70pct-30pct' |
  'horizontal-50pct-50pct' |
  'horizontal-30pct-30pct-30pct' |
  'grid-30pct-70pct--30pct-30pct-30pct' |
  'grid-100pct--30pct-30pct-30pct' |
  'grid-30pct-30pct-30pct--30pct-70pct' |
  'image-content' |
  'content-image' |
  'vertical' |
  'page-sections' |
  'horizontal';

type LayoutProps = {
  variant: LayoutVariant;
  children?: React.ReactNode;
  imageSrc?: string;
  imageHeight?: React.CSSProperties['height'];
  imageMinHeight?: React.CSSProperties['minHeight'];
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  style?: React.CSSProperties;
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
}

const Layout = (props: LayoutProps) => {
  const style: React.CSSProperties = {
    alignItems: props.alignItems,
    justifyContent: props.justifyContent
  }

  const componentProps = {
    style: { ...style, ...props.style },
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    onClick: props.onClick
  }

  return (
    <>
      {props.variant === 'horizontal-30pct-70pct' &&
        (<Horizontal30pct70pct {...componentProps}>{props.children}</Horizontal30pct70pct>)
      }
      {props.variant === 'horizontal-70pct-30pct' &&
        (<Horizontal70pct30pct {...componentProps}>{props.children}</Horizontal70pct30pct>)
      }
      {props.variant === 'horizontal-30pct-30pct-30pct' && (
        <Horizontal30pct30pct30pct {...componentProps}>{props.children}</Horizontal30pct30pct30pct>
      )}
      {props.variant === 'horizontal-50pct-50pct' &&
        (<Horizontal50pct50pct {...componentProps}>{props.children}</Horizontal50pct50pct>)
      }
      {props.variant === 'grid-30pct-70pct--30pct-30pct-30pct' &&
        (<Grid30p70pAnd30p30p30p {...componentProps}>{props.children}</Grid30p70pAnd30p30p30p>)
      }
      {props.variant === 'grid-30pct-30pct-30pct--30pct-70pct' && (
        <Grid30pct30pct30pctAnd30pct70pct {...componentProps}>{props.children}</Grid30pct30pct30pctAnd30pct70pct>
      )}
      {props.variant === 'grid-100pct--30pct-30pct-30pct' &&
        (<Grid100pctAnd30pct30pct30pct {...componentProps}>{props.children}</Grid100pctAnd30pct30pct30pct>)
      }
      {props.variant === 'image-content' &&
        (<ImageContent
          imageSrc={props.imageSrc ?? ''}
          imageMinHeight={props.imageMinHeight}
        >{props.children}</ImageContent>)
      }
      {props.variant === 'content-image' &&
        (<ContentImage
          {...componentProps}
          imageSrc={props.imageSrc ?? ''}
          imageHeight={props.imageHeight}
        >{props.children}</ContentImage>)
      }
      {props.variant === 'vertical' &&
        (<Vertical {...componentProps}>{props.children}</Vertical>)
      }
      {props.variant === 'page-sections' &&
        (<PageSections {...componentProps}>{props.children}</PageSections>)
      }
      {props.variant === 'horizontal' &&
        (<Horizontal {...componentProps}>{props.children}</Horizontal>)
      }
    </>
  )
}

export default Layout
export type { LayoutVariant }
