import colors from 'common/colors'
import nbspacer from 'common/nbspacer'
import Banner from 'components/v1/Banner'
import Button from 'components/v1/Button'
import Card from 'components/v1/Card'
import Heading from 'components/v1/Heading'
import Image from 'components/v1/Image'
import Layout from 'components/v1/Layout/Layout'
import Link from 'components/v1/Link'
import ListItem from 'components/v1/ListItem/ListItem'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import BasePage from 'pages/BasePage'
import React from 'react'

const MethodologyPage = () => {
  return (
    <BasePage>
      <Layout variant='page-sections'>
        {/* Баннер */}
        <PageSection>
          <Banner
            heading='5.8.7. Методология и технология профессионального образования'
            bgColor='green-light'
            breadcrumbs={[
              { name: 'Главная', url: '/' },
              { name: 'Образование', url: null },
              { name: 'Аспирантура', url: '/graduate-school' },
              { name: '5.8.7. Методология и технология профессионального образования', url: null }
            ]}
          />
        </PageSection>

        {/* Содержание */}
        <PageSection>
          <Layout
            variant='horizontal-70pct-30pct'
          >
            {/* left */}
            <Layout variant='vertical'>
              <Heading variant='h4' color='black-dark'>{nbspacer('Программа подготовки научно-педагогических кадров в аспирантуре (далее – программа аспирантуры) по научной специальности «5.8.7. Методология и технология профессионального образования» реализуются на основе федеральных государственных требований.')}</Heading>
              <Text>{nbspacer('Программа аспирантуры осуществляет подготовку научных и научно-педагогических кадров высшей квалификации в области педагогики и направлена на решение проблем профессионального образования, включая')}</Text>
              <Stack gap={20}>
                {[
                  'по теории идополнительное профессиональное образование, профессиональное обучение и профориентационную работу, взаимодействие с рынком труда;  концепции обучения; ',
                  'разработку научных подходов к исследованию тенденций развития профессионального образования на основе преемственности уровней образования, формирования человеческого капитала, профессиональных стандартов, компетентностной модели специалиста в условиях цифровизации экономики, особенностей социально-экономического развития субъектов Российской Федерации и культурной трансформации мира.'
                ].map((text, index) => (
                  <ListItem
                    key={index}
                    text={text}
                  />
                ))}
              </Stack>
              <Text>{nbspacer('Завершение обучения по программе аспирантуры предполагает выдачу Академией заключения о соответствии диссертации критериям, установленным в соответствии Федеральным законом от 23.08.1996 г. № 127-ФЗ «О науке и государственной научно-технической политике» и свидетельства об окончании аспирантуры.')}</Text>
              <div>
                <Button
                  label='Подать заявку'
                  size='small'
                  openInNewTab
                  to='https://lk.ranepa.ru/pk/auth.php'
                />
              </div>
            </Layout>
            {/* right */}
            <Layout variant='vertical'>
              <Image
                src='/static/img/pages/graduate-school/methodology/image1.jpg'
                width='100%'
                borderRadius='30px'
                style={{
                  border: `1px solid ${colors['grey-medium']}`
                }}
              />
              <Card
                borderColor='green-medium'
              >
                <Layout variant='vertical'>
                  <Text>{nbspacer('Организационными вопросами аспирантуры в ФИРО РАНХиГС занимаются:')}</Text>
                  <Stack gap={20}>
                    <Link to='mailto:Alekseeva-as@ranepa.ru' underline>Алексеева Анна Станиславовна</Link>
                    <Link to='mailto:Sharipova-ei@ranepa.ru' underline>Шарипова Эльвира Ирековна </Link>
                  </Stack>
                </Layout>
              </Card>
            </Layout>
          </Layout>
        </PageSection>
      </Layout>
    </BasePage>
  )
}

export default MethodologyPage
