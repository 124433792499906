import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import Icon, { IconVariant } from 'components/v1/Icon'
import Text from 'components/v1/Text'
import Stack from 'components/v1/Stack'
import React from 'react'
import PageSection from 'components/v1/PageSection'

const ComponentsPage = () => {
  const icons: IconVariant[] = [
    'arrow-right',
    'book',
    'book-simple',
    'book-with-pencil',
    'book-with-bookmark',
    'book-with-cog',
    'book-with-cog-opened',
    'book-with-finger',
    'book-stack',
    'case',
    'certificate',
    'chat',
    'clock',
    'computer',
    'exclamation-point',
    'family',
    'folder-with-star',
    'goal',
    'half-cog',
    'hat',
    'lamp',
    'laptop',
    'letter-a',
    'map-of-russia',
    'notebook',
    'pages-t',
    'page-with-puzzle',
    'page-with-check',
    'people',
    'people-communication',
    'people-with-clock',
    'person-with-arrows',
    'person-with-laptop-and-clock',
    'scientists',
    'scroll-with-diagram',
    'scroll-with-feather',
    'speaking-person',
    'telegram'
  ]
  return (
    <div>
      <PageSection>
        <Heading>Icons</Heading>
        <Grid
          cols={8}
          columnGap={32}
          rowGap={30}
        >
          {icons.map((icon, index) => (
            <Stack
              key={index}
              gap={10}
              alignItems='flex-start'
              height={64}
            >
              <Icon size={32} variant={icon as IconVariant} color='black-dark' />
              <Text>{icon}</Text>
            </Stack>
          ))}
        </Grid>
      </PageSection>
    </div>
  )
}

export default ComponentsPage
