import React from 'react'
import Card from 'components/v1/Card'
import Stack from 'components/v1/Stack'
import StackSpacer from './StackSpacer'
import Logo from 'components/v1/Logo'
import Link from 'components/v1/Link'
import useDevice from 'common/useDevice'
import colors from 'common/colors'

const GlobalHeader = () => {
  const device = useDevice()

  const getPaddingY = () => {
    if (device === 'phone') {
      return 0
    }
    return 40
  }

  const getPaddingX = () => {
    if (device === 'phone') {
      return 0
    }
    if (device === 'tablet') {
      return 64
    }
    return 128
  }

  return (
    <div
      style={{
        display: 'flex',
        paddingTop: getPaddingY(),
        paddingLeft: getPaddingX(),
        paddingRight: getPaddingX()
      }}
    >
      <Card
        borderRadius={device === 'phone' ? 0 : 16}
        bgColor='green-medium'
        padding='10px 30px'
        flexGrow={1}
      >
        <Stack orientation='horizontal'>
          <Stack
            orientation='horizontal'
            gap={16}
            alignItems='center'
          >
            <Link to='https://www.ranepa.ru'>
              {device !== 'phone' && device !== 'tablet' && <Logo variant='ranepa' width={90} />}
              {(device === 'phone' || device === 'tablet') && <Logo variant='ranepa' width={60} />}
            </Link>
            <div
              style={{
                height: 56,
                width: 1,
                backgroundColor: colors['white']
              }}
            />
            <Link to='/'>
              {device !== 'phone' && device !== 'tablet' && <Logo variant='firo-with-text' width={206} />}
              {(device === 'phone' || device === 'tablet') && <Logo variant='firo' width={60} />}
            </Link>
          </Stack>
          <StackSpacer />
          <Stack
            orientation='horizontal'
            gap={30}
            alignItems='center'
          >
          </Stack>
        </Stack>
      </Card>
    </div>
  )
}

export default GlobalHeader
