import BannerHeadingText from 'components/v1/BannerHeadingText'
import PageSection from 'components/v1/PageSection'
import React from 'react'

const StrategicCoalsSection = () => {
  return (
    <PageSection>
      <BannerHeadingText
        heading='Стратегическая цель'
        text='До 2025 года становление Института как передового института развития образования, обеспечивающего значимый вклад в деятельность Академии и отрасли образования в целом.'
        imageUrl='/static/img/pages/about/strategic-goals-image.jpg'
      />
    </PageSection>
  )
}

export default StrategicCoalsSection
