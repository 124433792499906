import Sizes from 'common/Sizes'
import nbspacer from 'common/nbspacer'
import useDevice from 'common/useDevice'
import Banner from 'components/v1/Banner'
import Card from 'components/v1/Card'
import Grid from 'components/v1/Grid'
import Heading from 'components/v1/Heading'
import Layout from 'components/v1/Layout/Layout'
import Link from 'components/v1/Link'
import PageSection from 'components/v1/PageSection'
import Stack from 'components/v1/Stack'
import Text from 'components/v1/Text'
import Image from 'components/v1/Image'
import BasePage from 'pages/BasePage'
import React, { useEffect } from 'react'
import NewsSection from 'pages/Home/sections/NewsSection'
import ExpandableCard from 'components/v1/ExpandableCard/ExpandableCard'
import LinkWithArrowAndButton from 'components/v1/LinkWithArrowAndButton'
import colors from 'common/colors'
import FloatingLink from 'components/v1/FloatingLink/FloatingLink'

const GraduateSchoolPage = () => {
  useEffect(() => {
    window.location.href = 'https://gspm.ranepa.ru/graduate-schools/pedagogy'
  }, [])

  const device = useDevice()

  return (
    <BasePage>
      <FloatingLink
        icon='document'
        label='Подать заявку'
        to='https://my.ranepa.ru/online/pk/'
        bottom={184}
        phoneBottom={184}
      />
      <FloatingLink
        icon='certificate'
        label='Документы, необходимые для поступления'
        to='/graduate-school/documents'
        bottom={24}
        phoneBottom={84}
      />
      <Layout variant='page-sections'>
        {/* Баннер */}
        <PageSection>
          <Banner
            heading='Аспирантура'
            bgColor='green-light'
            imageUrl='/static/img/pages/graduate-school/image0.jpg'
            breadcrumbs={[
              { name: 'Главная', url: '/' },
              { name: 'Образование', url: null },
              { name: 'Аспирантура', url: null }
            ]}
          />
        </PageSection>

        <PageSection>
          <Layout
            variant='content-image'
            imageSrc='/static/img/pages/graduate-school/image1.jpg'
          >
            <Layout variant='vertical' justifyContent='center'>
              <Stack>
                <Heading variant='h2'>
                  Открыт набор на программы аспирантуры по направлению 5.8&nbsp;Педагогика
                </Heading>
              </Stack>
              <Stack gap={12}>
                <Text>
                  {nbspacer('Учеба в аспирантуре ФИРО РАНХиГС с последующей защитой кандидатской диссертации — это возможность стать полноправным членом научного педагогического сообщества, участвовать в последних разработках и достижениях науки в своей области.')}
                </Text>
              </Stack>
            </Layout>
          </Layout>
        </PageSection>

        <PageSection>
          <Layout variant='horizontal-70pct-30pct'>
            {/* left */}
            <Layout variant='vertical'>
              <Heading variant='h2'>
                {nbspacer('Научные направления')}
              </Heading>
              <Stack gap={20}>
                <Link to='/graduate-school/pedagogy' underline>5.8.1. Общая педагогика, история педагогики и образования</Link>
                <Link to='/graduate-school/methodology' underline>5.8.7. Методология и технология профессионального образования</Link>
              </Stack>
            </Layout>
            {/* right */}
            <Card borderColor='green-medium'>
              <Layout variant='vertical'>
                <Text>{nbspacer('Организационными вопросами аспирантуры в ФИРО РАНХиГС занимаются:')}</Text>
                <Stack gap={20}>
                  <Link to='mailto:Alekseeva-as@ranepa.ru' underline>Алексеева Анна Станиславовна</Link>
                  <Link to='mailto:Sharipova-ei@ranepa.ru' underline>Шарипова Эльвира Ирековна</Link>
                </Stack>
              </Layout>
            </Card>
          </Layout>
        </PageSection>

        <PageSection>
          <Stack gap={30}>
            <Heading variant='h2'>5 причин учиться в аспирантуре РАНХиГС</Heading>
            <Heading variant='h3' color='green-medium'>Преимущества обучения в аспирантуре:</Heading>

            {
              ['РАНХиГС – единственная в стране образовательная организация при Президенте РФ;',
                'более чем 165 зарубежных образовательных организаций – партнеры Академии;',
                'повышение компетентности в области выбранного направления достигается с учетом научно-образовательного интереса аспиранта и потребности его карьерного роста;',
                'подготовка и защита диссертации происходит под руководством квалифицированного профессорско-преподавательского состава. Преподаватели Академии – самый авторитетный педагогический коллектив страны;',
                'диссеминация результатов исследований на международных научных конференциях и посредством публикаций.'
              ].map((item, index) => (
                <Stack key={index} gap={20} orientation='horizontal' alignItems='center'>
                  <div style={{ borderRadius: '50%', backgroundColor: '#0096A5', width: '45px', height: '45px', flexShrink: 0, display: 'flex', justifyContent: 'center' }}>
                    <Text color='white' variant='larger'>{index + 1}</Text>
                  </div>
                  <Text>{nbspacer(item)}</Text>
                </Stack>
              ))
            }
          </Stack>
        </PageSection>

        {/* Научные руководители */}
        <PageSection>
          <Card
            borderColor='green-medium'
          >
            <Layout variant='vertical'>
              <Stack orientation='horizontal' gap={30} alignItems='center'>
                <Heading variant='h2'>Научные руководители</Heading>
                <Link to='mailto:firo@ranepa.ru' style={{ backgroundColor: colors['green-medium'], borderRadius: '22px', padding: '10px 22px' }}>
                  <Text color='white'>Задать вопрос научному руководителю</Text>
                </Link>
              </Stack>
              <Grid
                cols={3}
                tabletCols={2}
                phoneCols={1}
                columnGap={Sizes.gap32[device]}
                rowGap={Sizes.gap30[device]}
              >
                {[
                  { name: 'Гаркуша Наталья Сергеевна', position: 'профессор, доктор педагогических наук', photoUrl: '/static/img/pages/graduate-school/garkusha.jpg', url: 'https://www.ranepa.ru/ob-akademii/prepodavateli-i-sotrudniki/sotrudnik/?id=d8444982-a7e0-4017-bad0-ed6c8a0a9d53' },
                  { name: 'Попов Александр Анатольевич', position: 'доцент, доктор философских наук', photoUrl: '/static/img/pages/graduate-school/popov.jpg', url: 'https://www.ranepa.ru/ob-akademii/prepodavateli-i-sotrudniki/sotrudnik/?id=73f38f1c-990e-4656-8b2d-a85b89514eb6' },
                  { name: 'Сергеев Игорь Станиславович', position: 'доктор педагогических наук', photoUrl: '/static/img/pages/graduate-school/persons/sergeev.jpg', url: 'https://www.ranepa.ru/ob-akademii/prepodavateli-i-sotrudniki/sotrudnik/?id=a50de714-ba02-4ae3-bb83-58e0926b6996' },
                  { name: 'Блинов Владимир Игоревич', position: 'профессор, доктор педагогических наук', photoUrl: '/static/img/pages/graduate-school/persons/blinov.jpg', url: 'https://www.ranepa.ru/ob-akademii/prepodavateli-i-sotrudniki/sotrudnik/?id=078035a5-0835-4cc9-a1e9-9368acb24ee7' },
                  { name: 'Есенина Екатерина Юрьевна', position: 'доктор педагогических наук', photoUrl: '/static/img/pages/graduate-school/persons/esenina.jpg', url: 'https://www.ranepa.ru/ob-akademii/prepodavateli-i-sotrudniki/sotrudnik/?id=e963b498-caaa-4762-9718-13d8ee1e0ab5' },
                  { name: 'Бенкс Елена Анатольевна', position: 'кандидат технических наук', photoUrl: '/static/img/pages/graduate-school/persons/benks.png', url: 'https://www.ranepa.ru/ob-akademii/prepodavateli-i-sotrudniki/sotrudnik/?id=e9b680be-36da-423e-8dcb-ce968dd84671' },
                  { name: 'Цирульников Анатолий Михайлович', position: 'доктор педагогических наук, профессор', photoUrl: '/static/img/pages/graduate-school/persons/cirulnikov.jpg', url: 'https://www.ranepa.ru/ob-akademii/prepodavateli-i-sotrudniki/sotrudnik/?id=a46ec0db-6ed4-4af8-96c1-101ac13fbff5' },
                  { name: 'Родичев Николай Федорович', position: 'кандидат педагогических наук', photoUrl: '/static/img/pages/graduate-school/persons/rodnichev.jpg', url: 'https://www.ranepa.ru/ob-akademii/prepodavateli-i-sotrudniki/sotrudnik/?id=8c4f9e5a-1eb7-4a2b-b4b2-fe525bee0332' },
                  { name: 'Зайцева Ольга Викторовна', position: 'кандидат технических наук', photoUrl: '/static/img/pages/graduate-school/persons/zaitceva.jpg', url: 'https://www.ranepa.ru/ob-akademii/prepodavateli-i-sotrudniki/sotrudnik/?id=32950' }
                ].map((item, index) => (
                  <Link key={index} to={item.url}>
                    <Stack
                      orientation='horizontal'
                      gap={10}
                      alignItems='center'
                    >
                      <Image
                        src={item.photoUrl}
                        width={85}
                        height={85}
                        objectFit='cover'
                        flexShrink={0}
                        borderRadius='50%'
                      />
                      <Stack gap={8}>
                        <Text>{item.name}</Text>
                        <Text variant='small'>{item.position}</Text>
                      </Stack>
                    </Stack>
                  </Link>
                ))}
              </Grid>
            </Layout>
          </Card>
        </PageSection>

        {/* Условия поступления */}
        <PageSection>
          <Stack gap={30}>
            {[
              {
                title: 'Порядок поступления в аспирантуру',
                url: '#',
                buttonTitle: 'Подать заявку',
                buttonUrl: 'https://lk.ranepa.ru/pk/auth.php'
              }
            ].map((item, index) => (
              <LinkWithArrowAndButton
                key={index} to={item.url}
                label={item.title}
                buttonUrl={item.buttonUrl}
                buttonLabel={item.buttonTitle}
              />
            ))}
          </Stack>
        </PageSection>

        {/* Новости */}
        <NewsSection />

        <PageSection>
          <ExpandableCard title='Полезная информация для аспиранта ФИРО'>
            <Layout variant='vertical'>
              <Link
                to='http://aspranepa.tilda.ws/'
                underline
              >
                Аспирантура РАНХиГС
              </Link>
              <Link
                to='https://www.ranepa.ru/nauka/podgotovka-nauchnykh-kadrov/aspirantura/'
                underline
              >
                Навигатор по Аспирантуре РАНХиГС
              </Link>
            </Layout>
          </ExpandableCard>
        </PageSection>
      </Layout>
    </BasePage>
  )
}

export default GraduateSchoolPage
